import style from '../components/WorkshopDetail.module.sass'
import type { CommonPageProps } from '../pages/[[...page]]'
import { WorkshopDetailHero } from './WorkshopDetailHero'

export type WorkshopDetailProps = Exclude<
	Pick<CommonPageProps['page'], 'workshopDetail'>['workshopDetail'],
	null
>

export const WorkshopDetail: React.FunctionComponent<WorkshopDetailProps> = ({
	title,
	content,
	tableSpecification,
	root,
	eventTitle,
}) => {
	return (
		<div className={style.wrapper}>
			<WorkshopDetailHero
				root={root}
				title={title}
				tableSpecification={tableSpecification}
				content={content}
				eventTitle={eventTitle}
			/>
		</div>
	)
}
