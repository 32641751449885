import type { FunctionComponent } from 'react'
import type { GroupedEventsResult } from '../utils/getGroupedEvents'
import style from './ProgramAndTicketPageEventsGroupedByDay.module.sass'
import { ProgramAndTicketPageEventsGroupedByTitle } from './ProgramAndTicketPageEventsGroupedByTitle'

export type ProgramAndTicketPageEventsGroupedByDayProps = {
	groupedEventsByDay: GroupedEventsResult[number]
}

export const ProgramAndTicketPageEventsGroupedByDay: FunctionComponent<
	ProgramAndTicketPageEventsGroupedByDayProps
> = ({ groupedEventsByDay }) => {
	return (
		<div className={style.wrapper}>
			<ProgramAndTicketPageEventsGroupedByTitle
				groupedEventsByTitle={groupedEventsByDay.groupedEventsByTitle}
			/>
		</div>
	)
}
