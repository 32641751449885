import type { PlyrProps } from 'plyr-react'
import Plyr from 'plyr-react'
import type { FunctionComponent } from 'react'

export type PlyrPlayerProps = PlyrProps

export const PlyrPlayer: FunctionComponent<PlyrPlayerProps> = ({ source, options }) => {
	return (
		<Plyr
			source={source}
			options={{ ...options, ratio: '16:9' }}
			onError={(error) => {
				// Plyr works just fine even though Sentry display error:
				// "CustomEvent: Non-Error exception captured with keys: currentTarget, detail, isTrusted, target"
				// So error.nativeEvent.stopImmediatePropagation() stops catching the errors
				error.nativeEvent.stopImmediatePropagation()
			}}
		/>
	)
}
