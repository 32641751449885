import { useRouter } from 'next/dist/client/router'
import React from 'react'
import type { WorkshopSubcategoryResult } from '../app/data/content/components/WorkshopSubcategoryFragment'
import { WorkshopFilterButton } from './WorkshopFilterButton'
import styles from './WorkshopSubcategorySwitcher.module.sass'

export type WorkshopSubcategorySwitcherProps = {
	subcategories: SubcategoryProps[]
	urlKey: string
}

type SubcategoryProps = WorkshopSubcategoryResult

export const WorkshopSubcategorySwitcher: React.FunctionComponent<
	WorkshopSubcategorySwitcherProps
> = ({ subcategories, urlKey }) => {
	const { query } = useRouter()

	return (
		<div className={styles.subcategories}>
			{subcategories &&
				subcategories.map((subcategory, index) => (
					<React.Fragment key={index}>
						{subcategory && (
							<WorkshopFilterButton
								title={subcategory.localesByLocale?.name ?? ''}
								type="slug"
								slug={subcategory.slug}
								returnLink={subcategory.slug}
								size="small"
								urlKey={urlKey}
								isActive={query.subcategory === subcategory.slug ? true : false}
							/>
						)}
					</React.Fragment>
				))}
		</div>
	)
}
