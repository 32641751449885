import type { FunctionComponent } from 'react'
import type { EventResult } from '../app/data/content/components/EventFragment'
import { parseDate } from '../utils/formatDate'
import { useCurrentLocale } from '../utils/useCurrentLocale'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import style from './DetailUpcomingEvent.module.sass'
import { Tag } from './Tag'
import { IsDotykovaObrazovka } from './contexts/IsDotykovaObrazovka'

export type DetailUpcomingEventProps = EventResult & {
	repertoireTitle?: string
	ageFrom?: string | number
}

export const DetailUpcomingEvent: FunctionComponent<DetailUpcomingEventProps> = ({
	ageFrom,
	repertoireTitle,
	...props
}) => {
	const currentLocale = useCurrentLocale()
	const date = props.datumOdModified
		? parseDate(currentLocale, props.datumOdModified.substring(0, 16)) // substract timezone from datumOdModified
		: parseDate(currentLocale, props.datumOd)

	const translation = useTranslate()
	const isDotykovaObrazovka = IsDotykovaObrazovka()
	return (
		<div className={style.wrapper}>
			<div className={style.meta}>
				<time className={style.date} dateTime={props.datumOd}>
					<div className={style.dateShort}>{date.dayNumeric}</div>
					<div className={style.dateLong}>{`${date.month}, ${date.weekday}`}</div>
				</time>
				<div className={style.tag}>
					{ageFrom && <Tag text={ageFrom.toString()} hasAddCharacter />}
				</div>
			</div>
			<div className={style.main}>
				<h4 className={style.title}>{repertoireTitle ? repertoireTitle : props.title?.nazev}</h4>
				<div className={style.mainIn}>
					<div className={style.place}>
						<time dateTime={props.datumOd}>{date.time}</time>
						<div className={style.placeVerticalDivider} />
						{props.venue?.exnazev && <span className={style.venue}>{props.venue.exnazev}</span>}
					</div>
					{!isDotykovaObrazovka && (
						<div className={style.actions}>
							<div className={style.button}>
								{props.volno !== 0 ? (
									<Button
										type="link"
										link={`https://www.webticket.cz/akce/${props.webTicketId}`}
										variant="red"
										viewType="detailUpcomingEvent">
										{translation('ticket.reserve')}
									</Button>
								) : (
									<Button type="button" variant="grey" isOutline isDisabled>
										{translation('ticket.soldOut')}
									</Button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
