import { useRouter } from 'next/dist/client/router'
import type { FunctionComponent } from 'react'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import { Button } from './Button'
import type { ProgramAndTicketPageProps } from './ProgramAndTicketPage'
import style from './ProgramAndTicketPageMonthsFilter.module.sass'

export type ProgramAndTicketPageMonthsFilterProps = {
	months: ProgramAndTicketPageProps['months']
}

export const ProgramAndTicketPageMonthsFilter: FunctionComponent<
	ProgramAndTicketPageMonthsFilterProps
> = ({ months }) => {
	return (
		<div className={style.wrapper}>
			{months.items.length > 0 && (
				<ul className={style.list}>
					{months.items.map((item) => (
						<li key={item.id} className={style.item}>
							<MonthFilterItem urlKey={months.urlKey} item={item} />
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

export const MonthFilterItem: FunctionComponent<{
	urlKey: ProgramAndTicketPageMonthsFilterProps['months']['urlKey']
	item: ProgramAndTicketPageMonthsFilterProps['months']['items'][number]
}> = ({ urlKey, item: { id, label, slug } }) => {
	const { query, pathname } = useRouter()

	const filterValue = slug ?? id

	const newQuery = useUrlQueryParam(query, urlKey, filterValue)

	return (
		<>
			<Button
				type="link"
				link={{
					pathname,
					query: newQuery,
				}}
				viewType="program"
				isActive={filterValue === query[urlKey]}
				isShadowed
				borderRadiusUnset={{ all: true }}>
				{label ?? slug}
			</Button>
		</>
	)
}
