import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { TagProps } from './Tag'
import { Tag } from './Tag'
import styles from './Tags.module.sass'

export type TagsProps = {
	items: TagProps[]
	isAlignCenter?: boolean
}

export const Tags: FunctionComponent<TagsProps> = ({ items, isAlignCenter }) => {
	return (
		<div className={clsx(styles.items, isAlignCenter && styles.is_align_center)}>
			{items.map((item, index) => (
				<div key={index} className={styles.item}>
					<Tag text={item.text} hasAddCharacter={item.hasAddCharacter} />
				</div>
			))}
		</div>
	)
}
