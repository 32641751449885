import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { FileResult } from '../app/data/content/components/FileFragment'
import type { FileOrLinkResult } from '../app/data/content/components/FileOrLinkFragment'
import { ContemberLink } from './ContemberLink'
import styles from './FileOrLink.module.sass'

export type FileOrLinkProps = {
	text?: string
	file: FileOrLinkResult
}

export const FileOrLink: FunctionComponent<FileOrLinkProps> = ({ file, text }) => {
	return (
		<div className={styles.wrapper}>
			{file.file && file.type === 'link' && file.link && (
				<ContemberLink className={styles.link} link={file.link}>
					<Content name={text ?? file.file.name} fileOrLinkType={file.type} type="link" />
				</ContemberLink>
			)}
			{file && file.type === 'file' && file.file?.url && (
				<Link className={styles.file} href={file.file.url}>
					<Content name={text ?? file.file.name} fileOrLinkType={file.type} type={file.file.type} />
				</Link>
			)}
		</div>
	)
}

type ContentProps = {
	name: FileResult['name']
	type?: string
	fileOrLinkType?: FileOrLinkResult['type']
}

const Content: React.FunctionComponent<ContentProps> = ({
	name,
	type,
	fileOrLinkType = 'file',
}) => {
	const fileType = fileOrLinkType === 'file' ? type?.split('/')[1] : type
	return (
		<div className={styles.content}>
			{fileType && <div className={styles.fileType}>{fileType}</div>}
			<div className={styles.label}>{name}</div>
		</div>
	)
}
