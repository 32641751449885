import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import type { ProgramAndTicketPageNewsletterResult } from '../app/data/content/components/ProgramAndTicketPageNewsletterFragment'
import type { TicketPaymentInfoResult } from '../app/data/content/components/TicketPaymentInfoFragment'
import { NewsletterFormInputs } from '../app/forms/NewsletterForm/NewsletterFormInputs'
import { validateZod } from '../app/validators/utils/validateZod'
import { createFetcher } from '../libs/next/api/jsonApiHandler'
import type { API } from '../pages/api/v1/[handler]'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import { CheckboxField, InputField } from './FormFields'
import { ImageAspectRatio } from './ImageAspectRatio'
import { Modal } from './Modal'
import style from './ProgramAndTicketPageNewsletterBox.module.sass'
import { ProgramAndTicketPagePriceInfo } from './ProgramAndTicketPagePriceInfo'
import { useNewsletterFormData } from './contexts/FormDataContextProvider'
import { useGetRecaptchaToken } from './contexts/RecaptchaContextProvider'

export type ProgramAndTicketPagenewsletterProps = {
	type?: 'default' | 'inline'
	ticketPaymentInfo?: TicketPaymentInfoResult
} & ProgramAndTicketPageNewsletterResult

export const api = createFetcher<API>('/api/v1/[handler]')

export const ProgramAndTicketPageNewsletterBox: FunctionComponent<
	ProgramAndTicketPagenewsletterProps
> = ({ type = 'default', newsletter, title, textInfo, image, buttonLabel, ticketPaymentInfo }) => {
	const [openNewsletter, setOpenNewsletter] = useState(false)
	const [modalIsOpen, setModalOpen] = useState(false)
	const translation = useTranslate()
	const formDataTranslation = useNewsletterFormData()

	const flashSuccessMessage = formDataTranslation?.localesByLocale?.successMessage
	const flashErrorMessage = formDataTranslation?.localesByLocale?.errorMessage

	const mutation = useMutation(async (formData: FormData) => {
		const validatedData = validateZod(formData, NewsletterFormInputs())

		return api('newsletterForm', validatedData)
	})

	const getRecaptchaToken = useGetRecaptchaToken()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (event.currentTarget) {
			const formData = new FormData(event.currentTarget)
			const recaptchaToken = await getRecaptchaToken()
			setOpenNewsletter(false)
			if (recaptchaToken === null) {
				toast.error(flashErrorMessage, { toastId: newsletter?.id })
				return
			}
			formData.append('recaptcha', recaptchaToken)
			await mutation
				.mutateAsync(formData)
				.then((result) => {
					if (result) {
						if (!result.contemberStatus?.ok) {
							console.error(result.contemberStatus?.errorMessage)
						}
						if (result.status) {
							toast.success(flashSuccessMessage, {
								toastId: newsletter?.id,
							})
						} else {
							toast.error(flashErrorMessage, { toastId: newsletter?.id })
							console.error(result.error, 'error')
						}
					}
				})
				.catch((e) => {
					toast.error(flashErrorMessage, { toastId: newsletter?.id })
					console.error(e)
				})
		}
	}

	return (
		<div
			className={clsx(
				style.wrapper,
				!title && !textInfo && !newsletter && style.is_hidden,
				type !== 'default' && style[`view_${type}`]
			)}>
			<div className={clsx(style.box, openNewsletter && style.shadow)}>
				{title && <h3 className={style.title}>{title}</h3>}
				{!openNewsletter && (
					<Button type="button" variant="red" onClick={() => setOpenNewsletter(true)} size="medium">
						{translation('iWantNewsletter')}
					</Button>
				)}
				{newsletter && openNewsletter && (
					<>
						<form
							className={style.form}
							onSubmit={(event) => {
								event.preventDefault()
								handleSubmit(event)
							}}>
							<div className={style.fieldNames}>
								<InputField
									type="text"
									name="firstName"
									placeholder={formDataTranslation?.localesByLocale?.firstnamePlaceholder}
									required
								/>
								<InputField
									type="text"
									name="lastName"
									placeholder={formDataTranslation?.localesByLocale?.lastnamePlaceholder}
									required
								/>
							</div>
							<InputField
								type="email"
								name="email"
								placeholder={formDataTranslation?.localesByLocale?.emailPlaceholder}
								required
							/>
							<div className={style.termsAndAgreements}>
								<CheckboxField
									label={
										formDataTranslation?.localesByLocale?.termsAndAgreements ? (
											<RichTextRenderer
												source={formDataTranslation?.localesByLocale.termsAndAgreements}
											/>
										) : (
											''
										)
									}
									name="terms_and_conditions"
									customClassName="checkboxProgramAndTicketPageNewsletterBox"
									required
								/>
							</div>
							<input type="hidden" name="newsletterListId" value={newsletter.id} />

							<Button type="submit" variant="red" size="medium">
								{translation('iWantNewsletter')}
							</Button>
						</form>
					</>
				)}
				{formDataTranslation?.localesByLocale?.gdpr && openNewsletter && (
					<div className={style.gdpr}>
						<RichTextRenderer source={formDataTranslation.localesByLocale.gdpr} />
					</div>
				)}
			</div>
			{textInfo && (
				<div className={style.textInfo}>
					<RichTextRenderer source={textInfo} />
				</div>
			)}
			{(buttonLabel || image) && (
				<>
					<button
						className={style.ticketButton}
						onClick={() => {
							setModalOpen(!modalIsOpen)
						}}>
						<ImageAspectRatio
							sizes="100px"
							image={image}
							layout="maxHeight"
							maxHeight={42}
							isBackgroundTransparent
						/>
						<span className={style.ticketLabel}>{buttonLabel}</span>
					</button>
					<Modal isOpen={modalIsOpen} setModalOpen={setModalOpen} isNoVerticalPadding>
						{ticketPaymentInfo && <ProgramAndTicketPagePriceInfo {...ticketPaymentInfo} />}
					</Modal>
				</>
			)}
		</div>
	)
}
