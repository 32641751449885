import type { FunctionComponent } from 'react'
import type { PartnerGroupResult } from '../app/data/content/components/PartnerGroupFragment'
import style from './PartnerGroupItem.module.sass'
import { PartnerItem } from './PartnerItem'

export type PartnerGroupItemProps = PartnerGroupResult

export const PartnerGroupItem: FunctionComponent<PartnerGroupItemProps> = ({ title, items }) => {
	return (
		<div className={style.wrapper}>
			{title && <h3 className={style.title}>{title}</h3>}
			<div className={style.list}>
				{items.map((item) => (
					<div key={item.id} className={style.item}>
						<PartnerItem {...item} />
					</div>
				))}
			</div>
		</div>
	)
}
