import Link from 'next/link'
import type { EmployeeResult } from '../app/data/content/components/EmployeeFragment'
import style from './PeoplePagePersonEmployeeItem.module.sass'
import { PeoplePagePersonItemImage } from './PeoplePagePersonItemImage'

export type PeoplePagePersonEmployeeItemProps = EmployeeResult

export const PeoplePagePersonEmployeeItem: React.FunctionComponent<
	PeoplePagePersonEmployeeItemProps
> = ({ image, firstName, lastName, positions, email, telephone }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.image}>
				<PeoplePagePersonItemImage image={image} />
			</div>
			<h3 className={style.name}>
				{firstName && firstName} {lastName}
			</h3>
			<div className={style.contact}>
				{positions.length > 0 && (
					<div className={style.positions}>
						{positions.map((position, index) => (
							<span key={position.localesByLocale?.id}>
								{position.localesByLocale?.name}
								{positions.length !== index + 1 && ', '}
							</span>
						))}
					</div>
				)}
				{telephone && (
					<Link href={`tel:${telephone}`} className={style.telephone}>
						{telephone}
					</Link>
				)}
				{email && (
					<Link href={`mailto:${email}`} className={style.email}>
						{email}
					</Link>
				)}
			</div>
		</div>
	)
}
