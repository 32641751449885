import clsx from 'clsx'
import React, { useCallback, useMemo, type FunctionComponent } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { isDefined } from '../utils/isDefined'
import { Container } from './Container'
import type { ListCategorySwitcherProps } from './ListCategorySwitcher'
import { Tags, type TagsProps } from './Tags'
import styles from './WorkshopCategory.module.sass'
import { WorkshopListCategorySwitcher } from './WorkshopListCategorySwitcher'
import type { WorkshopPageProps } from './WorkshopPage'
import { WorkshopSubcategoryDivider } from './WorkshopSubcategoryDivider'
import { WorkshopSubcategorySwitcher } from './WorkshopSubcategorySwitcher'
import { WorkshopTileLinkList } from './WorkshopTileLinkList'
import { Wysiwyg } from './Wysiwyg'

export type WorkshopCategoryProps = Exclude<
	Pick<CommonPageProps['page'], 'workshopCategory'>['workshopCategory'],
	null
>

// component is nearly identical as WorkshopPage.tsx but for better overview the code is separated
export const WorkshopCategory: React.FunctionComponent<WorkshopCategoryProps> = ({
	listWorkshopCategory,
	currentCategory,
	getWorkshopPage,
	listWorkshop,
	subcategoryKey,
	subcategoryValue,
	root,
}) => {
	const subcategories =
		root && 'subcategories' in root ? root.subcategories.filter(isDefined) : null

	const tagList = useCallback((workshops: WorkshopPageProps['listWorkshop'][number]) => {
		const tags: TagsProps['items'] = []
		// only ageFrom tag
		if (workshops?.ageFrom?.label) {
			tags.push({
				text: workshops.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		// all other tags
		workshops.tags?.items.map(
			(item) =>
				item.tag?.localesByLocale?.title &&
				tags.push({
					text: item.tag.localesByLocale.title,
				})
		)

		return tags
	}, [])

	const categories = useMemo(() => {
		const array: ListCategorySwitcherProps['categories'] = []

		listWorkshopCategory.map((category) => {
			if (!category.localesByLocale?.link) {
				return
			}
			array.push({
				link: category.localesByLocale.link,
				name: category.localesByLocale.name,
				isHidden: category.localesByLocale.isHidden,
				descriptionChildren: <CategorySwitcherDescription category={category} />,
			})
		})

		return array
	}, [listWorkshopCategory])

	return (
		<Container size="large" disableGutters="full">
			<div className={styles.wrapper}>
				<div className={clsx(styles.switcher)}>
					<WorkshopListCategorySwitcher
						currentCategoryName={currentCategory?.localesByLocale?.name}
						categories={categories}
						returnPage={getWorkshopPage?.localesByLocale?.link?.url}
					/>
				</div>
				<div>
					{subcategories && (
						<div
							className={subcategories.length > 0 ? styles.switcherWithSubcategories : undefined}>
							<WorkshopSubcategorySwitcher subcategories={subcategories} urlKey={subcategoryKey} />
						</div>
					)}
					{currentCategory && currentCategory.workshops?.length > 0 && (
						<>
							{subcategoryValue && subcategories ? (
								<WorkshopSubcategoryDivider
									subcategories={subcategories}
									currentSubcategory={subcategoryValue}
									listWorkshop={listWorkshop}
								/>
							) : (
								<WorkshopTileLinkList
									items={currentCategory.workshops.map((item) => ({
										id: item.id,
										image: item.tileImage,
										link: item.localesByLocale?.link,
										title: item.localesByLocale?.title,
										lead: item.localesByLocale?.lead,
										tags: item.ageFrom?.label && <Tags items={tagList(item)} isAlignCenter />,
									}))}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</Container>
	)
}

type CategorySwitcherDescriptionProps = {
	category: WorkshopCategoryProps['listWorkshopCategory'][number]
}

export const CategorySwitcherDescription: FunctionComponent<CategorySwitcherDescriptionProps> = ({
	category,
}) => {
	if (
		category?.localesByLocale?.description?.items &&
		category.localesByLocale.description.items.length > 0
	) {
		return (
			<div className={styles.description}>
				{category.localesByLocale.description.items.map((item) => (
					<div key={item.id} className={styles.descriptionText}>
						<Wysiwyg source={item.text} />
					</div>
				))}
			</div>
		)
	}
	return <></>
}
