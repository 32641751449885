import type { FunctionComponent } from 'react'
import React from 'react'
import { useTranslate } from '../utils/useTranslate'
import styles from './ProgramAndTicketPageEventTags.module.sass'
import type { EventTypeArray } from './ProgramAndTicketPageTileGroupedEvents'
import { EventType } from './ProgramAndTicketPageTileGroupedEvents'
import { Tag } from './Tag'

export type ProgramAndTicketPageEventTagsProps = {
	eventTypeArray: EventTypeArray
	eventType?: EventType
}

export const ProgramAndTicketPageEventTags: FunctionComponent<
	ProgramAndTicketPageEventTagsProps
> = ({ eventTypeArray, eventType }) => {
	const translation = useTranslate()
	return (
		<div className={styles.wrapper}>
			{/* Event might be connected to more than 1 repertoire/workshop */}
			{eventTypeArray.map((event) => (
				<React.Fragment key={event.id}>
					{/* age */}
					{event.root?.ageFrom?.label && (
						<div className={styles.tag}>
							<Tag text={event.root.ageFrom.label.toString()} hasAddCharacter />
						</div>
					)}
					{/* categories from workshop */}
					{event?.root?.categories && event.root.categories.length > 0 ? (
						event.root.categories.map((category, index) => (
							<React.Fragment key={`${category.id}-${index}`}>
								{category?.localesByLocale &&
									category.localesByLocale.name &&
									eventType === EventType.workshop && (
										<div className={styles.tag}>
											<Tag text={category.localesByLocale.name} />
										</div>
									)}
							</React.Fragment>
						))
					) : (
						<div className={styles.tag}>
							{/* Fallback translation if (workshop) event doesn't contain any category */}
							<Tag text={translation('ProgramAndTicketPageEventTags.label.workshop')} />
						</div>
					)}
					{event.root?.tags?.items.map(
						(item) =>
							item.tag?.localesByLocale?.title && (
								<div key={item.id} className={styles.tag}>
									<Tag text={item.tag.localesByLocale.title} />
								</div>
							)
					)}
				</React.Fragment>
			))}
		</div>
	)
}
