import clsx from 'clsx'
import { useMemo, type FunctionComponent } from 'react'
import { Audio } from './Audio'
import { DetailHeroGallery } from './DetailHeroGallery'
import { DetailHeroPartners } from './DetailHeroPartners'
import { DetailTableSpecification } from './DetailTableSpecification'
import { DetailUpcomingProgram } from './DetailUpcomingProgram'
import type { RepertoireDetailProps } from './RepertoireDetail'
import styles from './RepertoireDetailHero.module.sass'
import { Tags, type TagsProps } from './Tags'
import { Wysiwyg } from './Wysiwyg'

export type RepertoireDetailHeroProps = Pick<
	RepertoireDetailProps,
	'root' | 'title' | 'text' | 'audio' | 'tableSpecification' | 'eventTitle'
>

export const RepertoireDetailHero: FunctionComponent<RepertoireDetailHeroProps> = ({
	root,
	title,
	text,
	audio,
	tableSpecification,
	eventTitle,
}) => {
	const media = root?.cover

	const tagList = useMemo(() => {
		const tags: TagsProps['items'] = []
		if (root?.ageFrom?.label) {
			tags.push({
				text: root.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		if (root?.tags?.items) {
			root.tags.items.map((item) => {
				item.tag?.localesByLocale?.title && tags.push({ text: item.tag.localesByLocale.title })
			})
		}
		return tags
	}, [root?.ageFrom?.label, root?.tags?.items])
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.column, styles.is_media)}>
				<div className={styles.media}>
					{media && (
						<div className={styles.gallery}>
							<DetailHeroGallery {...media} />
						</div>
					)}
					{root?.partners && root.partners.items.length > 0 && (
						<div className={styles.partners}>
							<DetailHeroPartners {...root.partners} />
						</div>
					)}
					<div className={styles.upcomingProgram}>
						<div className={styles.upcomingProgramIn}>
							<DetailUpcomingProgram
								events={eventTitle?.events?.map((item) => ({
									webTicketId: item.webTicketId,
									id: item.id,
									repertoireTitle: title,
									title: item.title,
									datumOd: item.datumOd,
									datumOdModified: item.datumOdModified,
									venue: item.venue,
									volno: item.volno,
									ageFrom: root?.ageFrom?.label,
									isHiddenEvent: item.isHiddenEvent,
									skoly: item.skoly,
								}))}
								type="repertoire"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={clsx(styles.column, styles.is_content)}>
				{title && <h1 className={styles.title}>{title}</h1>}
				{audio && audio.items.length > 0 && (
					<div className={styles.audio}>
						<Audio items={audio.items} isRepertoire />
					</div>
				)}
				{tagList.length > 0 && (
					<div className={styles.tags}>
						<Tags items={tagList} />
					</div>
				)}
				{text && (
					<div className={styles.text}>
						<Wysiwyg source={text} />
					</div>
				)}
				<div className={styles.tableSpecification}>
					<DetailTableSpecification table={tableSpecification} />
				</div>
			</div>
		</div>
	)
}
