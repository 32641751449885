import type { FunctionComponent } from 'react'
import type { TicketPaymentInfoMethodResult } from '../app/data/content/components/TicketPaymentInfoMethodFragment'
import { ProgramAndTicketPagePaymentMethod } from './ProgramAndTicketPagePaymentMethod'
import style from './ProgramAndTicketPagePaymentMethods.module.sass'

export type ProgramAndTicketPagePaymentMethodsProps = {
	paymentMethods: TicketPaymentInfoMethodResult[]
}

export const ProgramAndTicketPagePaymentMethods: FunctionComponent<
	ProgramAndTicketPagePaymentMethodsProps
> = ({ paymentMethods }) => {
	return (
		<div className={style.wrapper}>
			{paymentMethods && paymentMethods.length > 0 && (
				<ul className={style.list}>
					{paymentMethods.map(
						(paymentMethod, index) =>
							paymentMethod.text && (
								<li className={style.item} key={paymentMethod.id}>
									<ProgramAndTicketPagePaymentMethod order={index + 1} {...paymentMethod} />
								</li>
							)
					)}
				</ul>
			)}
		</div>
	)
}
