import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ProductLocaleResult } from '../app/data/content/components/ProductLocaleFragment'
import { usePicturesGallery } from '../utils/usePicturesGallery'
import style from './EshopProduct.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type EshopProductProps = ProductLocaleResult

export const EshopProduct: FunctionComponent<EshopProductProps> = ({
	root,
	name,
	description,
	price,
}) => {
	const pictures = root?.gallery ?? undefined
	const productImage = pictures?.items[0].medium?.image ?? undefined

	const lightbox = usePicturesGallery(pictures)

	return (
		<div className={style.wrapper}>
			{pictures?.items && pictures.items.length > 0 && (
				<button
					aria-label={productImage?.alt ?? name}
					type="button"
					onClick={() => pictures && lightbox?.open(0)}
					className={style.button}>
					{productImage && (
						<Image
							className={style.image}
							src={productImage.url}
							width={productImage.width}
							height={productImage.height}
							sizes="(min-width: 480px) 350px, (min-width: 768px) 310px, 100vw"
							alt={productImage.alt ?? ''}
							blurDataURL={productImage.url}
							placeholder="blur"
						/>
					)}
				</button>
			)}
			<div className={style.despcription}>
				{name && <h4 className={style.name}>{name}</h4>}
				{description && (
					<div className={style.description}>
						<Wysiwyg source={description} />
					</div>
				)}
				{price && <div className={style.price}>{price}</div>}
			</div>
		</div>
	)
}
