import type { ReferenceRendererProps, RichTextRendererProps } from '@contember/react-client'
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo } from 'react'
import type { ContentBlockResult } from '../app/data/content/components/ContentBlockFragment'
import type { ContentResult } from '../app/data/content/components/ContentFragment'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { AsideMediaContentBlock } from './AsideMediaContentBlock'
import { AudioBlock } from './AudioBlock'
import type { ContainerProps } from './Container'
import { Container } from './Container'
import style from './Content.module.sass'
import { FileOrLink } from './FileOrLink'
import { GridGallery } from './GridGallery'
import { GridTiles } from './GridTiles'
import { ImageBlock } from './ImageBlock'
import { PartnerGroupList } from './PartnerGroupList'
import { PartnerImage } from './PartnerImage'
import { UpcomingProgram } from './UpcomingProgram'
import { VideoTiles } from './VideoTiles'
import { Wysiwyg } from './Wysiwyg'
import { YoutubeVideo } from './YoutubeVideo'

export interface ContentRendererProps {
	content: ContentResult
	textGutters?: ContainerProps['disableGutters']
	// @TODO: temporary - after galleries will be deleted then revert code
	isRepertoireDetail?: boolean
	isWorkshopDetail?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference', 'table', 'tableRow', 'tableCell', 'scrollTarget']
const nestedTypes = ['listItem', 'anchor']

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	textGutters,
	// @TODO: temporary - after galleries will be deleted then revert code
	isRepertoireDetail = false,
	isWorkshopDetail = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)
	return (
		<div className={style.wrapper}>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={useCallback<NonNullable<RichTextRendererProps['renderElement']>>(
					(element) => {
						const { type } = element.element
						if (standaloneTypes.includes(type) || nestedTypes.includes(type)) {
							return element.fallback
						}
						return (
							<Container disableGutters={textGutters ?? 'vertical'}>
								<Wysiwyg>{element.fallback}</Wysiwyg>
							</Container>
						)
					},
					[textGutters]
				)}
				referenceRenderers={useMemo(
					() => ({
						asideMediaContent: function AsideMediaContent({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.asideMediaContent)}>
									{reference.primaryText && (
										<Container disableGutters="vertical">
											<AsideMediaContentBlock
												title={reference.primaryText}
												text={reference.secondaryText}
												imageDescription={reference.terciaryText}
												image={reference.image}
											/>
										</Container>
									)}
								</section>
							)
						},
						tiles: function Tiles({ reference }: Block) {
							if (isWorkshopDetail) {
								return null
							} else {
								return (
									<section className={clsx(style.section, style.tiles)}>
										{/* GridTiles component handles container component */}
										{reference.tiles?.items && <GridTiles {...reference.tiles} />}
									</section>
								)
							}
						},
						fileOrLink: function FileOrLinkContent({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_fileOrLink)}>
									{reference.fileOrLink && (
										<Container disableGutters="vertical">
											<FileOrLink text={reference.fileOrLink.title} file={reference.fileOrLink} />
										</Container>
									)}
								</section>
							)
						},
						image: function Image({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_image)}>
									{reference.image && (
										<Container disableGutters="vertical">
											<ImageBlock image={reference.image} link={reference.link} />
										</Container>
									)}
								</section>
							)
						},
						gallery: function Gallery({ reference }: Block) {
							// @TODO: temporary - after galleries will be deleted then revert code
							if (isRepertoireDetail) {
								return null
							} else {
								return (
									<section
										className={clsx(
											style.section,
											style.view_gallery,
											style[`view_${reference.galleryType}`]
										)}>
										{reference.gallery && (
											<Container disableGutters="vertical">
												<div className={style.container}>
													<GridGallery gallery={reference.gallery} type={reference.galleryType} />
												</div>
											</Container>
										)}
									</section>
								)
							}
						},
						partnerLink: function PartnerLink({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_partnerItem)}>
									{reference.partner?.localesByLocale?.image && (
										<Container disableGutters="vertical">
											<PartnerImage
												image={reference.partner.localesByLocale.image}
												link={reference.partner.localesByLocale.link}
											/>
										</Container>
									)}
								</section>
							)
						},
						partnerList: function PartnerList({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_partnerList)}>
									{reference.partners?.groups && (
										<Container disableGutters="vertical">
											<PartnerGroupList groups={reference.partners.groups} />
										</Container>
									)}
								</section>
							)
						},
						youtubeVideos: function YoutubeVideos({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_youtubeVideos)}>
									<Container disableGutters="vertical">
										{reference.youtubeVideoType === 'asideTitleWithVideos' &&
										reference.youtubeVideoList?.videos ? (
											<VideoTiles
												type="block"
												title={reference.youtubeVideoList.title}
												videos={reference.youtubeVideoList.videos.map((video) => video.video)}
												link={reference.link}
											/>
										) : reference.youtubeVideoType === 'single' && reference.youtubeVideo ? (
											<YoutubeVideo {...reference.youtubeVideo} />
										) : null}
									</Container>
								</section>
							)
						},
						audio: function Audio({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_audio)}>
									<Container disableGutters="vertical">
										{reference.musicTrackList && reference.musicTrackList.items.length > 0 && (
											<AudioBlock items={reference.musicTrackList.items} />
										)}
									</Container>
								</section>
							)
						},
						anchor: function anchor({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_anchor)}>
									<span
										id={reference.primaryText?.replace('#', '')}
										className={style.isNonVisible}
									/>
								</section>
							)
						},
						program: function program({ reference }: Block) {
							return (
								<section className={clsx(style.section, style.view_program)}>
									<Container disableGutters="vertical">
										{reference.program && (
											<UpcomingProgram
												title={reference.primaryText}
												program={reference.program}
												link={reference.link}
											/>
										)}
									</Container>
								</section>
							)
						},
					}),
					[isRepertoireDetail, isWorkshopDetail]
				)}
			/>
		</div>
	)
}
