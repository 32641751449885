import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import Select from 'react-select'
import { useTranslate } from '../utils/useTranslate'
import { Container } from './Container'
import type { PageTileLinkItemProps } from './PageTileLinkItem'
import type { RepertoirePageProps } from './RepertoirePage'
import style from './RepertoirePageCategoryFilter.module.sass'

export type RepertoirePageCategoryFilterProps = {
	categories: RepertoirePageProps['categories']
	repertoireFiltered: PageTileLinkItemProps[]
	activeCategoryId: string
	setActiveCategoryId: Dispatch<SetStateAction<string>>
}

export const RepertoirePageCategoryFilter: FunctionComponent<RepertoirePageCategoryFilterProps> = ({
	categories,
	repertoireFiltered,
	activeCategoryId,
	setActiveCategoryId,
}) => {
	const router = useRouter()
	const options = repertoireFiltered.map((item) => ({
		value: item.id,
		label: item.title,
	}))

	const translation = useTranslate()
	return (
		<div className={style.wrapper}>
			<Container>
				<div className={style.main}>
					<div className={style.columnSelect}>
						<h3 className={style.title}>
							{translation('RepertoirePageCategoryFilter.selectRepertoire')}
						</h3>
						<Select
							instanceId="repertoirePageCategoryFilter"
							className={style.select}
							options={options}
							placeholder={translation('RepertoirePageCategoryFilter.placeholder')}
							classNamePrefix="select"
							isClearable={false}
							controlShouldRenderValue={false}
							noOptionsMessage={() => translation('RepertoirePageCategoryFilter.noOptions')}
							onChange={(option) => {
								const item = repertoireFiltered.find(
									(repertoire) => repertoire.id === option?.value
								)

								// @TODO: refactor
								if (item?.link) {
									if ('url' in item.link && item.link.url) {
										router.push(item.link.url)
									} else if ('type' in item.link && item.link.type) {
										if (item.link.type === 'internal' && item.link.internalLink?.url) {
											router.push(item.link.internalLink?.url)
										} else if (item.link.type === 'external' && item.link.externalLink) {
											router.push(item.link.externalLink)
										}
									}
								}
							}}
						/>
					</div>
					<ul className={style.list}>
						{categories.map((category) => (
							<li key={category.id} className={style.item}>
								<button
									className={clsx(
										style.button,
										activeCategoryId === category.id && style.is_active
									)}
									type="button"
									onClick={() => setActiveCategoryId(category.id)}>
									{category.name}
								</button>
							</li>
						))}
					</ul>
				</div>
			</Container>
		</div>
	)
}
