import clsx from 'clsx'
import React from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Button } from './Button'
import { Container } from './Container'
import style from './PeoplePage.module.sass'
import { PeoplePagePersonEmployeeDepartmentList } from './PeoplePagePersonEmployeeDepartmentList'
import { PeoplePagePersonList } from './PeoplePagePersonList'
import { Wysiwyg } from './Wysiwyg'

export type PeoplePageProps = Exclude<
	Pick<CommonPageProps['page'], 'peoplePage'>['peoplePage'],
	null
>

export const PeoplePage: React.FunctionComponent<PeoplePageProps> = ({
	peoplePageList,
	currentPeoplePage,
	actors,
	lectors,
	employeeDepartments,
	currentPeoplePageType, // actors | employees | lectors from PeoplePageType enum
}) => {
	return (
		<div className={style.wrapper}>
			<Container size="large" disableGutters="full">
				<div className={style.switcher}>
					{peoplePageList.map((type) => (
						<React.Fragment key={type.id}>
							{type.localesByLocale?.link && (
								<div className={style.buttonSwitch}>
									<Button
										type="link"
										link={type.localesByLocale.link.url}
										viewType="tab"
										isActive={type.localesByLocale.root?.type === currentPeoplePageType}>
										{type.localesByLocale.title}
									</Button>
								</div>
							)}
						</React.Fragment>
					))}
				</div>

				{currentPeoplePage?.localesByLocale?.content && (
					<div className={style.content}>
						<Wysiwyg source={currentPeoplePage.localesByLocale.content} />
					</div>
				)}

				<div
					className={clsx(
						style.main,
						currentPeoplePage?.localesByLocale?.root?.type === 'employees' && style.view_employees
					)}>
					{currentPeoplePage?.localesByLocale?.root?.type === 'employees' &&
					employeeDepartments.length > 0 ? (
						<PeoplePagePersonEmployeeDepartmentList departments={employeeDepartments} />
					) : currentPeoplePage?.localesByLocale?.root?.type === 'actors' && actors.length > 0 ? (
						<PeoplePagePersonList persons={actors} />
					) : currentPeoplePage?.localesByLocale?.root?.type === 'lectors' && lectors.length > 0 ? (
						<PeoplePagePersonList persons={lectors} />
					) : null}
				</div>
			</Container>
		</div>
	)
}
