import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { combineUrl } from '../app/data/combineUrl'
import { AboutTheatreTileFragment } from '../app/data/content/components/AboutTheatreTileFragment'
import { ActorFragment } from '../app/data/content/components/ActorFragment'
import { ComplexEventCategoryFragment } from '../app/data/content/components/ComplexEventCategoryFragment'
import { ContactFormLocaleFragment } from '../app/data/content/components/ContactFormLocale'
import { EmployeeDepartmentFragment } from '../app/data/content/components/EmployeeDepartmentFragment'
import { Error404PageLocaleFragment } from '../app/data/content/components/Error404PageLocale'
import { EventWithTitleAndRepertoiresOrWorkshopsLocaleFragment } from '../app/data/content/components/EventWithTitleAndRepertoiresOrWorkshopsLocaleFragment'
import { FooterSocialFragment } from '../app/data/content/components/FooterSocialLinkFragment'
import { ImageFragment } from '../app/data/content/components/ImageFragment'
import { InternalLinkFragment } from '../app/data/content/components/InternalLinkFragment'
import { LectorFragment } from '../app/data/content/components/LectorFragment'
import { LinkFragment } from '../app/data/content/components/LinkFragment'
import { MinorTvFragment } from '../app/data/content/components/MinorTvFragment'
import { NavigationFragment } from '../app/data/content/components/NavigationFragment'
import { NavigationSecondaryFragment } from '../app/data/content/components/NavigationSecondaryFragment'
import { NewsletterFormLocaleFragment } from '../app/data/content/components/NewsletterFormLocale'
import { SeoFragment } from '../app/data/content/components/OgSchemaFragment'
import { OrderFormLocaleFragment } from '../app/data/content/components/OrderFormLocale'
import { PartnerGroupFragment } from '../app/data/content/components/PartnerGroupFragment'
import { ProductCategoryFragment } from '../app/data/content/components/ProductCategoryFragment'
import { ProductLocaleFragment } from '../app/data/content/components/ProductLocaleFragment'
import { ProgramAndTicketPageInfoFragment } from '../app/data/content/components/ProgramAndTicketPageInfoFragment'
import { RedirectFragment } from '../app/data/content/components/RedirectFragment'
import { RepertoireMinorovinyFragment } from '../app/data/content/components/RepertoireMinorovinyFragment'
import { RepertoireTileFragment } from '../app/data/content/components/RepertoireTileFragment'
import { ReviewFormLocaleFragment } from '../app/data/content/components/ReviewFormLocale'
import { TranslationDomainWithCatalougesByIdentifierFragment } from '../app/data/content/components/TranslationDomainWithCatalougesByIdentifierFragment'
import { VideoYoutubeCategoryLocaleFragment } from '../app/data/content/components/VideoYoutubeCategoryFragment'
import { VideoYoutubeFragment } from '../app/data/content/components/VideoYoutubeFragment'
import { WorkshopCategoryFragment } from '../app/data/content/components/WorkshopCategoryFragment'
import { WorkshopTileFragment } from '../app/data/content/components/WorkshopTileFragment'
import { EventFilter } from '../app/data/content/filters/EventFilter'
import { AboutTheatreDetailFragment } from '../app/data/content/pages/AboutTheatreDetailFragment'
import { AboutTheatrePageDetailFragment } from '../app/data/content/pages/AboutTheatrePageDetailFragment'
import { AnnualReportDetailFragment } from '../app/data/content/pages/AnnualReportDetailFragment'
import { AnnualReportPageDetailFragment } from '../app/data/content/pages/AnnualReportPageDetailFragment'
import { ContactPageDetailFragment } from '../app/data/content/pages/ContactPageDetailFragment'
import { EmployeeDetailFragment } from '../app/data/content/pages/EmployeeDetailFragment'
import { EshopPageDetailFragment } from '../app/data/content/pages/EshopPageDetailFragment'
import { GameStestickaDetailFragment } from '../app/data/content/pages/GameStestickaPageDetailFragment'
import { HomePageDetailFragment } from '../app/data/content/pages/HomePageDetailFragment'
import { NewspaperPageDetailFragment } from '../app/data/content/pages/NewspaperPageDetailFragment'
import { PageDetailFragment } from '../app/data/content/pages/PageDetailFragment'
import { PeoplePageDetailFragment } from '../app/data/content/pages/PeoplePageDetailFragment'
import { PersonDetailLocaleFragment } from '../app/data/content/pages/PersonDetailLocaleFragment'
import { ProgramAndTicketPageDetailLocaleFragment } from '../app/data/content/pages/ProgramAndTicketPageDetailFragment'
import { RepertoireDetailFragment } from '../app/data/content/pages/RepertoireDetailFragment'
import { RepertoirePageDetailFragment } from '../app/data/content/pages/RepertoirePageDetailFragment'
import { VideoPageDetailFragment } from '../app/data/content/pages/VideoPageDetailFragment'
import { VideoYoutubeCategoryDetailFragment } from '../app/data/content/pages/VideoYoutubeCategoryDetailFragment'
import { WorkshopCategoryDetailFragment } from '../app/data/content/pages/WorkshopCategoryDetailFragment'
import { WorkshopDetailFragment } from '../app/data/content/pages/WorkshopDetailFragment'
import { WorkshopPageDetailFragment } from '../app/data/content/pages/WorkshopDetailPageFragment'
import { scalars } from '../app/data/content/scalars'
import { AboutTheatreDetail } from '../components/AboutTheatreDetail'
import { AboutTheatrePage } from '../components/AboutTheatrePage'
import { AnnualReportDetail } from '../components/AnnualReportDetail'
import { AnnualReportPage } from '../components/AnnualReportPage'
import { ContactPage } from '../components/ContactPage'
import { Error404Page } from '../components/Error404Page'
import { EshopPage } from '../components/EshopPage'
import { GameStestickaPage } from '../components/GameStestickaPage'
import { GeneralPage } from '../components/GeneralPage'
import { HomePage } from '../components/HomePage'
import type { LayoutProps } from '../components/Layout'
import { Layout } from '../components/Layout'
import { NewspaperPage } from '../components/NewspaperPage'
import { PeoplePage } from '../components/PeoplePage'
import { PersonDetail } from '../components/PersonDetail'
import { PersonEmployeeDetail } from '../components/PersonEmployeeDetail'
import { ProgramAndTicketPage } from '../components/ProgramAndTicketPage'
import { RepertoireDetail } from '../components/RepertoireDetail'
import { RepertoirePage } from '../components/RepertoirePage'
import { Seo } from '../components/Seo'
import { VideoCategoryPage } from '../components/VideoCategoryPage'
import { VideoPage } from '../components/VideoPage'
import { WorkshopCategory } from '../components/WorkshopCategory'
import { WorkshopDetail } from '../components/WorkshopDetail'
import { WorkshopPage } from '../components/WorkshopPage'
import { Thunder } from '../generated/content'
import { RespondWithRedirect, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'
import { contemberLinkToHref } from '../utils/contemberLinkToHref'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { getEventDate } from '../utils/getEventDate'
import { getEventMaxDate } from '../utils/getEventMaxDate'
import { getEventTypeFilterItems } from '../utils/getEventTypeFilterItems'
import { getEventUniqueMonths } from '../utils/getEventUniqueMonths'
import { getFilterValue, getFilterValueNumber } from '../utils/getFilterValue'
import { getGroupedEvents } from '../utils/getGroupedEvents'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage({
	header,
	footer,
	page,
	translations,
	seo,
	pageLocales,
	formDataList,
	error404page,
	isDeviceDotykovaObrazovka,
}: CommonPageProps) {
	const pageName: LayoutProps['pageName'] = page.gameStesticka
		? 'stesticka'
		: page.programAndTicketPage
		? 'programAndTicket'
		: page.newspaperPage
		? 'newspaperPage'
		: page.workshopPage
		? 'workshopPage'
		: page.workshopCategory
		? 'workshopPage'
		: undefined

	return (
		<>
			<Head>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<Seo {...seo} />
			<Layout
				header={header}
				footer={footer}
				translations={translations}
				pageName={pageName}
				pageLocales={pageLocales ?? undefined}
				formDataList={formDataList}
				isDeviceDotykovaObrazovka={isDeviceDotykovaObrazovka}>
				{page.homePage ? (
					<HomePage {...page.homePage} />
				) : page.aboutTheatrePage ? (
					<AboutTheatrePage {...page.aboutTheatrePage} />
				) : page.aboutTheatreDetail ? (
					<AboutTheatreDetail {...page.aboutTheatreDetail} />
				) : page.actor ? (
					<PersonDetail {...page.actor} />
				) : page.annualReportPage ? (
					<AnnualReportPage {...page.annualReportPage} />
				) : page.annualReportDetail ? (
					<AnnualReportDetail {...page.annualReportDetail} />
				) : page.contactPage ? (
					<ContactPage {...page.contactPage} />
				) : page.eshopPage ? (
					<EshopPage {...page.eshopPage} />
				) : page.newspaperPage ? (
					<NewspaperPage {...page.newspaperPage} />
				) : page.gameStesticka ? (
					<GameStestickaPage {...page.gameStesticka} />
				) : page.generalPage ? (
					<GeneralPage {...page.generalPage} />
				) : page.employee ? (
					<PersonEmployeeDetail {...page.employee} />
				) : page.lector ? (
					<PersonDetail {...page.lector} />
				) : page.repertoirePage ? (
					<RepertoirePage {...page.repertoirePage} />
				) : page.repertoireDetail ? (
					<RepertoireDetail {...page.repertoireDetail} />
				) : page.workshopPage ? (
					<WorkshopPage {...page.workshopPage} />
				) : page.workshopDetail ? (
					<WorkshopDetail {...page.workshopDetail} />
				) : page.workshopCategory ? (
					<WorkshopCategory {...page.workshopCategory} />
				) : page.peoplePage ? (
					<PeoplePage {...page.peoplePage} />
				) : page.programAndTicketPage ? (
					<ProgramAndTicketPage {...page.programAndTicketPage} />
				) : page.videoPage ? (
					<VideoPage {...page.videoPage} />
				) : page.videoCategoryPage ? (
					<VideoCategoryPage {...page.videoCategoryPage} />
				) : (
					<Error404Page {...error404page?.localesByLocale} />
				)}
			</Layout>
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const { path, locale } = combineUrl(context)

	const repertoireReviewKey = 'review'
	const repertoireReviewValue = getFilterValue(repertoireReviewKey, context)

	const data = await content('query', { scalars })({
		getLinkable: [
			{
				by: {
					url: path,
				},
			},
			{
				id: true,
				url: true,
				homePage: [{}, HomePageDetailFragment(locale)],
				aboutTheatrePage: [{}, AboutTheatrePageDetailFragment(locale)],
				aboutTheatre: [{}, AboutTheatreDetailFragment(locale)],
				annualReportPage: [{}, AnnualReportPageDetailFragment(locale)],
				annualReport: [{}, AnnualReportDetailFragment()],
				actor: [{}, PersonDetailLocaleFragment(locale)],
				contactPage: [{}, ContactPageDetailFragment(locale)],
				employee: [{}, EmployeeDetailFragment(locale)],
				eshopPage: [{}, EshopPageDetailFragment(locale)],
				gameStestickaPage: [{}, GameStestickaDetailFragment()],
				lector: [{}, PersonDetailLocaleFragment(locale)],
				newspaperPage: [{}, NewspaperPageDetailFragment()],
				page: [{}, PageDetailFragment(locale)],
				peoplePage: [{}, PeoplePageDetailFragment()],
				programAndTicketPage: [{}, ProgramAndTicketPageDetailLocaleFragment(locale)],
				repertoirePage: [{}, RepertoirePageDetailFragment(locale)],
				repertoire: [{}, RepertoireDetailFragment(locale)],
				videoPage: [{}, VideoPageDetailFragment(locale)],
				videoYoutubeCategory: [{}, VideoYoutubeCategoryDetailFragment(locale)],
				workshopPage: [{}, WorkshopPageDetailFragment(locale)],
				workshop: [{}, WorkshopDetailFragment(locale)],
				workshopCategory: [{}, WorkshopCategoryDetailFragment(locale, true)],
				redirect: [{}, RedirectFragment()],
			},
		],
		getHomePage: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: { code: locale },
						},
					},
					{
						seo: [{}, SeoFragment()],
					},
				],
			},
		],
		getTranslationDomain: [
			{
				by: {
					identifier: 'app',
				},
			},
			TranslationDomainWithCatalougesByIdentifierFragment(locale),
		],
		getContactForm: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					ContactFormLocaleFragment(),
				],
			},
		],
		getNewsletterForm: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					NewsletterFormLocaleFragment(),
				],
			},
		],
		getOrderForm: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					OrderFormLocaleFragment(),
				],
			},
		],
		getReviewForm: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					ReviewFormLocaleFragment(),
				],
			},
		],
		getNewspaperPage: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					{
						link: [{}, InternalLinkFragment()],
					},
				],
			},
		],
		getSeoDefault: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					{
						seo: [{}, SeoFragment()],
					},
				],
			},
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		throw new RespondWithRedirect({ permanent: false, destination: redirectUrl })
	}

	if (!data.getLinkable?.programAndTicketPage) {
		// Program and ticket page is not cached because of the data that are imported from "web ticket" (every 5 minutes by cron)
		context.res.setHeader('Cache-Control', 's-maxage=60, stale-while-revalidate=3600')
	}

	const homePage = await (async () => {
		const initial = data.getLinkable?.homePage
		if (!initial) {
			return null
		}
		return initial
	})()

	const aboutTheatrePage = await (async () => {
		const initial = data.getLinkable?.aboutTheatrePage
		if (!initial) {
			return null
		}

		const { listAboutTheatre } = await content('query', { scalars })({
			listAboutTheatre: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				AboutTheatreTileFragment(locale),
			],
		})
		return { ...initial, listAboutTheatre }
	})()

	const aboutTheatreDetail = await (async () => {
		const initial = data.getLinkable?.aboutTheatre
		if (!initial) {
			return null
		}

		return initial
	})()

	const actor = await (async () => {
		const initial = data.getLinkable?.actor
		if (!initial) {
			return null
		}

		return initial
	})()

	const annualReportPage = await (async () => {
		const initial = data.getLinkable?.annualReportPage
		if (!initial) {
			return null
		}

		const { listAnnualReport } = await content('query', { scalars })({
			listAnnualReport: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						AnnualReportDetailFragment(),
					],
				},
			],
		})
		return { ...initial, listAnnualReport }
	})()

	const annualReportDetail = await (async () => {
		const initial = data.getLinkable?.annualReport
		if (!initial) {
			return null
		}

		return initial
	})()

	const employee = await (async () => {
		const initial = data.getLinkable?.employee
		if (!initial) {
			return null
		}

		return initial
	})()

	const contactPage = await (async () => {
		const initial = data.getLinkable?.contactPage

		if (!initial) {
			return null
		}

		return { ...initial }
	})()

	const eshopPage = await (async () => {
		const initial = data.getLinkable?.eshopPage
		if (!initial) {
			return null
		}

		const { listProduct, listProductCategory } = await content('query', { scalars })({
			listProductCategory: [{}, ProductCategoryFragment(locale)],
			listProduct: [
				{},
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						ProductLocaleFragment(locale),
					],
				},
			],
		})

		return { ...initial, listProduct, listProductCategory }
	})()

	const gameStesticka = await (async () => {
		const initial = data.getLinkable?.gameStestickaPage
		if (!initial) {
			return null
		}

		return initial
	})()

	const newspaperPage = await (async () => {
		const initial = data.getLinkable?.newspaperPage
		if (!initial) {
			return null
		}

		const repertoireKey = 'repertoire'

		const repertoireValue = getFilterValue(repertoireKey, context)

		const { listRepertoire, publishedListRepertoire, listFilledReviewForm } = await content(
			'query',
			{ scalars }
		)({
			listRepertoire: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				RepertoireMinorovinyFragment(locale),
			],
			__alias: {
				publishedListRepertoire: {
					listRepertoire: [
						{
							filter: {
								filledReviews: {
									isPublished: {
										eq: true,
									},
								},
							},
							orderBy: [
								{
									order: 'asc',
								},
							],
						},
						RepertoireMinorovinyFragment(locale),
					],
				},
			},
			listFilledReviewForm: [
				{
					filter: {
						and: [
							{
								isPublished: {
									eq: true,
								},
							},
							{
								repertoire: {
									locales: {
										id: {
											eq: repertoireValue,
										},
									},
								},
							},
						],
					},
					orderBy: [
						{
							publishedAt: 'asc',
						},
					],
				},
				{
					id: true,
					repertoire: [
						{},
						{
							id: true,
							localesByLocale: [
								{
									by: {
										locale: {
											code: locale,
										},
									},
								},
								{
									id: true,
									title: true,
								},
							],
						},
					],
					subject: true,
					name: true,
					message: true,
				},
			],
		})

		return {
			...initial,
			listRepertoire,
			publishedListRepertoire: publishedListRepertoire,
			listFilledReviewForm,
			repertoireReviewValue,
			repertoireFilterData: {
				urlKey: repertoireKey,
				items: listFilledReviewForm
					.map((item) => ({
						id: item.repertoire?.id,
						slug: item.repertoire?.localesByLocale?.id,
						label: item.repertoire?.localesByLocale?.title,
					}))
					.filter(filterNonEmpty),
			},
		}
	})()

	const lector = await (async () => {
		const initial = data.getLinkable?.lector
		if (!initial) {
			return null
		}

		return initial
	})()

	const generalPage = await (async () => {
		const initial = data.getLinkable?.page
		if (!initial) {
			return null
		}

		return initial
	})()

	const peoplePage = await (async () => {
		const initial = data.getLinkable?.peoplePage
		if (!initial) {
			return null
		}

		const { listPeoplePage, getPeoplePage } = await content('query', { scalars })({
			listPeoplePage: [
				{},
				{
					id: true,
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						{
							root: [
								{},
								{
									type: true,
								},
							],
							id: true,
							title: true,
							link: [{}, InternalLinkFragment()],
						},
					],
				},
			],
			getPeoplePage: [
				{
					by: {
						type: initial.root?.type,
					},
				},
				{
					id: true,
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						PeoplePageDetailFragment(),
					],
				},
			],
		})

		const actors =
			initial.root?.type === 'actors'
				? await content('query', { scalars })({
						listActor: [
							{
								orderBy: [{ order: 'asc' }],
								filter: {
									isHidden: {
										eq: false,
									},
								},
							},
							ActorFragment(locale),
						],
				  })
				: undefined

		const employees =
			initial.root?.type === 'employees'
				? await content('query', { scalars })({
						listEmployeeDepartment: [
							{
								orderBy: [{ order: 'asc' }],
							},
							EmployeeDepartmentFragment(locale),
						],
				  })
				: undefined

		const lectors =
			initial.root?.type === 'lectors'
				? await content('query', { scalars })({
						listLector: [
							{
								orderBy: [{ order: 'asc' }],
								filter: {
									isHidden: {
										eq: false,
									},
								},
							},
							LectorFragment(locale),
						],
				  })
				: undefined

		return {
			...initial,
			peoplePageList: listPeoplePage,
			actors: actors?.listActor ?? [],
			employeeDepartments: employees?.listEmployeeDepartment ?? [],
			lectors: lectors?.listLector ?? [],
			currentPeoplePageType: initial.root?.type,
			currentPeoplePage: getPeoplePage,
		}
	})()

	const programAndTicketPage = await (async () => {
		const initial = data.getLinkable?.programAndTicketPage

		if (!initial) {
			return null
		}

		const ageUrlKey = 'age'
		const durationUrlKey = 'duration'
		const monthUrlKey = 'month'
		const schoolClassUrlKey = 'class'
		const typeUrlKey = 'type'
		let schoolClassValue: string | null = null
		let workshopSlug = ''
		let repertoireSlug = ''

		if (initial.root?.type === 'schools') {
			schoolClassValue = getFilterValue(schoolClassUrlKey, context)
		}

		const monthValue = getFilterValue(monthUrlKey, context)
		const ageFromValue = getFilterValueNumber(ageUrlKey, context)
		const durationValue = getFilterValueNumber(durationUrlKey, context)
		const typeValue = getFilterValue(typeUrlKey, context)

		if (initial.filterTypes) {
			workshopSlug = initial.filterTypes.workshopSlug
			repertoireSlug = initial.filterTypes.repertoireSlug
		}

		const {
			getProgramAndTicketPage,
			listProgramAndTicketPageLocale,
			listEvent,
			listEventAgeFromCategory,
			listEventDurationCategory,
			listEventSchoolClassCategory,
			months,
		} = await content('query', { scalars })({
			getProgramAndTicketPage: [
				{ by: { type: initial.root?.type } },
				{
					localesByLocale: [
						{
							by: { locale: { code: locale } },
						},
						{
							info: [{}, ProgramAndTicketPageInfoFragment(locale)],
						},
					],
				},
			],
			listProgramAndTicketPageLocale: [
				{ filter: { locale: { code: { eq: locale } } } },
				{
					id: true,
					root: [
						{},
						{
							type: true,
						},
					],
					title: true,
					pageButtonLabel: true,
					link: [{}, InternalLinkFragment()],
				},
			],
			__alias: {
				months: {
					listEvent: [
						{
							filter: {
								and: [
									{
										datumOd: {
											gte: getEventDate(),
										},
									},
									{
										skoly: {
											eq: initial.root?.type === 'schools' ? true : false,
										},
									},
									{
										datumOd: {
											// show only until last day of next month
											lte: initial.root?.type === 'schools' ? undefined : getEventMaxDate(),
										},
									},
								],
							},
							orderBy: [
								{
									datumOd: 'asc',
								},
							],
						},
						{
							datumOd: true,
							id: true,
						},
					],
				},
			},
			listEvent: [
				{
					filter: EventFilter(
						initial.root?.type,
						ageFromValue,
						durationValue,
						monthValue,
						schoolClassValue,
						locale,
						{
							workshopSlug: workshopSlug,
							repertoireSlug: repertoireSlug,
							typeValue: typeValue,
						}
					),
					orderBy: [
						{
							datumOd: 'asc',
						},
					],
				},
				EventWithTitleAndRepertoiresOrWorkshopsLocaleFragment(locale),
			],
			listEventAgeFromCategory: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				ComplexEventCategoryFragment(),
			],
			listEventDurationCategory: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				ComplexEventCategoryFragment(),
			],
			listEventSchoolClassCategory: [
				initial.root?.type === 'schools'
					? {}
					: {
							filter: {
								id: {
									isNull: true,
								},
							},
					  },
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						ComplexEventCategoryFragment(),
					],
				},
			],
		})

		const typeItems = initial.filterTypes ? getEventTypeFilterItems(initial.filterTypes) : []

		const groupedEvents = getGroupedEvents(listEvent)

		return {
			...initial,
			pages: listProgramAndTicketPageLocale,
			info: getProgramAndTicketPage?.localesByLocale?.info,
			groupedEvents,
			currentProgramAndTicketPageType: initial.root?.type,
			months: {
				urlKey: monthUrlKey,
				items: getEventUniqueMonths(months, locale),
			},
			types: {
				urlKey: typeUrlKey,
				items: typeItems,
			},
			ageCategories: {
				urlKey: ageUrlKey,
				items: listEventAgeFromCategory,
			},
			durationCategories: {
				urlKey: durationUrlKey,
				items: listEventDurationCategory,
			},
			schoolClassCategories:
				initial.root?.type === 'schools' && schoolClassUrlKey
					? {
							urlKey: schoolClassUrlKey,
							items: listEventSchoolClassCategory
								.map((item) => item.localesByLocale)
								.filter(filterNonEmpty),
					  }
					: null,
		}
	})()

	const repertoirePage = await (async () => {
		const initial = data.getLinkable?.repertoirePage
		if (!initial) {
			return null
		}

		const { listRepertoire, listRepertoireCategory } = await content('query', { scalars })({
			listRepertoire: [
				{
					filter: {
						locales: {
							and: [
								{
									locale: {
										code: {
											eq: locale,
										},
									},
									isHidden: {
										eq: false,
									},
								},
							],
						},
					},
					orderBy: [{ order: 'asc' }],
				},
				RepertoireTileFragment(locale),
			],
			listRepertoireCategory: [
				{},
				{
					id: true,
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						{ name: true },
					],
				},
			],
		})
		return {
			...initial,
			repertoires: listRepertoire,
			categories: listRepertoireCategory.map((category) => ({
				id: category.id,
				name: category.localesByLocale?.name,
			})),
		}
	})()

	const repertoireDetail = await (async () => {
		const initial = data.getLinkable?.repertoire
		if (!initial) {
			return null
		}

		return {
			...initial,
			newspaperPage: {
				urlKey: repertoireReviewKey,
				link: data.getNewspaperPage?.localesByLocale?.link ?? null,
			},
		}
	})()

	const videoPage = await (async () => {
		const initial = data.getLinkable?.videoPage
		if (!initial) {
			return null
		}
		const { listVideoYoutube, listVideoYoutubeCategory } = await content('query', { scalars })({
			listVideoYoutubeCategory: [
				{
					filter: {
						isHidden: {
							eq: false,
						},
					},
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						VideoYoutubeCategoryLocaleFragment(),
					],
				},
			],
			listVideoYoutube: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
					filter: {
						isHidden: {
							or: [{ eq: false }, { isNull: true }],
						},
					},
				},
				VideoYoutubeFragment(locale),
			],
		})
		return {
			...initial,
			listCategory: listVideoYoutubeCategory,
			videos: listVideoYoutube,
		}
	})()

	const videoCategoryPage = await (async () => {
		const initial = data.getLinkable?.videoYoutubeCategory

		if (!initial) {
			return null
		}
		const { listVideoYoutubeCategory, getVideoYoutubeCategory } = await content('query', {
			scalars,
		})({
			listVideoYoutubeCategory: [
				{
					filter: {
						isHidden: {
							eq: false,
						},
					},
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						VideoYoutubeCategoryLocaleFragment(),
					],
				},
			],
			getVideoYoutubeCategory: [
				{
					by: {
						locales: {
							name: initial.name,
						},
					},
				},
				{
					localesByLocale: [
						{
							by: {
								locale: {
									code: locale,
								},
							},
						},
						{
							name: true,
						},
					],
					videos: [
						{
							orderBy: [
								{
									order: 'asc',
								},
							],
							filter: {
								isHidden: {
									or: [{ eq: false }, { isNull: true }],
								},
							},
						},
						VideoYoutubeFragment(locale),
					],
				},
			],
		})
		return {
			...initial,
			currentCategory: getVideoYoutubeCategory,
			listCategory: listVideoYoutubeCategory,
		}
	})()

	const workshopPage = await (async () => {
		const initial = data.getLinkable?.workshopPage
		if (!initial) {
			return null
		}
		const { listWorkshop, listWorkshopCategory } = await content('query', { scalars })({
			listWorkshop: [
				{
					filter: {
						locales: {
							and: [
								{
									locale: {
										code: {
											eq: locale,
										},
									},
									isHidden: {
										eq: false,
									},
								},
							],
						},
					},
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				WorkshopTileFragment(locale),
			],
			listWorkshopCategory: [
				{
					orderBy: [
						{
							order: 'asc',
						},
					],
				},
				{
					id: true,
					localesByLocale: [
						{
							filter: {
								isHidden: {
									notEq: true,
								},
							},
							by: {
								locale: {
									code: locale,
								},
							},
						},
						WorkshopCategoryFragment(),
					],
				},
			],
		})
		return { ...initial, listWorkshop, listWorkshopCategory }
	})()

	const workshopCategory = await (async () => {
		const initial = data.getLinkable?.workshopCategory
		if (!initial) {
			return null
		}

		const subcategoryKey = 'subcategory'

		const subcategoryValue = getFilterValue(subcategoryKey, context)

		const { getWorkshopPage, listWorkshopCategory, getWorkshopCategory, listWorkshop } =
			await content('query', {
				scalars,
			})({
				getWorkshopPage: [
					{
						by: {
							unique: 'One',
						},
					},
					{
						localesByLocale: [
							{
								by: {
									locale: { code: locale },
								},
							},
							{
								link: [{}, { url: true }],
							},
						],
					},
				],
				getWorkshopCategory: [
					{
						by: {
							locales: {
								name: initial.name,
							},
						},
					},
					{
						id: true,
						workshops: [
							{
								filter: {
									locales: {
										and: [
											{
												isHidden: {
													eq: false,
												},
											},
											{
												locale: {
													code: {
														eq: locale,
													},
												},
											},
										],
									},
								},
								orderBy: [
									{
										order: 'asc',
									},
								],
							},
							WorkshopTileFragment(locale),
						],
						localesByLocale: [
							{
								by: {
									locale: {
										code: locale,
									},
								},
							},
							WorkshopCategoryDetailFragment(locale),
						],
					},
				],
				listWorkshopCategory: [
					{
						orderBy: [
							{
								order: 'asc',
							},
						],
					},
					{
						id: true,
						localesByLocale: [
							{
								filter: {
									isHidden: {
										eq: false,
									},
								},
								by: {
									locale: {
										code: locale,
									},
								},
							},
							WorkshopCategoryFragment(),
						],
					},
				],
				listWorkshop: [
					{
						filter: {
							subcategories: {
								slug: {
									eq: subcategoryValue,
								},
							},
						},
					},
					WorkshopTileFragment(locale),
				],
			})

		return {
			...initial,
			listWorkshopCategory,
			currentCategory: getWorkshopCategory,
			getWorkshopPage,
			listWorkshop,
			subcategoryKey,
			subcategoryValue,
		}
	})()

	const workshopDetail = await (async () => {
		const initial = data.getLinkable?.workshop
		if (!initial) {
			return null
		}

		return initial
	})()

	const translations = data.getTranslationDomain

	const page = {
		homePage,
		aboutTheatrePage,
		aboutTheatreDetail,
		actor,
		annualReportPage,
		annualReportDetail,
		contactPage,
		eshopPage,
		employee,
		gameStesticka,
		generalPage,
		newspaperPage,
		lector,
		peoplePage,
		programAndTicketPage,
		repertoirePage,
		repertoireDetail,
		videoPage,
		videoCategoryPage,
		workshopPage,
		workshopDetail,
		workshopCategory,
	}

	const currentPage = Object.values(page).find((page) => page !== null)

	if (!currentPage) {
		context.res.statusCode = 404
	}

	const {
		getHeader: header,
		getFooter: footer,
		getError404Page: error404page,
	} = await content('query', { scalars })({
		getHeader: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				logo: [{}, ImageFragment()],
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					{
						id: true,
						primaryNavigation: [{}, NavigationFragment()],
						secondaryNavigation: [{}, NavigationSecondaryFragment()],
						minorTvButton: [{}, MinorTvFragment()],
					},
				],
			},
		],

		getFooter: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				id: true,
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					{
						navigationTitle: true,
						navigation: [{}, NavigationSecondaryFragment()],
						socialsTitle: true,
						partnerTitleLink: [{}, LinkFragment()],
						socials: [
							{
								orderBy: [
									{
										order: 'asc',
									},
								],
							},
							FooterSocialFragment(),
						],
						partners: [{}, PartnerGroupFragment(locale)],
					},
				],
			},
		],

		getError404Page: [
			{
				by: {
					unique: 'One',
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					Error404PageLocaleFragment(),
				],
			},
		],
	})

	const isDeviceDotykovaObrazovka = (context.req?.headers.cookie ?? '').includes(
		'dotykova-obrazovka=true'
	)

	return {
		props: {
			formDataList: {
				contactFormData: data.getContactForm,
				newsletterFormData: data.getNewsletterForm,
				orderFormData: data.getOrderForm,
				reviewFormData: data.getReviewForm,
			},
			pageLocales: currentPage && 'root' in currentPage ? currentPage.root?.locales : null,
			header,
			footer,
			error404page,
			page,
			translations,
			isDeviceDotykovaObrazovka,
			seo: currentPage?.seo ?? data.getSeoDefault?.localesByLocale?.seo ?? {},
		},
	}
})
