import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ContactFormBox } from './ContactFormBox'
import style from './ContactPage.module.sass'
import { Container } from './Container'
import { ContentRenderer } from './Content'
import { PageTitle } from './PageTitle'

export type ContactPageProps = Exclude<
	Pick<CommonPageProps['page'], 'contactPage'>['contactPage'],
	null
>

export const ContactPage: FunctionComponent<ContactPageProps> = ({
	contactTitle,
	urlEmbedMap,
	content,
	contactContent,
}) => {
	return (
		<div className={style.wrapper}>
			{content?.blocks && <ContentRenderer content={content} />}
			<Container disableGutters="vertical">
				<div className={style.main}>
					<div className={style.columnMain}>
						{contactContent?.blocks && <ContentRenderer content={contactContent} />}
					</div>
					<div className={style.columnSide}>
						<div className={style.columnSideIn}>
							<div className={style.contact}>
								{contactTitle && (
									<div className={style.contactTitle}>
										<PageTitle title={contactTitle} padding="noVerticalPadding" align="center" />
									</div>
								)}
								<ContactFormBox type={{ name: 'contact' }} />
							</div>
							{urlEmbedMap && (
								<div className={style.contactMap}>
									<iframe
										className={style.contactMapIframe}
										title="embedded map"
										width={'100%'}
										height={'100%'}
										loading="lazy"
										allowFullScreen
										referrerPolicy="no-referrer-when-downgrade"
										src={urlEmbedMap}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
