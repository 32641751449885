import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { TicketPaymentInfoPriceGroupResult } from '../app/data/content/components/TicketPaymentInfoPriceGroupFragment'
import { Container } from './Container'
import { ProgramAndTicketPageFilterPrices } from './ProgramAndTicketPageFilterPrices'
import { ProgramAndTicketPageFilterShowTypes } from './ProgramAndTicketPageFilterShowTypes'
import { ProgramAndTicketPageFilterVenues } from './ProgramAndTicketPageFilterVenues'
import style from './ProgramAndTicketPagePriceGroups.module.sass'

export type ProgramAndTicketPagePriceGroupsProps = {
	priceGroup?: TicketPaymentInfoPriceGroupResult
}

export const ProgramAndTicketPagePriceGroups: FunctionComponent<
	ProgramAndTicketPagePriceGroupsProps
> = ({ priceGroup }) => {
	const [venueId, setVenueId] = useState(priceGroup?.venues[0]?.id || null)
	const [showTypeId, setShowTypeId] = useState(priceGroup?.venues[0]?.showTypes[0]?.id || null)

	return (
		<div className={style.wrapper}>
			<Container disableGutters="full">
				{priceGroup && priceGroup.venues.length > 0 && (
					<div className={style.filters}>
						<div className={style.filterVenues}>
							{priceGroup.venueLabel && (
								<h3 className={style.filterLabel}>{priceGroup.venueLabel}</h3>
							)}
							<div className={style.filterButtons}>
								<ProgramAndTicketPageFilterVenues
									venues={priceGroup.venues}
									venueId={venueId}
									setVenueId={setVenueId}
									setShowTypeId={setShowTypeId}
								/>
							</div>
						</div>
						<div className={style.filterShowType}>
							{priceGroup.showTypeLabel && (
								<h3 className={style.filterLabel}>{priceGroup.showTypeLabel}</h3>
							)}
							<div className={style.filterButtons}>
								<ProgramAndTicketPageFilterShowTypes
									venues={priceGroup.venues}
									venueId={venueId}
									showTypeId={showTypeId}
									setShowTypeId={setShowTypeId}
								/>
							</div>
						</div>
						<div className={style.prices}>
							<ProgramAndTicketPageFilterPrices
								venues={priceGroup.venues}
								showTypeId={showTypeId}
							/>
						</div>
					</div>
				)}
				{priceGroup?.text && (
					<div className={style.description}>
						<RichTextRenderer source={priceGroup?.text} />
					</div>
				)}
			</Container>
		</div>
	)
}
