import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { ContemberLink } from './ContemberLink'
import styles from './ImageBlock.module.sass'

export type ImageBlockProps = {
	image: ImageResult
	link?: LinkResult
}

export const ImageBlock: FunctionComponent<ImageBlockProps> = ({ image, link }) => {
	return (
		<div className={styles.wrapper}>
			{link ? (
				<ContemberLink className={styles.link} link={link}>
					<ImageContent image={image} link={link} />
				</ContemberLink>
			) : (
				<ImageContent image={image} />
			)}
		</div>
	)
}

const ImageContent: FunctionComponent<ImageBlockProps> = ({ image, link }) => {
	return (
		<div className={styles.wrapper}>
			<figure
				className={styles.figure}
				style={{
					'--ImageBlock-image-width': image.width,
					'--ImageBlock-image-height': image.height,
				}}>
				<div className={styles.image}>
					<Image
						className={styles.imageIn}
						src={image.url}
						fill
						alt={image.alt ?? ''}
						blurDataURL={image.url}
						placeholder="blur"
						sizes="(min-width: 992px) 1000px, 100vw"
					/>
				</div>
				{link?.title && (
					<figcaption className={styles.caption}>
						<h3>{link.title}</h3>
					</figcaption>
				)}
			</figure>
		</div>
	)
}
