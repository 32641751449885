import Image from 'next/image'
import type { FunctionComponent } from 'react'
import React from 'react'
import type { RepertoireEventLocaleResult } from '../app/data/content/components/RepertoireEventLocaleFragment'
import style from './ProgramAndTicketPageRibbon.module.sass'

export type ProgramAndTicketPageRibbonProps = {
	events: RepertoireEventLocaleResult[]
}

export const ProgramAndTicketPageRibbon: FunctionComponent<ProgramAndTicketPageRibbonProps> = ({
	events,
}) => {
	return (
		// extend with more ribbons?
		<div className={style.wrapper}>
			{events &&
				events.length > 0 &&
				events.map(
					(event) =>
						event.root?.categories &&
						event.root.categories.length > 0 &&
						event.root.categories.map((category, index) => (
							<React.Fragment key={`${category.id}-${index}`}>
								{category?.localesByLocale?.isGuest && (
									<Image
										src="/ribbon-program-item-guest.png"
										width={54}
										height={54}
										alt="Host"
										sizes="60px"
									/>
								)}
							</React.Fragment>
						))
				)}
		</div>
	)
}
