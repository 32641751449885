import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import type { useUrlQueryParam } from '../utils/useUrlQueryParam'
import style from './Button.module.sass'
import type { IconName } from './Icon'
import { Icon } from './Icon'

type LinkButtonProps = {
	type: 'link'
	link: LinkResult | string | LinkQueryParam | undefined
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

type ButtonButtonProps = {
	type: 'button'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type SubmitButtonProps = {
	type: 'submit'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type LinkQueryParam = {
	pathname: string
	query: ReturnType<typeof useUrlQueryParam>
}

export type ButtonProps = {
	children: React.ReactNode
	variant?: 'red' | 'grey'
	viewType?:
		| 'pageTileLinkItem'
		| 'tab'
		| 'tabSmall'
		| 'program'
		| 'programAndTicketPageEvent'
		| 'newsletterBox'
		| 'detailUpcomingEvent'
	size?: 'xs' | 'medium'
	isActive?: boolean
	isOutline?: boolean
	isShadowed?: boolean
	isBordered?: boolean
	isSideSpaced?: boolean
	isDisabled?: boolean
	borderRadiusUnset?: {
		all?: boolean
		top?: boolean
		right?: boolean
		bottom?: boolean
		left?: boolean
	}
	icon?: ButtonIcon
} & (LinkButtonProps | ButtonButtonProps | SubmitButtonProps)

type ButtonIcon = {
	name: IconName
	onHover?: {
		isLooped?: boolean
		direction?: 'up' | 'right' | 'down' | 'left'
	}
}

export const Button: React.FunctionComponent<ButtonProps> = ({
	variant,
	size,
	isActive = false,
	isOutline = false,
	isShadowed = false,
	isBordered = false,
	isSideSpaced = false,
	isDisabled = false,
	borderRadiusUnset: borderRadiusUnset,
	viewType,
	children,
	icon,
	...props
}) => {
	const link = 'link' in props ? props.link : '/'
	const href = link
		? typeof link === 'string'
			? link
			: 'type' in link
			? link.type === 'internal'
				? link.internalLink?.url ?? '/'
				: link.externalLink ?? '/'
			: link
		: '/'

	const isTargetBlank = link
		? typeof link !== 'string' && 'type' in link
			? link.isTargetBlank
			: false
		: false

	const className = clsx(
		style.wrapper,
		style[`view_${variant}`],
		style[`view_${viewType}`],
		style[`view_${size}`],
		isActive && style.is_active,
		isOutline && style.is_outline,
		isBordered && style.is_bordered,
		isSideSpaced && style.is_sidespace,
		isShadowed && style.is_shadowed,
		isDisabled && style.is_disabled,
		borderRadiusUnset?.all && style.is_borderRadiusUnsetAll,
		borderRadiusUnset?.top && style.is_borderRadiusUnsetTop,
		borderRadiusUnset?.right && style.is_borderRadiusUnsetRight,
		borderRadiusUnset?.bottom && style.is_borderRadiusUnsetBottom,
		borderRadiusUnset?.left && style.is_borderRadiusUnsetLeft
	)

	const buttonContent = (content: React.ReactNode, icon?: ButtonIcon) => {
		return (
			<>
				{content}
				{icon?.name && (
					<div
						className={clsx(
							style.icon,
							icon.onHover?.isLooped && style.isLooped,
							icon.onHover?.direction && style[`${icon.onHover.direction}`]
						)}>
						<Icon name={icon.name} />
					</div>
				)}
			</>
		)
	}

	return props.type === 'link' ? (
		<Link
			href={href}
			target={isTargetBlank ? '_blank' : undefined}
			rel={isTargetBlank ? 'noopener noreferrer' : undefined}
			className={className}
			onClick={props.onClick}>
			{/* eslint-disable-next-line */}
			{buttonContent(children, icon)}
		</Link>
	) : (
		<button type={props.type} className={className} onClick={props.onClick}>
			{buttonContent(children, icon)}
		</button>
	)
}
