import clsx from 'clsx'
import { type FunctionComponent } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import { ContentRenderer } from './Content'
import { PageTitle } from './PageTitle'
import style from './ProgramAndTicketPage.module.sass'
import { ProgramAndTicketPageFilter, TypeFilter } from './ProgramAndTicketPageFilter'
import { ProgramAndTicketPageMonthsFilter } from './ProgramAndTicketPageMonthsFilter'
import { ProgramAndTicketPageNewsletterBox } from './ProgramAndTicketPageNewsletterBox'
import { ProgramAndTicketPageProgram } from './ProgramAndTicketPageProgram'
import { ProgramAndTicketPageSideContent } from './ProgramAndTicketPageSideContent'

export type ProgramAndTicketPageProps = Exclude<
	Pick<CommonPageProps['page'], 'programAndTicketPage'>['programAndTicketPage'],
	null
>

export const ProgramAndTicketPage: FunctionComponent<ProgramAndTicketPageProps> = ({
	title,
	content,
	pages,
	info,
	groupedEvents,
	currentProgramAndTicketPageType,
	ageCategories,
	durationCategories,
	// schoolClassCategories,
	types,
	months,
}) => {
	const sortedPages = pages.sort((page) => (page.root?.type === 'public' ? -1 : 1))

	const translation = useTranslate()

	return (
		<div className={style.wrapper}>
			{content && <ContentRenderer content={content} />}
			<div className={style.main}>
				{currentProgramAndTicketPageType === 'public' && (
					<div className={style.infoBox}>
						<div className={style.titleBox}>
							{title && <PageTitle padding="noVerticalPadding" title={title} align="left" />}
							{(info?.sideContent || info?.ticketPaymentInfo) && (
								<div className={style.sideContent}>
									<ProgramAndTicketPageSideContent
										{...info.sideContent}
										ticketPaymentInfo={info.ticketPaymentInfo}
									/>
								</div>
							)}
						</div>
						{info && (
							<div className={style.newsletter}>
								<ProgramAndTicketPageNewsletterBox
									{...info.newsletter}
									ticketPaymentInfo={info.ticketPaymentInfo}
								/>
							</div>
						)}
					</div>
				)}
				{sortedPages && sortedPages.length > 0 && (
					<div className={style.switcher}>
						{sortedPages.map(
							(page) =>
								page?.link && (
									<div key={page.id} className={style.switcherButton}>
										<Button
											type="link"
											link={page.link.url}
											viewType="program"
											isActive={page.root?.type === currentProgramAndTicketPageType}
											isShadowed
											borderRadiusUnset={{ all: true }}>
											{page.pageButtonLabel}
										</Button>
									</div>
								)
						)}
					</div>
				)}

				<div className={style.filters}>
					<div className={style.filtersIn}>
						{/* // @TODO: fix filter by class */}
						{/* {currentProgramAndTicketPageType === 'schools' && schoolClassCategories && (
							<div className={style.filter}>
								<ProgramAndTicketPageFilter
									typeFilter={TypeFilter.select}
									title={translation('ProgramAndTicketPageFilter.class')}
									data={schoolClassCategories}
								/>
							</div>
						)} */}
						<div className={style.filter}>
							<div className={style.is_desktop}>
								<ProgramAndTicketPageFilter
									title={translation('ProgramAndTicketPageFilter.age')}
									data={ageCategories}
									hasFilterAddCharacter
								/>
							</div>
							<div className={style.is_mobile}>
								<ProgramAndTicketPageFilter
									typeFilter={TypeFilter.select}
									title={translation('ProgramAndTicketPageFilter.age')}
									data={ageCategories}
									hasFilterAddCharacter
								/>
							</div>
						</div>
						{types.items.length > 0 && (
							<div className={style.filter}>
								<div className={style.is_desktop}>
									<ProgramAndTicketPageFilter
										title={translation('ProgramAndTicketPageFilter.type')}
										data={types}
									/>
								</div>
								<div className={style.is_mobile}>
									<ProgramAndTicketPageFilter
										typeFilter={TypeFilter.select}
										title={translation('ProgramAndTicketPageFilter.type')}
										data={types}
									/>
								</div>
							</div>
						)}
						<div className={style.filter}>
							<div className={style.is_desktop}>
								<ProgramAndTicketPageFilter
									title={translation('ProgramAndTicketPageFilter.duration')}
									data={durationCategories}
									hasFilterAddCharacter
								/>
							</div>
							<div className={style.is_mobile}>
								<ProgramAndTicketPageFilter
									typeFilter={TypeFilter.select}
									title={translation('ProgramAndTicketPageFilter.duration')}
									data={durationCategories}
									hasFilterAddCharacter
								/>
							</div>
						</div>
						<div className={clsx(style.filter, style.view_months)}>
							<div className={style.is_mobile}>
								<ProgramAndTicketPageFilter
									typeFilter={TypeFilter.select}
									title={translation('ProgramAndTicketPageFilter.month')}
									data={months}
								/>
							</div>
							<div className={style.is_desktop}>
								<ProgramAndTicketPageMonthsFilter months={months} />
							</div>
						</div>
					</div>
				</div>

				<div className={style.program}>
					<ProgramAndTicketPageProgram groupedEvents={groupedEvents} {...info?.newsletter} />
				</div>
			</div>
		</div>
	)
}
