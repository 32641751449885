import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import style from './ProgramAndTicketPageEventDescription.module.sass'

export type ProgramAndTicketPageEventDescriptionProps = {
	description?: string
	image?: ImageResult
}

export const ProgramAndTicketPageEventDescription: FunctionComponent<
	ProgramAndTicketPageEventDescriptionProps
> = ({ description, image }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.main}>
				<div className={style.description}>
					{description && <RichTextRenderer source={description} />}
				</div>
				{image && (
					<div className={style.image}>
						<Image
							className={style.imageIn}
							src={image.url}
							width={image.width}
							height={image.height}
							alt={image.alt ?? ''}
							blurDataURL={image.url}
							placeholder="blur"
							sizes="(max-width: 1440px) 700px, 100vw"
						/>
					</div>
				)}
			</div>
		</div>
	)
}
