import Link from 'next/link'
import type { FunctionComponent } from 'react'
import styles from './DotykovaObrazovkaBar.module.sass'

export const DotykovaObrazovkaBar: FunctionComponent = () => {
	return (
		<div className={styles.wrapper}>
			<Link href="/repertoar" className={styles.link}>
				Repertoár
			</Link>
			<Link href="/dotykova-obrazovka" className={styles.link}>
				Rozcestník
			</Link>
		</div>
	)
}
