import clsx from 'clsx'
import Image from 'next/image'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import style from './PeoplePagePersonItemImage.module.sass'

export type PeoplePagePersonItemImageProps = {
	image?: ImageResult
	hoverOnImage?: boolean
}

export const PeoplePagePersonItemImage: React.FunctionComponent<PeoplePagePersonItemImageProps> = ({
	image,
	hoverOnImage,
}) => {
	return (
		<div className={clsx(style.wrapper, hoverOnImage && style.view_hover)}>
			{image && (
				<Image
					className={style.image}
					src={image.url}
					alt={image.alt ?? ''}
					sizes="150px"
					fill
					blurDataURL={image.url}
					placeholder="blur"
				/>
			)}
		</div>
	)
}
