import type { FunctionComponent } from 'react'
import style from './RepertoireFooterColumnTitle.module.sass'

export type RepertoireFooterColumnTitleProps = {
	title: string
}

export const RepertoireFooterColumnTitle: FunctionComponent<RepertoireFooterColumnTitleProps> = ({
	title,
}) => {
	return <h3 className={style.title}>{title}</h3>
}
