import clsx from 'clsx'
import React from 'react'
import type { NavigationSublinksResult } from '../app/data/content/components/NavigationSublinksFragment'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import style from './HeaderMainNavigationSublinks.module.sass'
import { ImageAspectRatio } from './ImageAspectRatio'

type HeaderMainNavigationSublinksProps = {
	listSublinks: NavigationSublinksResult[]
	isItemVisible: boolean
}

export const HeaderMainNavigationSublinks: React.FunctionComponent<
	HeaderMainNavigationSublinksProps
> = ({ listSublinks, isItemVisible = false }) => {
	return (
		<div className={style.wrapper}>
			<Container size="wide" height="full" disableGutters="full">
				<div className={style.main}>
					<div className={style.grid}>
						{listSublinks.map((item, i) => (
							<React.Fragment key={item.id}>
								{item && item.link && (
									<div
										className={clsx(style.item, isItemVisible && style.is_visible)}
										style={{
											'--HeaderMainNavigationSublinks-item-index': `${i}`,
										}}>
										<ContemberLink className={style.link} link={item.link}>
											<figure className={style.figure}>
												<div className={style.image}>
													<ImageAspectRatio
														sizes="300px"
														image={item.image}
														emptyImageLabel={item.link.title}
														isRounded
														isRatioSquared
													/>
												</div>
												{item.link.title && (
													<figcaption className={style.figureCaption}>{item.link.title}</figcaption>
												)}
											</figure>
										</ContemberLink>
									</div>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
			</Container>
		</div>
	)
}
