import React, { type ReactNode } from 'react'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import { Button } from './Button'
import style from './ListCategorySwitcher.module.sass'

export type ListCategorySwitcherProps = {
	categories: CategoryProps[]
	currentCategoryName?: string
}

type CategoryProps = {
	name: string
	link: InternalLinkResult
	isHidden?: boolean
	descriptionChildren?: ReactNode
}

export const ListCategorySwitcher: React.FunctionComponent<ListCategorySwitcherProps> = ({
	categories,
	currentCategoryName: currentCategory,
}) => {
	return (
		<div className={style.wrapper}>
			{categories.map((category, index) => (
				<React.Fragment key={index}>
					{category?.link && (
						<div className={style.button}>
							<Button
								type="link"
								link={category.link.url}
								viewType="tab"
								isActive={category.name === currentCategory}>
								{category.name}
							</Button>
							{category.descriptionChildren && category.descriptionChildren}
						</div>
					)}
				</React.Fragment>
			))}
		</div>
	)
}
