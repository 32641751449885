import React, { useContext } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'

type DotykovaObrazovkaBarProps = Pick<CommonPageProps, 'isDeviceDotykovaObrazovka'>

const defaultValue: DotykovaObrazovkaBarProps = {
	isDeviceDotykovaObrazovka: false,
}

export const DotykovaObrazovkaBarContext = React.createContext(defaultValue)
export const DotykovaObrazovkaBarContextProvider: React.FunctionComponent<
	DotykovaObrazovkaBarProps & {
		children?: React.ReactNode
	}
> = ({ children, isDeviceDotykovaObrazovka }) => {
	return (
		<DotykovaObrazovkaBarContext.Provider value={{ isDeviceDotykovaObrazovka }}>
			{children}
		</DotykovaObrazovkaBarContext.Provider>
	)
}

export const useIsDotykovaObrazovka = () => {
	const getIsDotykovaObrazovka = useContext(DotykovaObrazovkaBarContext)
	return getIsDotykovaObrazovka.isDeviceDotykovaObrazovka
}
