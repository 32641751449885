import { z } from 'zod'
import type { PaymentType } from '../../../generated/content'

export const paymentTypes = ['bankTransfer', 'cashOnDelivery'] satisfies [PaymentType, PaymentType]

export const OrderFormInputs = () => {
	return z.object({
		recaptcha: z.string().min(1),
		details: z.string().min(1),
		name: z.string().min(1),
		address: z.string().min(1),
		city: z.string().min(1),
		postalCode: z.string().min(1),
		email: z.string().min(1),
		phone: z.string().min(1),
		// @TODO: could be better with native enum :(
		payment_type: z.enum(paymentTypes),
	})
}

export type OrderFormInputsResult = z.infer<ReturnType<typeof OrderFormInputs>>
