import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './DetailHeroGalleryNavigation.module.sass'
import { Icon } from './Icon'

export type DetailHeroGalleryNavigationProps = {
	onPreviousClick: () => void
	onNextClick: () => void
	isPreviousButtonVisible: boolean
	isNextButtonVisible: boolean
}

export const DetailHeroGalleryNavigation: FunctionComponent<DetailHeroGalleryNavigationProps> = ({
	onPreviousClick,
	onNextClick,
	isPreviousButtonVisible,
	isNextButtonVisible,
}) => {
	return (
		<div className={styles.wrapper}>
			<button
				type="button"
				className={clsx(
					styles.button,
					styles.view_previous,
					isPreviousButtonVisible && styles.is_visible
				)}
				onClick={onPreviousClick}
				aria-label="previous">
				<Icon name="caret" />
			</button>
			<button
				type="button"
				className={clsx(styles.button, styles.view_next, isNextButtonVisible && styles.is_visible)}
				onClick={onNextClick}
				aria-label="next">
				<Icon name="caret" />
			</button>
		</div>
	)
}
