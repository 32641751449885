import type { EmployeeDepartmentResult } from '../app/data/content/components/EmployeeDepartmentFragment'
import style from './PeoplePagePersonEmployeeDepartmentList.module.sass'
import { PeoplePagePersonEmployeeList } from './PeoplePagePersonEmployeeList'

type PeoplePagePersonEmployeeDepartmentListProps = {
	departments: EmployeeDepartmentResult[]
}

export const PeoplePagePersonEmployeeDepartmentList: React.FunctionComponent<
	PeoplePagePersonEmployeeDepartmentListProps
> = ({ departments }) => {
	return (
		<div className={style.wrapper}>
			{departments.length > 0 && (
				<>
					{departments.map((department) => (
						<section className={style.department} key={department.id}>
							{department.localesByLocale?.name && (
								<h2 className={style.title}>{department.localesByLocale?.name}</h2>
							)}
							<PeoplePagePersonEmployeeList employees={department.employees} />
						</section>
					))}
				</>
			)}
		</div>
	)
}
