import type { FunctionComponent } from 'react'
import type { PartnerGroupListResult } from '../app/data/content/components/PartnerGroupListFragment'
import { PartnerGroupItem } from './PartnerGroupItem'
import style from './PartnerGroupList.module.sass'

export type PartnerGroupListProps = PartnerGroupListResult

export const PartnerGroupList: FunctionComponent<PartnerGroupListProps> = ({ groups }) => {
	return (
		<div className={style.wrapper}>
			{groups.map((group) => (
				<div key={group.id} className={style.group}>
					<PartnerGroupItem {...group} />
				</div>
			))}
		</div>
	)
}
