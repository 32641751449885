import type { PageTileLinkItemProps } from './PageTileLinkItem'
import { PageTileLinkItem } from './PageTileLinkItem'
import style from './PageTileLinkList.module.sass'

type PageTileLinkListProps = {
	items: PageTileLinkItemProps[]
}

export const PageTileLinkList: React.FunctionComponent<PageTileLinkListProps> = ({ items }) => {
	return items.length > 0 ? (
		<div className={style.wrapper}>
			{items.map((tile) => (
				<div key={tile.id} className={style.tile}>
					<PageTileLinkItem {...tile} />
				</div>
			))}
		</div>
	) : null
}
