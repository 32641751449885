import { useEffect, useState } from 'react'

export const IsDotykovaObrazovka = () => {
	const [isDotykovaObrazovka, setIsDotykovaObrazovka] = useState(false)

	useEffect(() => {
		const isDeviceDotykovaObrazovka = document.cookie.includes('dotykova-obrazovka=true')

		setIsDotykovaObrazovka(isDeviceDotykovaObrazovka)
	}, [])

	return isDotykovaObrazovka
}
