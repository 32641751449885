import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import type { TicketPaymentInfoVenueResult } from '../app/data/content/components/TicketPaymentInfoVenueFragment'
import { Button } from './Button'
import style from './ProgramAndTicketPageFilterVenues.module.sass'

export type ProgramAndTicketPageFilterVenuesProps = {
	venues: TicketPaymentInfoVenueResult[]
	venueId: string | null
	setVenueId: Dispatch<SetStateAction<string | null>>
	setShowTypeId: Dispatch<SetStateAction<string | null>>
}

export const ProgramAndTicketPageFilterVenues: FunctionComponent<
	ProgramAndTicketPageFilterVenuesProps
> = ({ venues, venueId, setVenueId, setShowTypeId }) => {
	return (
		<div className={style.wrapper}>
			{venues.map((venue) => (
				<div key={venue.id}>
					<Button
						type="button"
						viewType="tabSmall"
						onClick={() => {
							setVenueId(venue.id)
							setShowTypeId(venue.showTypes[0].id)
						}}
						isActive={venue.id === venueId}>
						{venue.label}
					</Button>
				</div>
			))}
		</div>
	)
}
