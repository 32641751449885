import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './PageTitle.module.sass'

export type PageTitleProps = {
	title: string
	padding?: 'noVerticalPadding'
	isLine?: boolean
	align?: 'center' | 'left' | 'right'
}

export const PageTitle: FunctionComponent<PageTitleProps> = ({ title, padding, isLine, align }) => {
	return (
		<>
			<h2
				className={clsx(
					style.title,
					padding && style[`view_${padding}`],
					isLine && style.is_line,
					align && style[`align_${align}`]
				)}>
				{title}
			</h2>
		</>
	)
}
