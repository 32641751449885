import type { FunctionComponent } from 'react'
import type { PersonDetailLocaleResult } from '../app/data/content/pages/PersonDetailLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './Content'
import { GalleryWithSmallThumbnails } from './GalleryWithSmallThumbnails'
import style from './PersonDetail.module.sass'
import { PersonDetailHeader } from './PersonDetailHeader'

export type PersonDetailProps = PersonDetailLocaleResult

export const PersonDetail: FunctionComponent<PersonDetailProps> = ({ root, content }) => {
	return (
		<Container>
			<div className={style.wrapper}>
				<div className={style.header}>
					{root?.lastName && (
						<PersonDetailHeader
							lastName={root.lastName}
							firstName={root.firstName}
							image={root.image}
						/>
					)}
				</div>
				{root?.gallery && (
					<div className={style.gallery}>
						<GalleryWithSmallThumbnails gallery={root.gallery} />
					</div>
				)}
				{content && (
					<div className={style.content}>
						<ContentRenderer content={content} />
					</div>
				)}
			</div>
		</Container>
	)
}
