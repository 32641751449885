import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React from 'react'
import styles from './BreakableContent.module.sass'

export type BreakableContentProps = {
	breakableFrom?: 'always' | '768' | '992'
	text: string
}

export const BreakableContent: FunctionComponent<BreakableContentProps> = ({
	text,
	breakableFrom = 'always',
}) => {
	const lines = text.split('\n')
	return (
		<div
			className={clsx(
				styles.wrapper,
				breakableFrom && styles[`is_breakableFrom_${breakableFrom}`]
			)}>
			{lines.map((line, i) => (
				<React.Fragment key={i}>
					{i != 0 && <br />}
					{line}
				</React.Fragment>
			))}
		</div>
	)
}
