import type { FunctionComponent } from 'react'
import type { TableWithLinksOrTextsResult } from '../app/data/content/components/TableWithLinksOrTextsFragment'
import style from './DetailTableSpecification.module.sass'
import { RepertoireFooterColumnTitle } from './RepertoireFooterColumnTitle'
import { TableWithLinks } from './TableWithLinks'

export type DetailTableSpecificationProps = {
	table?: TableWithLinksOrTextsResult
}

export const DetailTableSpecification: FunctionComponent<DetailTableSpecificationProps> = ({
	table,
}) => {
	return (
		<div className={style.wrapper}>
			{table?.name && <RepertoireFooterColumnTitle title={table.name} />}
			{table?.rows && <TableWithLinks rows={table.rows} />}
		</div>
	)
}
