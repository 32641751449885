import type { FunctionComponent, ReactNode } from 'react'
import Select from 'react-select'
import type { SingleValue } from '../node_modules/react-select/dist/declarations/src/types'
import { useTranslate } from '../utils/useTranslate'
import type { NewspaperPageProps } from './NewspaperPage'
import styles from './NewspaperRepertoireListSelect.module.sass'

export type NewspaperRepertoireListSelectProps = {
	list: NewspaperPageProps['listRepertoire']
	formatOptionLabel?: (option: SelectOptionProps) => ReactNode
	onChange?: (option: SingleValue<SelectOptionProps>) => void
	defaultValue?: SelectOptionProps
}

export type SelectOptionProps = {
	value: string
	label: string | undefined
}

export const NewspaperRepertoireListSelect: FunctionComponent<
	NewspaperRepertoireListSelectProps
> = ({ list, formatOptionLabel, onChange, defaultValue }) => {
	const translation = useTranslate()

	const options = list.map((item) => ({
		value: item.localesByLocale?.id ?? '',
		label: item.localesByLocale?.title,
	}))

	return (
		<div className={styles.wrapper}>
			<Select
				required
				instanceId="newspaperRepertoireListSelect"
				className={styles.select}
				options={options}
				placeholder={translation('NewspaperRepertoireListSelect.placeholder')}
				classNamePrefix="select"
				isClearable={true}
				isSearchable
				onChange={onChange}
				noOptionsMessage={() => translation('NewspaperRepertoireListSelect.noOptions')}
				defaultValue={defaultValue}
				formatOptionLabel={formatOptionLabel ?? undefined}
			/>
		</div>
	)
}
