import type { FunctionComponent } from 'react'
import { useTranslate } from '../utils/useTranslate'
import type { ProgramAndTicketPageProps } from './ProgramAndTicketPage'
import { ProgramAndTicketPageEventsGrouped } from './ProgramAndTicketPageEventsGrouped'
import { ProgramAndTicketPageNewsletterBox } from './ProgramAndTicketPageNewsletterBox'
import style from './ProgramAndTicketPageProgram.module.sass'

export type ProgramAndTicketPageProgramProps = {
	groupedEvents: NonNullable<ProgramAndTicketPageProps['groupedEvents']>
} & NonNullable<ProgramAndTicketPageProps['info']>['newsletter']

export const ProgramAndTicketPageProgram: FunctionComponent<ProgramAndTicketPageProgramProps> = ({
	groupedEvents,
	newsletter,
}) => {
	const translation = useTranslate()
	return (
		<div className={style.wrapper}>
			{groupedEvents.length > 0 ? (
				<ProgramAndTicketPageEventsGrouped groupedEvents={groupedEvents} />
			) : (
				<div className={style.emptyProgram}>
					<div className={style.content}>
						<div>
							{translation('ProgramAndTicketPageProgram.emptyProgram.content')
								.split('\n')
								.map((line, index) => (
									<div key={index}>{line}</div>
								))}
						</div>
					</div>
					<div className={style.newsletter}>
						<div className={style.newsletterInfo}>
							{translation('ProgramAndTicketPageProgram.emptyProgram.newsletterInfo')}
						</div>
						<div className={style.newsletterForm}>
							<ProgramAndTicketPageNewsletterBox type="inline" newsletter={newsletter} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
