import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { TicketPaymentInfoMethodResult } from '../app/data/content/components/TicketPaymentInfoMethodFragment'
import { useTranslate } from '../utils/useTranslate'
import style from './ProgramAndTicketPagePaymentMethod.module.sass'

export type ProgramAndTicketPagePaymentMethodProps = {
	order: number
} & TicketPaymentInfoMethodResult

export const ProgramAndTicketPagePaymentMethod: FunctionComponent<
	ProgramAndTicketPagePaymentMethodProps
> = ({ order, title, text }) => {
	const translation = useTranslate()
	return (
		<div className={style.wrapper}>
			<div className={style.order}>
				<div className={style.orderNumber}>{order}.</div>
				<div className={style.orderLabel}>
					{translation('ProgramAndTicketPagePaymentMethod.order.label')}
				</div>
			</div>
			<div className={style.description}>
				<h3 className={style.descriptionTitle}>{title}</h3>
				{text && (
					<div className={style.descriptionText}>
						<RichTextRenderer source={text} />
					</div>
				)}
			</div>
		</div>
	)
}
