export { default as arrowDown } from './arrowDown.svg'
export { default as arrowRight } from './arrowRight.svg'
export { default as arrowUp } from './arrowUp.svg'
export { default as calendar } from './calendar.svg'
export { default as cancel } from './cancel.svg'
export { default as caret } from './caret.svg'
export { default as go } from './go.svg'
export { default as mangoHeart } from './mangoHeart.svg'
export { default as play } from './play.svg'
export { default as theatre } from './theatre.svg'
