import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React from 'react'
import type { TicketPaymentInfoResult } from '../app/data/content/components/TicketPaymentInfoFragment'
import { ContentRenderer } from './Content'
import { ProgramAndTicketPagePaymentMethods } from './ProgramAndTicketPagePaymentMethods'
import { ProgramAndTicketPagePriceGroups } from './ProgramAndTicketPagePriceGroups'
import style from './ProgramAndTicketPagePriceInfo.module.sass'

export type ProgramAndTicketPagePriceInfoProps = TicketPaymentInfoResult

export const ProgramAndTicketPagePriceInfo: FunctionComponent<
	ProgramAndTicketPagePriceInfoProps
> = ({ title, methods, content, priceGroup }) => {
	const section = (content: React.ReactChild, isFullSize?: boolean) => (
		<section className={clsx(style.section, isFullSize && style.is_fullSize)}>
			<div className={style.sectionIn}>{content}</div>
		</section>
	)

	return (
		<div className={style.wrapper}>
			{title &&
				section(
					<h2 className={style.title}>
						<RichTextRenderer source={title} />
					</h2>
				)}
			{section(<ProgramAndTicketPagePaymentMethods paymentMethods={methods} />)}
			{content?.content &&
				section(
					<div className={style.contentAlignCenter}>
						<ContentRenderer content={content?.content} />
					</div>
				)}
			{section(<ProgramAndTicketPagePriceGroups priceGroup={priceGroup} />, true)}
		</div>
	)
}
