import Image from 'next/image'
import type { FunctionComponent, ReactNode } from 'react'
import { useMemo } from 'react'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import { parseDate } from '../utils/formatDate'
import type { GroupedEventsResult } from '../utils/getGroupedEvents'
import { useCurrentLocale } from '../utils/useCurrentLocale'
import { ContemberInternalLink } from './ContemberLink'
import { Icon } from './Icon'
import { ProgramAndTicketEventTitle } from './ProgramAndTicketEventTitle'
import type { EventTypeArray } from './ProgramAndTicketPageTileGroupedEvents'
import { EventType } from './ProgramAndTicketPageTileGroupedEvents'
import { ProgramAndTicketSchedule } from './ProgramAndTicketSchedule'
import styles from './UpcomingProgramItems.module.sass'

export type UpcomingProgramItemsProps = GroupedEventsResult[number]

export const UpcomingProgramItems: FunctionComponent<UpcomingProgramItemsProps> = ({
	day,
	groupedEventsByTitle,
}) => {
	return (
		<div className={styles.wrapper}>
			{groupedEventsByTitle.map((titles) => (
				<div key={titles.id}>
					<UpcomingProgramItem day={day} events={titles.events} />
				</div>
			))}
		</div>
	)
}

type UpcomingProgramItemProps = Pick<UpcomingProgramItemsProps, 'day'> & {
	events: UpcomingProgramItemsProps['groupedEventsByTitle'][number]['events']
}

const UpcomingProgramItem: FunctionComponent<UpcomingProgramItemProps> = ({ day, events }) => {
	const currentLocale = useCurrentLocale()
	const parsedDate = parseDate(currentLocale, day)

	// @TODO: duplicated code (ProgramAndTicketPageTileGroupedEvents.tsx)
	const { eventTypes, eventType } = useMemo(() => {
		const event = events && events.length > 0 && events[0]

		let eventTypes: EventTypeArray = []
		let eventType: EventType | undefined

		if (event && event.title) {
			if (event.title.repertoires.length > 0) {
				eventTypes = event.title.repertoires
				eventType = EventType.repertoire
			} else if (event.title.workshops.length > 0) {
				eventTypes = event.title.workshops
				eventType = EventType.workshop
			}
		}

		return { eventTypes, eventType }
	}, [events])

	const link = useMemo(() => {
		return eventTypes ? (eventTypes[0] && eventTypes[0].link) ?? undefined : undefined
	}, [eventTypes])

	const repertoarOrWorkshopCover = useMemo(() => {
		const defaultEvent = events[0].title
		if (!defaultEvent) {
			return
		}

		if (defaultEvent.repertoires.length > 0) {
			return defaultEvent.repertoires.flatMap((event) => event.root?.tileCover)[0]
		} else if (defaultEvent.workshops.length > 0) {
			return defaultEvent.workshops.map((event) => event.root?.tileImage)[0]
		}
	}, [events])

	return (
		<div className={styles.item}>
			<div className={styles.icon}>
				{eventType === EventType.repertoire ? <Icon name="theatre" /> : null}
			</div>
			<div className={styles.image}>
				<UpcomingProgramItemLink link={link}>
					{repertoarOrWorkshopCover && (
						<Image
							className={styles.imageIn}
							src={repertoarOrWorkshopCover.url}
							fill
							alt={repertoarOrWorkshopCover.alt ?? ''}
							blurDataURL={repertoarOrWorkshopCover.url}
							placeholder="blur"
							sizes="(min-width: 992px) 700px, 100vw"
						/>
					)}
				</UpcomingProgramItemLink>
			</div>
			<div className={styles.content}>
				<time className={styles.date} dateTime={day}>
					<div className={styles.dateDay}>{parsedDate.dayNumeric}</div>
					<div className={styles.dateMonth}>{parsedDate.month}</div>
				</time>
				<div className={styles.info}>
					<h3 className={styles.eventTitle}>
						<ProgramAndTicketEventTitle
							originalTitle={events[0].title?.nazev}
							overrideTitle={events[0].customTitle}
							events={eventTypes}
						/>
					</h3>
					<div className={styles.eventSchedule}>
						<ProgramAndTicketSchedule events={events} eventTypeArray={eventTypes} type="block" />
					</div>
				</div>
			</div>
		</div>
	)
}

type UpcomingProgramItemLinkProps = {
	link?: InternalLinkResult
	children: ReactNode
}

const UpcomingProgramItemLink: FunctionComponent<UpcomingProgramItemLinkProps> = ({
	link,
	children,
}) => {
	if (link) {
		return (
			<ContemberInternalLink className={styles.link} link={link}>
				{children}
			</ContemberInternalLink>
		)
	} else {
		return <div className={styles.link}>{children}</div>
	}
}
