import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useTranslate } from '../utils/useTranslate'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import style from './ProgramAndTicketPageFilter.module.sass'

export type ProgramAndTicketPageFilterProps = {
	typeFilter?: TypeFilter
	title?: string
	data: DataProps
	hasFilterAddCharacter?: boolean
}

export type Item = {
	id: string
	label?: number | string
	slug: string
}

export enum TypeFilter {
	default,
	select,
}

export type DataProps = {
	urlKey: string
	items: Item[]
}

export const ProgramAndTicketPageFilter: FunctionComponent<ProgramAndTicketPageFilterProps> = ({
	typeFilter = TypeFilter.default,
	title,
	data: { urlKey, items },
	hasFilterAddCharacter = false,
}) => {
	return (
		<div className={style.wrapper}>
			{title && <div className={style.title}>{title}</div>}
			{items &&
				items.length > 0 &&
				(typeFilter === TypeFilter.default ? (
					<FilterDefault
						items={items}
						urlKey={urlKey}
						hasFilterAddCharacter={hasFilterAddCharacter}
					/>
				) : (
					<FilterSelect
						items={items}
						urlKey={urlKey}
						hasFilterAddCharacter={hasFilterAddCharacter}
					/>
				))}
		</div>
	)
}

const FilterDefault: FunctionComponent<{
	urlKey: ProgramAndTicketPageFilterProps['data']['urlKey']
	items: ProgramAndTicketPageFilterProps['data']['items']
	hasFilterAddCharacter?: boolean
}> = ({ items, urlKey, hasFilterAddCharacter }) => {
	return (
		<ul className={style.list}>
			{items.map((item) => (
				<FilterItem
					urlKey={urlKey}
					item={item}
					key={item.id}
					hasFilterAddCharacter={hasFilterAddCharacter}
				/>
			))}
		</ul>
	)
}

const FilterSelect: FunctionComponent<{
	urlKey: ProgramAndTicketPageFilterProps['data']['urlKey']
	items: ProgramAndTicketPageFilterProps['data']['items']
	hasFilterAddCharacter?: boolean
}> = ({ items, urlKey, hasFilterAddCharacter }) => {
	const { query, pathname } = useRouter()
	const newQuery = useUrlQueryParam(query, urlKey, null)
	const [selectOpenCount, setSelectOpenCount] = useState(0)
	const selectedOption = query[urlKey] ? items.find((item) => item.slug === query[urlKey]) : null

	const translation = useTranslate()
	const localeSelect = translation('ProgramAndTicketPageFilter.select')
	return (
		<div className={clsx(style.wrapper, selectOpenCount % 2 === 1 && style.is_open)}>
			<button
				className={style.selectOverlay}
				onClick={() => setSelectOpenCount(selectOpenCount + 1)}></button>
			<button
				className={clsx(style.select)}
				onClick={() => {
					setSelectOpenCount(selectOpenCount + 1)
				}}>
				<div className={style.selectHeader}>
					{selectedOption?.label
						? `${selectedOption.label}${hasFilterAddCharacter ? '+' : ''}`
						: localeSelect}
				</div>
				<div className={style.options}>
					<ul className={style.optionsList}>
						<li className={style.option}>
							<Link href={{ pathname, query: newQuery }} className={style.link}>
								{localeSelect}
							</Link>
						</li>
						{items.map((item) => (
							<FilterItem
								urlKey={urlKey}
								item={item}
								key={item.id}
								hasFilterAddCharacter={hasFilterAddCharacter}
								isOption
							/>
						))}
					</ul>
				</div>
			</button>
		</div>
	)
}

const FilterItem: FunctionComponent<{
	urlKey: ProgramAndTicketPageFilterProps['data']['urlKey']
	item: ProgramAndTicketPageFilterProps['data']['items'][number]
	hasFilterAddCharacter?: boolean
	isOption?: boolean
}> = ({ urlKey, item: { id, label, slug }, hasFilterAddCharacter, isOption = false }) => {
	const { query, pathname } = useRouter()

	const filterValue = slug ?? id

	const newQuery = useUrlQueryParam(query, urlKey, filterValue)

	return (
		<li
			className={clsx(
				isOption ? style.option : style.item,
				filterValue === query[urlKey] && style.is_active
			)}
			key={id}>
			<Link
				href={{
					pathname,
					query: newQuery,
				}}
				className={style.link}>
				{filterValue === query[urlKey] ? (
					<strong>{label?.toString() ?? filterValue}</strong>
				) : (
					label?.toString() ?? filterValue
				)}
				{hasFilterAddCharacter && '+'}
			</Link>
		</li>
	)
}
