import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { useCallback, useState } from 'react'
import type { SingleValue } from 'react-select/dist/declarations/src/types'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import type { NewspaperPageProps } from './NewspaperPage'
import { NewspaperRepertoireListSelect } from './NewspaperRepertoireListSelect'
import styles from './NewspaperRepertoireListSelect.module.sass'

export type NewspaperRepertoireListSelectFilterProps = {
	list: NewspaperPageProps['publishedListRepertoire']
	repertoireFilterData: NewspaperPageProps['repertoireFilterData']
}

export type SelectOptionProps = {
	value: string
	label: string | undefined
}

export const NewspaperRepertoireListSelectFilter: FunctionComponent<
	NewspaperRepertoireListSelectFilterProps
> = ({ list, repertoireFilterData }) => {
	const { query, pathname, push } = useRouter()
	const emptyFilterQuery = useUrlQueryParam(query, repertoireFilterData.urlKey, null)

	const [_, selectRepertoireId] = useState<string | undefined>(undefined)

	const onChange = useCallback(
		(option: SingleValue<SelectOptionProps>) => {
			if (option?.value) {
				selectRepertoireId(option.value)
			} else {
				push({ pathname, query: emptyFilterQuery })
				selectRepertoireId(undefined)
			}
		},
		[emptyFilterQuery, pathname, push]
	)

	return (
		<NewspaperRepertoireListSelect
			list={list}
			formatOptionLabel={(option) => (
				<FormatOptionLabel option={option} repertoireFilterData={repertoireFilterData} />
			)}
			onChange={onChange}
		/>
	)
}

const FormatOptionLabel: FunctionComponent<{
	option: SelectOptionProps
	repertoireFilterData: NewspaperPageProps['repertoireFilterData']
}> = ({ option, repertoireFilterData }) => {
	return (
		<FilterItem
			urlKey={repertoireFilterData.urlKey}
			item={{
				id: option.value,
				slug: option.value,
				label: option.label,
			}}
		/>
	)
}

const FilterItem: FunctionComponent<{
	urlKey: NonNullable<NewspaperRepertoireListSelectFilterProps['repertoireFilterData']>['urlKey']
	item: NonNullable<
		NewspaperRepertoireListSelectFilterProps['repertoireFilterData']
	>['items'][number]
}> = ({ urlKey, item: { id, label, slug } }) => {
	const { query, pathname } = useRouter()

	const filterValue = slug ?? id

	const newQuery = useUrlQueryParam(query, urlKey, filterValue)
	return (
		<Link
			href={{
				pathname,
				query: newQuery,
			}}
			className={styles.link}>
			{label}
		</Link>
	)
}
