import type { LightboxSource } from '@mangoweb/react-fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import { useMemo } from 'react'
import type { MediumGalleryResult } from '../app/data/content/components/MediumGalleryFragment'

export const useMediumGallery = (media?: MediumGalleryResult) => {
	const mediumGallery = useMemo(() => {
		const sources: LightboxSource[] = []

		if (media?.items && media.items.length > 0) {
			media.items.map((item) => {
				if (item.medium) {
					if (item.medium.type === 'Image' && item.medium.image) {
						return sources.push({
							source: item.medium.image.url,
							type: 'image',
							caption: item.medium.image.alt,
						})
					} else if (item.medium.type === 'YoutubeVideo' && item.medium.youtubeVideo) {
						return sources.push({
							source: item.medium.youtubeVideo.videoId
								? `https://www.youtube.com/watch?v=${item.medium.youtubeVideo.videoId}`
								: '',
							type: 'youtube',
						})
					}
				}
			})
		}

		return sources
	}, [media?.items])

	const lightbox = useLightbox(mediumGallery)

	return lightbox
}
