import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { TableWithLinksOrTextsContentsResult } from '../app/data/content/components/TableWithLinksOrTextsContentsFragment'
import { ContemberLink } from './ContemberLink'
import style from './TableWithLinksContent.module.sass'

export type TableWithLinksContentProps = TableWithLinksOrTextsContentsResult

export const TableWithLinksContent: FunctionComponent<TableWithLinksContentProps> = (props) => {
	switch (props.type) {
		case 'link':
			return props.link ? (
				<ContemberLink className={style.link} link={props.link}>
					{props.link.title}
				</ContemberLink>
			) : null

		case 'text':
			return props.text ? <RichTextRenderer source={props.text} /> : null
	}

	return null
}
