import dynamic from 'next/dynamic'
import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import style from './GameStestickaPage.module.sass'
import { Wysiwyg } from './Wysiwyg'

const DynamicStesticka = dynamic<unknown>(
	() => import('./Stesticka').then((mod) => mod.Stesticka),
	{
		ssr: false,
	}
)

export type GameStestickaPageProps = Exclude<
	Pick<CommonPageProps['page'], 'gameStesticka'>['gameStesticka'],
	null
>

export const GameStestickaPage: FunctionComponent<GameStestickaPageProps> = ({ title, text }) => {
	return (
		<div className={style.wrapper}>
			<Container disableGutters="vertical">
				<div className={style.main}>
					<div className={style.content}>
						{title && <h1 className={style.title}>{title}</h1>}
						{text && (
							<div className={style.text}>
								<Wysiwyg source={text} />
							</div>
						)}
					</div>
					<DynamicStesticka />
				</div>
			</Container>
		</div>
	)
}
