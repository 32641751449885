// source: https://dev.to/skona27/the-easiest-way-to-internationalize-your-nextjs-application-llj
import { useRouter } from 'next/router'

// keep it same as i18n.locales
export type Locales = 'cs' | 'en'

// keep it same as i18n.defaultLocales
export const defaultLocale: Locales = 'cs'

export const useCurrentLocale = (): Locales => {
	const { locale } = useRouter()

	return (locale as Locales) || defaultLocale
}
