import type { AnnualReportDetailFragmentResult } from '../app/data/content/pages/AnnualReportDetailFragment'
import style from '../components/AnnualReportDetail.module.sass'
import { AnnualReportTile } from './AnnualReportTile'
import { Container } from './Container'

export type AnnualReportDetailProps = AnnualReportDetailFragmentResult

export const AnnualReportDetail: React.FunctionComponent<AnnualReportDetailProps> = ({
	id,
	name,
	file,
}) => {
	return (
		<Container disableGutters="vertical">
			<div className={style.wrapper}>
				<h2>{name}</h2>
				<AnnualReportTile id={id} name={name} file={file} />
			</div>
		</Container>
	)
}
