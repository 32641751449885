import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useInViewRef } from 'rooks'
import type { RepertoireEventLocaleResult } from '../app/data/content/components/RepertoireEventLocaleFragment'
import type { WorkshopEventLocaleResult } from '../app/data/content/components/WorkshopEventLocaleFragment'
import { parseDate } from '../utils/formatDate'
import { useCurrentLocale } from '../utils/useCurrentLocale'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import { Collapsible } from './Collapsible'
import { ProgramAndTicketEventTitle } from './ProgramAndTicketEventTitle'
import { ProgramAndTicketPageEventDescription } from './ProgramAndTicketPageEventDescription'
import { ProgramAndTicketPageEventTags } from './ProgramAndTicketPageEventTags'
import type { ProgramAndTicketPageEventsGroupedByTitleProps } from './ProgramAndTicketPageEventsGroupedByTitle'
import { ProgramAndTicketPageRibbon } from './ProgramAndTicketPageRibbon'
import style from './ProgramAndTicketPageTileGroupedEvents.module.sass'
import { ProgramAndTicketSchedule } from './ProgramAndTicketSchedule'

export type ProgramAndTicketPageTileGroupedEventsProps = {
	events: ProgramAndTicketPageEventsGroupedByTitleProps['groupedEventsByTitle'][number]['events']
}

export type EventTypeArray = RepertoireEventLocaleResult[] | WorkshopEventLocaleResult[]

export enum EventType {
	repertoire,
	workshop,
}

export const ProgramAndTicketPageTileGroupedEvents: FunctionComponent<
	ProgramAndTicketPageTileGroupedEventsProps
> = ({ events }) => {
	const currentLocale = useCurrentLocale()

	const defaultEvent = events[0] // default event to get general data (date, title)
	const date = defaultEvent.datumOdModified
		? parseDate(currentLocale, defaultEvent.datumOdModified)
		: parseDate(currentLocale, defaultEvent.datumOd) // orignal date - in safari can't be parsed - it returns "invalid date"
	const [elRef, inView] = useInViewRef({ rootMargin: '100px 0px' })

	// @TODO: duplicated code (UpcomingProgramItems.tsx)
	// different locales of repertoire/workshop might be related to different eventTitle (So there might be related more than 1 repertoire/workshop to one eventTitle)
	let eventTypeArray: EventTypeArray = []
	let eventType: EventType | undefined
	if (defaultEvent.title) {
		if (defaultEvent.title.repertoires.length > 0) {
			eventTypeArray = defaultEvent.title.repertoires
			eventType = EventType.repertoire
		} else if (defaultEvent.title.workshops.length > 0) {
			eventTypeArray = defaultEvent.title.workshops
			eventType = EventType.workshop
		}
	}

	const [isDescriptionOpen, setDescriptionOpen] = useState(false)

	const translation = useTranslate()
	return (
		<div className={clsx(style.wrapper, inView && style.isInView)} ref={elRef}>
			<div className={style.meta}>
				<ProgramAndTicketPageRibbon events={eventTypeArray} />
				<time className={style.date} dateTime={defaultEvent.datumOd}>
					<div className={style.dateShort}>{date.dayNumeric}</div>
					<div className={style.dateLong}>{`${date.month}, ${date.weekday}`}</div>
				</time>
			</div>
			<div className={style.main}>
				<div className={style.info}>
					<h3 className={style.title}>
						<span className={style.titleIn}>
							<ProgramAndTicketEventTitle
								originalTitle={defaultEvent.title?.nazev}
								overrideTitle={defaultEvent.customTitle}
								events={eventTypeArray}
							/>
						</span>
						{/* tags */}
						{eventTypeArray && eventTypeArray.length > 0 && (
							<ProgramAndTicketPageEventTags
								eventType={eventType}
								eventTypeArray={eventTypeArray}
							/>
						)}
					</h3>
					<div className={style.schedule}>
						<ProgramAndTicketSchedule events={events} eventTypeArray={eventTypeArray} />
					</div>
					{events.length <= 2 &&
						eventTypeArray.length > 0 &&
						eventType === EventType.repertoire && (
							<div className={style.description}>
								<Collapsible open={isDescriptionOpen}>
									{eventTypeArray.map((eventType) => {
										const cover =
											eventType.root && 'cover' in eventType.root
												? eventType.root.cover?.items[0].medium?.image || undefined
												: undefined
										const description = 'text' in eventType ? eventType.text : undefined
										return (
											<div className={style.descriptionIn} key={eventType.id}>
												<ProgramAndTicketPageEventDescription
													image={cover}
													description={description}
												/>
											</div>
										)
									})}
								</Collapsible>
								{isDescriptionOpen ? (
									<Button
										type="button"
										variant="grey"
										size="xs"
										icon={{
											name: 'arrowUp',
											onHover: { direction: 'up', isLooped: true },
										}}
										onClick={() => setDescriptionOpen(!isDescriptionOpen)}>
										{translation('ProgramAndTicketPageTileGroupedEvents.hideInfo')}
									</Button>
								) : (
									<Button
										type="button"
										variant="red"
										size="xs"
										icon={{
											name: 'arrowDown',
											onHover: { direction: 'down', isLooped: true },
										}}
										onClick={() => setDescriptionOpen(!isDescriptionOpen)}>
										{translation('ProgramAndTicketPageTileGroupedEvents.showInfo')}
									</Button>
								)}
							</div>
						)}
				</div>
			</div>
		</div>
	)
}
