import type { ParsedUrlQuery } from 'querystring'
import { useMemo } from 'react'

export function useUrlQueryParam(
	query: ParsedUrlQuery,
	key: string,
	value: string | undefined | null
) {
	return useMemo(
		() =>
			Object.fromEntries(
				Object.entries({
					...query,
					[key]: value === query[key] ? null : value,
				})
					.filter(([_, value]) => value !== null)
					.sort(([a], [b]) => (a > b ? 1 : -1))
			),
		[query, value, key]
	)
}
