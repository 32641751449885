import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import React, { useCallback } from 'react'
import type { PersonTileResult } from '../app/data/content/components/PersonTileFragment'
import { Collapsible } from './Collapsible'
import { Container } from './Container'
import { PeoplePagePersonProfile } from './PeoplePagePersonProfile'
import style from './PeoplePagePersonTile.module.sass'
import { PeoplePagePersonTileContent } from './PeoplePagePersonTileContent'

export type PeoplePagePersonTileProps = PersonTileResult & {
	openPersonId: string | null
	setOpenPersonId: React.Dispatch<React.SetStateAction<string | null>>
}

export const PeoplePagePersonTile: FunctionComponent<PeoplePagePersonTileProps> = ({
	id,
	slug,
	firstName,
	lastName,
	gallery,
	image,
	openPersonId,
	setOpenPersonId,
	localesByLocale,
}) => {
	const { basePath, query, push } = useRouter()

	const onClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
		(event) => {
			event.preventDefault()
			setOpenPersonId(openPersonId === id ? null : id)
			const { currentTarget } = event
			const elementId = currentTarget.id
			currentTarget.id = ''

			push(
				{
					pathname: basePath,
					query: query,
					hash: slug ?? elementId,
				},
				undefined,
				{
					shallow: true,
					scroll: false,
				}
			).catch((error) => {
				// workaround for https://github.com/vercel/next.js/issues/37362#issuecomment-1283671326
				if (!error.cancelled) {
					throw error
				}
			})
			setTimeout(() => {
				currentTarget.scrollIntoView({
					behavior: 'smooth',
				})
			}, 300 /* from Collapsible component */)
			requestAnimationFrame(() => {
				currentTarget.id = id
			})
		},
		[basePath, id, openPersonId, push, query, setOpenPersonId, slug]
	)

	return (
		<div className={style.wrapper}>
			<Link
				href={{
					hash: slug,
				}}
				id={slug}
				className={clsx(style.person, openPersonId && openPersonId !== id && style.view_fade)}
				onClick={onClick}>
				<PeoplePagePersonProfile firstName={firstName} lastName={lastName} image={image} />
			</Link>
			<div className={style.content}>
				<Collapsible open={openPersonId === id}>
					<Container>
						<div className={style.contentIn}>
							<PeoplePagePersonTileContent
								firstName={firstName}
								lastName={lastName}
								setOpenPersonId={setOpenPersonId}
								content={localesByLocale?.content}
								gallery={gallery}
							/>
						</div>
					</Container>
				</Collapsible>
			</div>
		</div>
	)
}
