import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { MediumGalleryImagesResult } from '../app/data/content/components/MediumGalleryImagesFragment'
import { type GalleryType } from '../generated/content'
import { usePicturesGallery } from '../utils/usePicturesGallery'
import style from './GridGallery.module.sass'

export type GridGalleryProps = {
	gallery: MediumGalleryImagesResult
	type?: GalleryType
	visibleImages?: number
}

export const GridGallery: FunctionComponent<GridGalleryProps> = ({
	gallery,
	type = 'fourTiles',
	visibleImages = undefined,
}) => {
	const lightbox = usePicturesGallery(gallery)
	return (
		<div className={clsx(style.wrapper, style[`view_${type}`])}>
			{gallery?.items && gallery.items.length > 0 && (
				<div className={style.images}>
					{gallery.items.slice(0, type === 'fourTiles' ? 4 : visibleImages).map(
						(item, index) =>
							item.medium &&
							item.medium.image?.url && (
								<button
									key={item.medium.id}
									aria-label={item.medium.image.alt ?? 'image'}
									type="button"
									className={style.button}
									onClick={() => lightbox?.open(index)}
									style={{
										'--GridGallery-image-width': item.medium.image.width,
										'--GridGallery-image-height': item.medium.image.height,
									}}>
									<Image
										src={item.medium.image.url}
										alt={item.medium.image.alt ?? ''}
										fill
										blurDataURL={item.medium.image.url}
										placeholder="blur"
										// 975px == --content-width-normal
										sizes={type === 'fourTiles' ? '400px' : '975px'}
									/>
								</button>
							)
					)}
				</div>
			)}
		</div>
	)
}
