import React, { useState } from 'react'
import type { ProductCategoryResult } from '../app/data/content/components/ProductCategoryFragment'
import type { CommonPageProps } from '../pages/[[...page]]'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { Container } from './Container'
import { EshopFilter } from './EshopFilter'
import { EshopList } from './EshopList'
import { EshopOrderForm } from './EshopOrderForm'
import style from './EshopPage.module.sass'
import { PageContent } from './PageContent'
import { useOrderFormData } from './contexts/FormDataContextProvider'

export type EshopPageProps = Exclude<Pick<CommonPageProps['page'], 'eshopPage'>['eshopPage'], null>

export const EshopPage: React.FunctionComponent<EshopPageProps> = ({
	title,
	content,
	listProduct,
	listProductCategory,
}) => {
	const [currentCategory, setCurrentCategory] = useState<ProductCategoryResult | null>(null)

	const products = currentCategory
		? listProduct.filter((item) =>
				item.localesByLocale?.root?.categories.find(
					(category) => category.localesByLocale?.id === currentCategory.localesByLocale?.id
				)
		  )
		: listProduct

	const formDataTranslation = useOrderFormData()

	return (
		<div className={style.wrapper}>
			<div className={style.content}>
				<PageContent title={title} content={content} />
			</div>
			<Container size="wide">
				{listProductCategory.length > 0 && (
					<div className={style.filter}>
						<EshopFilter
							currentCategory={currentCategory}
							setCurrentCategory={setCurrentCategory}
							categories={listProductCategory.filter(filterNonEmpty)}
						/>
					</div>
				)}
				{listProduct.length > 0 && (
					<div className={style.products}>
						<EshopList
							products={products.map((products) => products.localesByLocale).filter(filterNonEmpty)}
						/>
					</div>
				)}
				<div className={style.orderForm}>
					<EshopOrderForm
						title={formDataTranslation?.localesByLocale?.title}
						text={formDataTranslation?.localesByLocale?.text}
					/>
				</div>
			</Container>
		</div>
	)
}
