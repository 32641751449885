import style from '../components/AboutTheatreDetail.module.sass'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ContentRenderer } from './Content'

export type AboutTheatreDetailProps = Exclude<
	Pick<CommonPageProps['page'], 'aboutTheatreDetail'>['aboutTheatreDetail'],
	null
>

export const AboutTheatreDetail: React.FunctionComponent<AboutTheatreDetailProps> = ({
	content,
}) => {
	return (
		<div className={style.wrapper}>
			{content && <ContentRenderer content={content}></ContentRenderer>}
		</div>
	)
}
