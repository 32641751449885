import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import { Collapsible } from './Collapsible'
import { ProgramAndTicketPageEventDescription } from './ProgramAndTicketPageEventDescription'
import type {
	EventTypeArray,
	ProgramAndTicketPageTileGroupedEventsProps,
} from './ProgramAndTicketPageTileGroupedEvents'
import style from './ProgramAndTicketSchedule.module.sass'
import type { ProgramAndTicketScheduleItemProps } from './ProgramAndTicketScheduleItem'
import { ProgramAndTicketScheduleItem } from './ProgramAndTicketScheduleItem'

export type ProgramAndTicketScheduleProps = {
	events: ProgramAndTicketPageTileGroupedEventsProps['events']
	eventTypeArray: EventTypeArray
	type?: ProgramAndTicketScheduleItemProps['type']
}

export const ProgramAndTicketSchedule: FunctionComponent<ProgramAndTicketScheduleProps> = ({
	events,
	eventTypeArray,
	type = 'page',
}) => {
	const [isScheduleOpen, setScheduleOpen] = useState(false)

	const translation = useTranslate()
	return (
		<div className={style.wrapper}>
			<div className={style.list}>
				{events.slice(0, 2).map((titleEvent, index) => (
					<div
						key={titleEvent.id}
						className={clsx(style.item, index === 1 && style.view_hideBottomSpacing)}>
						<ProgramAndTicketScheduleItem
							type={type}
							event={titleEvent}
							eventTypeArray={eventTypeArray}
						/>
					</div>
				))}
				{events.length > 2 && (
					<>
						<Collapsible open={isScheduleOpen}>
							{events.slice(2).map((titleEvent) => (
								<div key={titleEvent.id} className={clsx(style.item, style.view_collapsible)}>
									<ProgramAndTicketScheduleItem
										type={type}
										event={titleEvent}
										eventTypeArray={eventTypeArray}
									/>
								</div>
							))}
							{type === 'page' &&
								eventTypeArray.map((eventType) => {
									const cover =
										eventType.root && 'cover' in eventType.root
											? eventType.root.cover?.items[0].medium?.image || undefined
											: undefined
									const description = 'text' in eventType ? eventType.text : undefined

									return (
										<div className={style.description} key={eventType.id}>
											<ProgramAndTicketPageEventDescription
												image={cover}
												description={description}
											/>
										</div>
									)
								})}
						</Collapsible>
						<div className={style.buttonCollapse}>
							{isScheduleOpen ? (
								<Button
									type="button"
									onClick={() => setScheduleOpen(!isScheduleOpen)}
									variant="grey"
									size="xs"
									icon={{
										name: 'arrowUp',
										onHover: { direction: 'up', isLooped: true },
									}}>
									{translation('ProgramAndTicketPageTileSchedule.hide')}
								</Button>
							) : (
								<Button
									type="button"
									onClick={() => setScheduleOpen(!isScheduleOpen)}
									variant="red"
									size="xs"
									icon={{
										name: 'arrowDown',
										onHover: { direction: 'down', isLooped: true },
									}}>
									{translation('ProgramAndTicketPageTileSchedule.show')}
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
