import type { FunctionComponent } from 'react'
import type { PartnerItemResult } from '../app/data/content/components/PartnerItemFragment'
import { LogoItem } from './LogoItem'
import { PartnerImage } from './PartnerImage'
import style from './PartnerItem.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type PartnerItemProps = PartnerItemResult

export const PartnerItem: FunctionComponent<PartnerItemProps> = ({ partner }) => {
	const name = partner?.localesByLocale?.name
	const text = partner?.localesByLocale?.text
	const image = partner?.localesByLocale?.image
	const link = partner?.localesByLocale?.link
	const otherImages = partner?.localesByLocale?.otherImages

	return (
		<div className={style.wrapper}>
			<div className={style.main}>
				<div className={style.content}>
					{name && <h4 className={style.name}>{name}</h4>}
					{text && (
						<div className={style.text}>
							<Wysiwyg source={text} />
						</div>
					)}
				</div>
				{image && (
					<div className={style.image}>
						<PartnerImage image={image} link={link} />
					</div>
				)}
			</div>

			{otherImages?.items && (
				<div className={style.otherImages}>
					{otherImages.items.map(
						(item) =>
							item.medium?.type === 'Image' &&
							item.medium.image && (
								<LogoItem key={item.medium.id} image={item.medium.image} size={140} />
							)
					)}
				</div>
			)}
		</div>
	)
}
