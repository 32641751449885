import type { AnnualReportDetailFragmentResult } from '../app/data/content/pages/AnnualReportDetailFragment'
import styles from './AnnualReportTile.module.sass'
import { FileOrLink } from './FileOrLink'

export type AnnualReportTileProps = AnnualReportDetailFragmentResult

export const AnnualReportTile: React.FunctionComponent<AnnualReportTileProps> = ({
	name,
	file,
}) => {
	if (file) {
		return <div className={styles.wrapper}>{file && <FileOrLink file={file} text={name} />}</div>
	}
	return <></>
}
