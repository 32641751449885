import React, { useContext } from 'react'
import { v4 } from 'uuid'
import type { PageLocaleLocalesDataResult } from '../../app/data/content/components/PageLocalesFragment'

type SwitcherLocalesProps = {
	pageLocales?: PageLocaleLocalesDataResult[]
	children: React.ReactNode
}

const defaultValues: PageLocaleLocalesDataResult[] = [
	{
		id: v4(),
		locale: {
			code: 'cs',
			label: 'Čeština',
		},
		link: {
			url: '#',
		},
	},
]

export const SwitcherLocalesContext = React.createContext<
	PageLocaleLocalesDataResult[] | undefined
>(defaultValues)

export const SwitcherLocalesContextProvider: React.FunctionComponent<SwitcherLocalesProps> = ({
	pageLocales,
	children,
}) => {
	return (
		<SwitcherLocalesContext.Provider value={pageLocales}>
			{children}
		</SwitcherLocalesContext.Provider>
	)
}

export const useLocales = () => {
	const getLocales = useContext(SwitcherLocalesContext)
	return getLocales
}
