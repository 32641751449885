import type { LightboxSource } from '@mangoweb/react-fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import type { TileVideoResult } from '../app/data/content/components/TileVideoFragment'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import style from './GridTileVideo.module.sass'

export type GridTileVideoProps = TileVideoResult & {
	buttonFontSize?: 'largeFontSize'
}

export const GridTileVideo: FunctionComponent<GridTileVideoProps> = ({
	type,
	customYoutubeVideo,
	uploadedYoutubeVideo,
	buttonFontSize,
}) => {
	const video = useMemo(() => {
		let sources: LightboxSource[] = []

		if (type === 'customYoutube' && customYoutubeVideo?.videoId) {
			sources = [
				{
					source: `https://www.youtube.com/watch?v=${customYoutubeVideo.videoId}`,
					type: 'youtube',
				},
			]
		} else if (type === 'uploadedYoutube' && uploadedYoutubeVideo?.youtubeVideo?.videoId) {
			sources = [
				{
					source: `https://www.youtube.com/watch?v=${uploadedYoutubeVideo.youtubeVideo.videoId}`,
					type: 'youtube',
					caption: uploadedYoutubeVideo.localesByLocale?.title,
				},
			]
		}

		return sources
	}, [
		customYoutubeVideo?.videoId,
		type,
		uploadedYoutubeVideo?.localesByLocale?.title,
		uploadedYoutubeVideo?.youtubeVideo?.videoId,
	])

	const lightbox = useLightbox(video)

	const translation = useTranslate()
	return (
		<div className={clsx(style.wrapper, buttonFontSize && style[`view_${buttonFontSize}`])}>
			<Button type="button" variant="red" onClick={() => lightbox?.open(0)}>
				{translation('GridTileVideo.video.button')}
			</Button>
		</div>
	)
}
