import React, { useCallback } from 'react'
import type { WorkshopSubcategoryResult } from '../app/data/content/components/WorkshopSubcategoryFragment'
import type { WorkshopTileResult } from '../app/data/content/components/WorkshopTileFragment'
import { Tags, type TagsProps } from './Tags'
import type { WorkshopPageProps } from './WorkshopPage'
import styles from './WorkshopSubcategoryDivider.module.sass'
import { WorkshopTileLinkList } from './WorkshopTileLinkList'

export type WorkshopSubcategoryDividerProps = {
	subcategories: SubcategoryProps[]
	currentSubcategory: string
	listWorkshop: ListWorkshopProps[]
}

type SubcategoryProps = WorkshopSubcategoryResult
type ListWorkshopProps = WorkshopTileResult

export const WorkshopSubcategoryDivider: React.FunctionComponent<
	WorkshopSubcategoryDividerProps
> = ({ subcategories, currentSubcategory, listWorkshop }) => {
	const activeSubcategoryBySlug = subcategories.find(
		(subcategory) => subcategory.slug === currentSubcategory
	)

	const subcategoryDividers = activeSubcategoryBySlug?.subcategoryDividers

	const tagList = useCallback((workshops: WorkshopPageProps['listWorkshop'][number]) => {
		const tags: TagsProps['items'] = []
		// ageFrom tag
		if (workshops?.ageFrom?.label) {
			tags.push({
				text: workshops.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		// all other tags
		workshops.tags?.items.map(
			(item) =>
				item.tag?.localesByLocale?.title &&
				tags.push({
					text: item.tag.localesByLocale.title,
				})
		)

		return tags
	}, [])

	return (
		<>
			{subcategoryDividers && subcategoryDividers.length > 0 ? (
				subcategoryDividers.map((subcategoryDivider) => {
					const dividedWorkshops = listWorkshop.filter((workshop) =>
						workshop.subcategoryDividers.find(
							(subcategory) => subcategory.id === subcategoryDivider.id
						)
					)

					return (
						<div key={subcategoryDivider.id} className={styles.dividerWrapper}>
							<h3 className={styles.title}>{subcategoryDivider.localesByLocale?.name}</h3>
							<p className={styles.description}>
								{subcategoryDivider.localesByLocale?.description}
							</p>
							<div className={styles.workshopList}>
								<WorkshopTileLinkList
									items={dividedWorkshops.map((item) => ({
										id: item.id,
										image: item.tileImage,
										link: item.localesByLocale?.link,
										title: item.localesByLocale?.title,
										lead: item.localesByLocale?.lead,
										tags: item.ageFrom?.label && <Tags items={tagList(item)} isAlignCenter />,
									}))}
								/>
							</div>
						</div>
					)
				})
			) : (
				<WorkshopTileLinkList
					items={listWorkshop.map((item) => ({
						id: item.id,
						image: item.tileImage,
						link: item.localesByLocale?.link,
						title: item.localesByLocale?.title,
						lead: item.localesByLocale?.lead,
						tags: item.ageFrom?.label && <Tags items={tagList(item)} isAlignCenter />,
					}))}
				/>
			)}
		</>
	)
}
