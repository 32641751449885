import type { FunctionComponent } from 'react'
import style from './LoadingDots.module.sass'

type LoadingDotsProps = {
	size?: string
	spacing?: string
	duration?: string
}

export const LoadingDots: FunctionComponent<LoadingDotsProps> = ({
	size = '10px',
	spacing = '2px',
	duration = '1.5s',
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.main}>
				<div
					className={style.dot}
					style={{
						'--LoadingDots-size': size,
						'--LoadingDots-spacing': spacing,
						'--LoadingDots-animationDuration': duration,
					}}
				/>
			</div>
		</div>
	)
}
