import type { FunctionComponent } from 'react'
import React from 'react'
import { useTranslate } from '../utils/useTranslate'
import type { DetailUpcomingEventProps } from './DetailUpcomingEvent'
import { DetailUpcomingEvent } from './DetailUpcomingEvent'
import style from './DetailUpcomingProgram.module.sass'

export type DetailUpcomingProgramProps = {
	events?: DetailUpcomingEventProps[]
	type?: string
}

export const DetailUpcomingProgram: FunctionComponent<DetailUpcomingProgramProps> = ({
	events,
	type = 'repertoire' || 'workshop',
}) => {
	const translation = useTranslate()
	return (
		<div className={style.wrapper}>
			<h3 className={style.title}>
				{type === 'repertoire' && translation('RepertoireDetailUpcomingProgram.title')}
				{type === 'workshop' && translation('WorkshopDetailUpcomingProgram.title')}
			</h3>
			<div className={style.list}>
				{events && events.length > 0 ? (
					events.map((event) => (
						<React.Fragment key={event.id}>
							{!event.isHiddenEvent && !event.skoly && (
								<div className={style.item}>
									<DetailUpcomingEvent {...event} ageFrom={event.ageFrom?.toString()} />
								</div>
							)}
						</React.Fragment>
					))
				) : (
					<p className={style.emptyProgram}>
						<em>
							{type === 'repertoire' && translation('RepertoireDetailUpcomingProgram.emptyProgram')}
							{type === 'workshop' && translation('WorkshopDetailUpcomingProgram.emptyProgram')}
						</em>
					</p>
				)}
			</div>
		</div>
	)
}
