import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import Link from 'next/link'
import React from 'react'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import { Icon } from './Icon'
import style from './WorkshopFilterButton.module.sass'

type LinkButtonProps = {
	type: 'link'
	link: LinkResult | string | LinkQueryParam | undefined
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

type SlugButtonProps = {
	type: 'slug'
	urlKey: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

type ButtonButtonProps = {
	type: 'button'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type SubmitButtonProps = {
	type: 'submit'
	onClick?: React.MouseEventHandler<HTMLButtonElement>
}

type LinkQueryParam = {
	pathname: string
	query: ReturnType<typeof useUrlQueryParam>
}

export type ButtonProps = {
	title: string
	children?: React.ReactNode
	returnLink?: string
	slug?: string
	isActive?: boolean
	size?: string
} & (LinkButtonProps | ButtonButtonProps | SubmitButtonProps | SlugButtonProps)

export const WorkshopFilterButton: React.FunctionComponent<ButtonProps> = ({
	title,
	returnLink,
	slug,
	isActive = false,
	children,
	size,
	...props
}) => {
	const link = 'link' in props ? props.link : '/'
	const href = link
		? typeof link === 'string'
			? link
			: 'type' in link
			? link.type === 'internal'
				? link.internalLink?.url ?? '/'
				: link.externalLink ?? '/'
			: link
		: '/'

	const urlKey = 'urlKey' in props ? props.urlKey : ''

	const isTargetBlank = link
		? typeof link !== 'string' && 'type' in link
			? link.isTargetBlank
			: false
		: false

	const className = clsx(
		style.wrapper,
		isActive && style.is_active,
		size === 'small' && style.small,
		props.type === 'slug' && style.slug
	)

	const { query, pathname } = useRouter()

	const newQuery = useUrlQueryParam(query, urlKey, slug)

	return props.type === 'link' ? (
		<div className={style.linkWrapper}>
			{isActive && returnLink ? (
				<div className={style.cancelField}>
					<Link href={returnLink} className={clsx(style.radioButton, isActive && style.active)}>
						<div className={style.icon}>
							<Icon name="cancel" />
						</div>
					</Link>
				</div>
			) : (
				<div className={style.cancelField}>
					<div className={style.radioButton} />
				</div>
			)}
			<Link
				href={href}
				target={isTargetBlank ? '_blank' : undefined}
				rel={isTargetBlank ? 'noopener noreferrer' : undefined}
				className={className}
				onClick={props.onClick}>
				<div className={style.content}>
					<div className={style.titleBox}>
						<span className={style.title}>{title}</span>
					</div>
					<div className={style.children}>{children}</div>
				</div>
			</Link>
		</div>
	) : props.type === 'slug' ? (
		<Link
			href={{ pathname, query: newQuery }}
			target={isTargetBlank ? '_blank' : undefined}
			rel={isTargetBlank ? 'noopener noreferrer' : undefined}
			className={className}
			onClick={props.onClick}>
			<div className={style.content}>
				<div className={style.titleBox}>
					<span className={style.title}>{title}</span>
					{isActive && (
						<div className={clsx(style.radioButton)}>
							<div className={style.icon}>
								<Icon name="cancel" />
							</div>
						</div>
					)}
				</div>
				<div className={style.children}>{children}</div>
			</div>
		</Link>
	) : (
		<button type={props.type} className={className} onClick={props.onClick}>
			<div className={style.content}>
				<div className={style.titleBox}>
					<span className={style.title}>{title}</span>
					{isActive ? (
						<Link
							href={{
								pathname,
								query: newQuery,
							}}
							className={style.radioButton}>
							<div className={style.icon}>
								<Icon name="cancel" />
							</div>
						</Link>
					) : (
						<div className={style.radioButton}>
							<div className={style.icon}>
								<Icon name="cancel" />
							</div>
						</div>
					)}
				</div>
				<div>{children}</div>
			</div>
		</button>
	)
}
