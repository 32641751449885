import clsx from 'clsx'
import { ToastContainer } from 'react-toastify'
import { SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import type { PageLocaleLocalesDataResult } from '../app/data/content/components/PageLocalesFragment'
import type { TranslationDomainWithCatalougesByIdentifierFragmentResult } from '../app/data/content/components/TranslationDomainWithCatalougesByIdentifierFragment'
import type { CommonPageProps } from '../pages/[[...page]]'
import { DotykovaObrazovkaBar } from './DotykovaObrazovkaBar'
import { Footer } from './Footer'
import { Header } from './Header'
import style from './Layout.module.sass'
import { PageNavigationLoadingTracker } from './PageNavigationLoadingTracker'
import { SharedLoadingIndicatorContextProvider } from './SharedLoadingIndicatorContextProvider'
import { DotykovaObrazovkaBarContextProvider } from './contexts/DotykovaObrazovkaBarContextProvider'
import { FormDataContextProvider } from './contexts/FormDataContextProvider'
import { IsDotykovaObrazovka } from './contexts/IsDotykovaObrazovka'
import { SwitcherLocalesContextProvider } from './contexts/LocalesContextProvider'
import { RecaptchaContextProvider } from './contexts/RecaptchaContextProvider'
import { TranslationsContextProvider } from './contexts/TranslationsContextProvider'

export type LayoutProps = Pick<
	CommonPageProps,
	'header' | 'footer' | 'isDeviceDotykovaObrazovka'
> & {
	translations?: TranslationDomainWithCatalougesByIdentifierFragmentResult
	pageName?: 'stesticka' | 'programAndTicket' | 'newspaperPage' | 'workshopPage'
	pageLocales?: PageLocaleLocalesDataResult[]
	formDataList?: CommonPageProps['formDataList']
	children: React.ReactNode
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
	pageLocales,
	header,
	footer,
	translations,
	pageName,
	formDataList,
	children,
}) => {
	const isDeviceDotykovaObrazovka = IsDotykovaObrazovka()

	return (
		<div
			className={clsx(
				style.wrapper,
				style[`view_${pageName}`],
				isDeviceDotykovaObrazovka && style.is_dotykovaObrazovka
			)}>
			<SharedLoadingIndicatorContextProvider>
				<SwitcherLocalesContextProvider pageLocales={pageLocales}>
					<RecaptchaContextProvider>
						<SharedProgressLoadingIndicator />
						<PageNavigationLoadingTracker />
						<TranslationsContextProvider data={translations}>
							<FormDataContextProvider formDataList={formDataList}>
								<DotykovaObrazovkaBarContextProvider
									isDeviceDotykovaObrazovka={isDeviceDotykovaObrazovka}>
									{pageName !== 'stesticka' && !isDeviceDotykovaObrazovka && (
										<Header header={header} pageName={pageName} />
									)}

									<main className={style.main}>{children}</main>

									{pageName !== 'stesticka' && !isDeviceDotykovaObrazovka && (
										<Footer footer={footer} />
									)}

									{isDeviceDotykovaObrazovka && <DotykovaObrazovkaBar />}
									{/* ToastContainer (flash messages) need to be on the top of (non-active) menu close button - menu button has 45 */}
									{pageName !== 'stesticka' && (
										<ToastContainer
											style={{ zIndex: '46' }}
											position="top-right"
											autoClose={5000}
											hideProgressBar={false}
											newestOnTop={false}
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable
											pauseOnHover
											theme="colored"
										/>
									)}
								</DotykovaObrazovkaBarContextProvider>
							</FormDataContextProvider>
						</TranslationsContextProvider>
					</RecaptchaContextProvider>
				</SwitcherLocalesContextProvider>
			</SharedLoadingIndicatorContextProvider>
		</div>
	)
}
