import React from 'react'
import style from '../components/AnnualReportList.module.sass'
import type { CommonPageProps } from '../pages/[[...page]]'
import { AnnualReportTile } from './AnnualReportTile'

export type AnnualReportListProps = Exclude<
	Pick<CommonPageProps['page'], 'annualReportPage'>['annualReportPage'],
	null
>

export const AnnualReportList: React.FunctionComponent<AnnualReportListProps> = ({
	listAnnualReport,
}) => {
	return (
		<div className={style.wrapper}>
			{listAnnualReport.map((item, index) => (
				<React.Fragment key={index}>
					{item?.localesByLocale && (
						<div className={style.item} key={item.localesByLocale.id}>
							<AnnualReportTile
								id={item.localesByLocale.id}
								name={item.localesByLocale.name}
								file={item.localesByLocale.file}
							/>
						</div>
					)}
				</React.Fragment>
			))}
		</div>
	)
}
