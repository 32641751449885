import clsx from 'clsx'
import 'plyr-react/plyr.css'
import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import { ContentRenderer } from './Content'
import style from './RepertoireDetail.module.sass'
import { RepertoireDetailHero } from './RepertoireDetailHero'
import { RepertoireReviewList } from './RepertoireReviewList'
import { Wysiwyg } from './Wysiwyg'
import { IsDotykovaObrazovka } from './contexts/IsDotykovaObrazovka'

export type RepertoireDetailProps = Exclude<
	Pick<CommonPageProps['page'], 'repertoireDetail'>['repertoireDetail'],
	null
>

export const RepertoireDetail: FunctionComponent<RepertoireDetailProps> = ({
	title,
	audio,
	root,
	text,
	content,
	tableSpecification,
	eventTitle,
	mediaReviews,
	newspaperPage,
	slug,
}) => {
	const isDotykovaObrazovka = IsDotykovaObrazovka()

	const translation = useTranslate()
	const newspaperPageQuery = useUrlQueryParam(
		{
			page: undefined,
		},
		newspaperPage.urlKey,
		slug
	)

	return (
		<div className={clsx(style.wrapper, isDotykovaObrazovka && style.is_dotykovaObrazovka)}>
			<div className={style.hero}>
				<RepertoireDetailHero
					title={title}
					text={text}
					audio={audio}
					tableSpecification={tableSpecification}
					eventTitle={eventTitle}
					root={root}
				/>
			</div>
			<Container size="normal" disableGutters="vertical">
				<div className={style.content}>
					{/* @TODO: temporary - after galleries will be deleted then revert code */}
					{content && <ContentRenderer isRepertoireDetail content={content} />}
				</div>
			</Container>
			<div className={style.reviews}>
				<Container size="wide" disableGutters="vertical">
					<div className={style.reviewsIn}>
						{mediaReviews.length > 0 && (
							<div className={style.column}>
								<RepertoireReviewList
									title={translation('RepertoireDetail.review.mediaTitle')}
									list={mediaReviews.map((review) => ({
										id: review.id,
										children: (
											<>
												<Wysiwyg source={review.message} />
												{review.link && (
													<div className={style.reviewFooter}>
														<ContemberLink className={style.reviewLink} link={review.link}>
															{review.link.title}
														</ContemberLink>
													</div>
												)}
											</>
										),
									}))}
								/>
							</div>
						)}
						{root?.filledReviews && root?.filledReviews.length > 0 && (
							<div className={style.column}>
								<RepertoireReviewList
									title={translation('RepertoireDetail.review.publicTitle')}
									list={root.filledReviews.map((review) => ({
										id: review.id,
										children: (
											<>
												<em>{review.message}</em>
												<div className={style.reviewFooter}>
													<strong>{review.name}</strong>
												</div>
											</>
										),
									}))}
								/>
							</div>
						)}
					</div>
					<div className={style.reviewActions}>
						<Button
							type="link"
							link={{
								pathname: newspaperPage.link?.url ?? '',
								query: newspaperPageQuery,
							}}
							isBordered>
							{translation('RepertoireDetail.review.button')}
						</Button>
					</div>
				</Container>
			</div>
		</div>
	)
}
