import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { parseDate } from '../utils/formatDate'
import { useCurrentLocale } from '../utils/useCurrentLocale'
import { ProgramAndTicketPageEventReservationButton } from './ProgramAndTicketPageEventReservationButton'
import { ProgramAndTicketPageEventSubInfo } from './ProgramAndTicketPageEventSubInfo'
import type {
	EventTypeArray,
	ProgramAndTicketPageTileGroupedEventsProps,
} from './ProgramAndTicketPageTileGroupedEvents'
import style from './ProgramAndTicketScheduleItem.module.sass'

export type ProgramAndTicketScheduleItemProps = {
	event: ProgramAndTicketPageTileGroupedEventsProps['events'][number]
	eventTypeArray: EventTypeArray
	type?: 'page' | 'block'
}

export const ProgramAndTicketScheduleItem: FunctionComponent<ProgramAndTicketScheduleItemProps> = ({
	event,
	eventTypeArray,
	type = 'block',
}) => {
	const currentLocale = useCurrentLocale()
	const time = event.datumOdModified
		? parseDate(currentLocale, event.datumOdModified.substring(0, 16)).time // substract timezone from datumOdModified
		: parseDate(currentLocale, event.datumOd).time
	return (
		<div className={clsx(style.wrapper, style[`is_${type}`])}>
			<div className={style.subInfo}>
				{(event.datumOd || event.venue?.exnazev) && (
					<ProgramAndTicketPageEventSubInfo
						eventTypeArray={eventTypeArray}
						time={time}
						venue={event.venue?.exnazev ?? ''}
					/>
				)}
			</div>
			<div className={style.actions}>
				<ProgramAndTicketPageEventReservationButton
					webTicketId={event.webTicketId}
					capacity={{ remains: event.volno, total: event.celkem }}
					overrideButton={event.customButtonLink}
					isTypeSchool={event.skoly}
				/>
			</div>
		</div>
	)
}
