import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { MediumGalleryResult } from '../app/data/content/components/MediumGalleryFragment'
import { useMediumGallery } from '../utils/useMediaGallery'
import styles from './DetailHeroGallery.module.sass'
import { DetailHeroGalleryNavigation } from './DetailHeroGalleryNavigation'
import { Icon } from './Icon'

export type DetailHeroGalleryProps = MediumGalleryResult

export const DetailHeroGallery: FunctionComponent<DetailHeroGalleryProps> = ({ id, items }) => {
	const lightbox = useMediumGallery({ id: id, items: items })
	const timerRef = useRef<ReturnType<typeof setInterval> | null>(null)

	const carouselElements = useRef<HTMLDivElement>(null)

	// @TODO: create common component for the same code
	const scroll = useCallback(
		(direction: 1 | -1) => {
			if (!carouselElements.current) {
				return
			}

			const isCarouselAtEnd =
				carouselElements.current.scrollLeft ===
				(carouselElements.current.scrollWidth ?? 0) - (carouselElements.current.clientWidth ?? 0)
			const scrollSlideOffset = direction * (carouselElements.current.scrollWidth / items.length)

			if (isCarouselAtEnd) {
				carouselElements.current.scrollTo({
					left: 0,
					behavior: 'smooth',
				})
			} else {
				carouselElements.current.scrollBy({
					left: scrollSlideOffset,
					behavior: 'smooth',
				})
			}
		},
		[items.length]
	)

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	// @TODO: create common component for the same code
	const onSlideChange = () => {
		if (carouselElements.current === null) {
			return
		}

		const isCarouselAtEnd =
			carouselElements.current.scrollLeft ===
			(carouselElements.current.scrollWidth ?? 0) - (carouselElements.current.clientWidth ?? 0)

		setShowPreviousButton(true)
		setShowNextButton(true)
		if (carouselElements.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (isCarouselAtEnd) {
			setShowNextButton(false)
		}
	}

	const clearTimer = useCallback(() => {
		if (timerRef.current === null) {
			return
		}
		clearInterval(timerRef.current)
	}, [])
	useEffect(() => {
		const interval = () => {
			timerRef.current = setInterval(() => {
				scroll(1)
			}, 4000)
		}
		interval()

		return () => {
			clearTimer()
		}
	}, [clearTimer, scroll])

	useEffect(() => {
		if (carouselElements.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [clearTimer, scroll])

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div className={styles.wrapper} onMouseEnter={clearTimer}>
			{items.length > 1 && (
				<div className={styles.navigation}>
					<DetailHeroGalleryNavigation
						onPreviousClick={() => scroll(-1)}
						onNextClick={() => scroll(1)}
						isPreviousButtonVisible={showPreviousButton}
						isNextButtonVisible={showNextButton}
					/>
				</div>
			)}
			<div className={styles.carousel} onScroll={() => onSlideChange()} ref={carouselElements}>
				{items.map(
					(item, count) =>
						item.medium && (
							<div key={item.medium.id} className={styles.slide}>
								<button
									type="button"
									onClick={() => lightbox?.open(count)}
									className={styles.medium}>
									{item.medium.type === 'Image' && item.medium.image ? (
										<Image
											src={item.medium.image.url}
											alt={item.medium.image.alt ?? item.medium.image.fileName ?? ''}
											fill
											sizes="(min-width: 992px) 650px, (min-width: 1200px) 850px, (min-width: 1400px) 900px, 100vw"
										/>
									) : // @TODO: hide video on TV devices
									item.medium.type === 'YoutubeVideo' && item.medium.youtubeVideo ? (
										<>
											<div className={styles.playButton}>
												<Icon name="play" />
											</div>
											<Image
												src={`https://img.youtube.com/vi/${item.medium.youtubeVideo.videoId}/maxresdefault.jpg`}
												blurDataURL={`https://img.youtube.com/vi/${item.medium.youtubeVideo.videoId}/maxresdefault.jpg`}
												placeholder="blur"
												alt="video"
												fill
												sizes="(min-width: 992px) 650px, (min-width: 1200px) 850px, (min-width: 1400px) 900px, 100vw"
											/>
										</>
									) : null}
								</button>
							</div>
						)
				)}
			</div>
		</div>
	)
}
