import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { ProgramAndTicketPageInfoSideContentResult } from '../app/data/content/components/ProgramAndTicketPageInfoSideContentFragment'
import type { TicketPaymentInfoResult } from '../app/data/content/components/TicketPaymentInfoFragment'
import style from './ProgramAndTicketPageSideContent.module.sass'

export type ProgramAndTicketPageSideContentProps = ProgramAndTicketPageInfoSideContentResult & {
	ticketPaymentInfo?: TicketPaymentInfoResult
}

export const ProgramAndTicketPageSideContent: FunctionComponent<
	ProgramAndTicketPageSideContentProps
> = ({ primaryText, secondaryText }) => {
	return (
		<div className={style.wrapper}>
			{primaryText && (
				<div className={style.primaryText}>
					<RichTextRenderer source={primaryText} />
				</div>
			)}
			{secondaryText && (
				<div className={style.secondaryText}>
					<RichTextRenderer source={secondaryText} />
				</div>
			)}
		</div>
	)
}
