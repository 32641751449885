import React, { useContext } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'

interface FormDataContextProps {
	formDataList?: CommonPageProps['formDataList']
	children: React.ReactNode
}

const defaultValue: FormDataContextProps['formDataList'] = {
	contactFormData: {},
	newsletterFormData: {},
	orderFormData: {},
	reviewFormData: {},
}

export const FormDataContext = React.createContext(defaultValue)
export const FormDataContextProvider: React.FunctionComponent<FormDataContextProps> = ({
	formDataList,
	children,
}) => {
	return (
		<FormDataContext.Provider
			value={{
				contactFormData: formDataList?.contactFormData,
				newsletterFormData: formDataList?.newsletterFormData,
				orderFormData: formDataList?.orderFormData,
				reviewFormData: formDataList?.reviewFormData,
			}}>
			{children}
		</FormDataContext.Provider>
	)
}

export const useFormData = () => {
	const getFormData = useContext(FormDataContext)
	return getFormData
}

export const useContactFormData = () => {
	const getFormData = useFormData()

	return getFormData.contactFormData
}

export const useNewsletterFormData = () => {
	const getFormData = useFormData()

	return getFormData.newsletterFormData
}

export const useOrderFormData = () => {
	const getFormData = useFormData()

	return getFormData.orderFormData
}

export const useReviewFormData = () => {
	const getFormData = useFormData()

	return getFormData.reviewFormData
}
