import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { TicketPaymentInfoVenueResult } from '../app/data/content/components/TicketPaymentInfoVenueFragment'
import style from './ProgramAndTicketPageFilterPrices.module.sass'

export type ProgramAndTicketPageFilterPricesProps = {
	venues: TicketPaymentInfoVenueResult[]
	showTypeId: string | null
}

export const ProgramAndTicketPageFilterPrices: FunctionComponent<
	ProgramAndTicketPageFilterPricesProps
> = ({ venues, showTypeId }) => {
	return (
		<div className={style.wrapper}>
			{venues.map((venue) => (
				<div className={clsx(style.sectionVenue && style.is_inView)} key={venue.id}>
					{venue.showTypes.length > 0 &&
						venue.showTypes.map((showType) => (
							<div
								className={clsx(
									style.sectionShowType,
									showType.id === showTypeId && style.is_inView
								)}
								key={showType.id}>
								{showType.type === 'price'
									? showType.prices.length > 0 &&
									  showType.prices.map((price) => (
											<dl className={style.priceBox} key={price.id}>
												<dt className={style.priceLabel}>{price.label}</dt>
												<dl className={style.priceNumber}>{price.price}</dl>
											</dl>
									  ))
									: showType.type === 'text'
									? showType.text && (
											<div className={style.priceBox}>
												<RichTextRenderer source={showType.text} />
											</div>
									  )
									: null}
							</div>
						))}
				</div>
			))}
		</div>
	)
}
