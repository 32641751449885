import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import type { PartnerLocaleResult } from '../app/data/content/components/PartnerLocaleFragment'
import { ContemberLink } from './ContemberLink'
import { ImageAspectRatio } from './ImageAspectRatio'
import style from './PartnerImage.module.sass'

export type PartnerImageProps = {
	image?: ImageResult
	link?: PartnerLocaleResult['link']
}

export const PartnerImage: FunctionComponent<PartnerImageProps> = ({ image, link }) => {
	const Image = (image: ImageResult) => (
		<div className={style.image}>
			<ImageAspectRatio image={image} layout="intrinsic" hover="verticalOffset" />
		</div>
	)
	return (
		<div className={style.wrapper}>
			{image &&
				(link ? (
					<ContemberLink className={style.link} link={link}>
						{Image(image)}
					</ContemberLink>
				) : (
					Image(image)
				))}
		</div>
	)
}
