import { useRouter } from 'next/dist/client/router'
import type { FunctionComponent } from 'react'
import { Fragment } from 'react'
import { useTranslate } from '../utils/useTranslate'
import style from './ProgramAndTicketPageEventSubInfo.module.sass'
import type { EventTypeArray } from './ProgramAndTicketPageTileGroupedEvents'

export type ProgramAndTicketPageEventSubInfoProps = {
	eventTypeArray?: EventTypeArray
	time: string
	venue: string
}

export const ProgramAndTicketPageEventSubInfo: FunctionComponent<
	ProgramAndTicketPageEventSubInfoProps
> = ({ eventTypeArray, time, venue }) => {
	const { query } = useRouter()
	const isExtraInfoSlug = 'extraDetails' in query
	const translation = useTranslate()

	return (
		<div className={style.wrapper}>
			{isExtraInfoSlug && eventTypeArray && eventTypeArray.length > 0 && (
				<>
					{eventTypeArray.map((event, index) => (
						<Fragment key={`${event.id}-age-${index}`}>
							{(event.root?.ageFrom?.label || event.root?.ageUntil?.label) && (
								<>
									<div className={style.age}>
										{`${translation('ProgramAndTicketPageEventSubInfo.age')} ${
											event.root.ageFrom?.label ?? '*'
										} - ${event.root.ageUntil?.label ?? '*'}`}
									</div>
									<div className={style.verticalDivider}></div>
								</>
							)}
						</Fragment>
					))}
					{eventTypeArray.map((event, index) => (
						<Fragment key={`${event.id}-duration-${index}`}>
							{event.root?.duration?.label && (
								<>
									<div className={style.duration}>
										{`${translation('ProgramAndTicketPageEventSubInfo.duration')} ${
											event.root.duration.label ?? ''
										}+`}
									</div>
									<div className={style.verticalDivider}></div>
								</>
							)}
						</Fragment>
					))}
				</>
			)}
			{time && (
				<>
					<time dateTime={time}>{time}</time>
					<div className={style.verticalDivider}></div>
				</>
			)}
			{venue && (
				<>
					<span className={style.place}>{venue}</span>
				</>
			)}
		</div>
	)
}
