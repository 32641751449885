import type { FunctionComponent } from 'react'
import { Audio, type AudioProps } from './Audio'
import styles from './AudioBlock.module.sass'

export type AudioBlockProps = AudioProps

export const AudioBlock: FunctionComponent<AudioBlockProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<Audio items={items} />
		</div>
	)
}
