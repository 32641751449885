import clsx from 'clsx'
import { useMemo, type FunctionComponent } from 'react'
import { ContentRenderer } from './Content'
import { DetailHeroGallery } from './DetailHeroGallery'
import { DetailTableSpecification } from './DetailTableSpecification'
import { DetailUpcomingProgram } from './DetailUpcomingProgram'
import { Tags, type TagsProps } from './Tags'
import type { WorkshopDetailProps } from './WorkshopDetail'
import styles from './WorkshopDetailHero.module.sass'

export type WorkshopDetailHeroProps = Pick<
	WorkshopDetailProps,
	'root' | 'title' | 'content' | 'tableSpecification' | 'eventTitle'
>

export const WorkshopDetailHero: FunctionComponent<WorkshopDetailHeroProps> = ({
	root,
	title,
	content,
	tableSpecification,
	eventTitle,
}) => {
	const media = root?.cover

	const tagList = useMemo(() => {
		const tags: TagsProps['items'] = []
		if (root?.ageFrom?.label) {
			tags.push({
				text: root.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		if (root?.tags?.items) {
			root.tags.items.map((item) => {
				item.tag?.localesByLocale?.title && tags.push({ text: item.tag.localesByLocale.title })
			})
		}
		return tags
	}, [root?.ageFrom?.label, root?.tags?.items])

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.column, styles.is_media)}>
				<div className={styles.media}>
					{media && (
						<div className={styles.gallery}>
							<DetailHeroGallery {...media} />
						</div>
					)}
					<div className={styles.upcomingProgram}>
						<div className={styles.upcomingProgramIn}>
							<DetailUpcomingProgram
								events={eventTitle?.events?.map((item) => ({
									webTicketId: item.webTicketId,
									id: item.id,
									repertoireTitle: title,
									title: item.title,
									datumOd: item.datumOd,
									datumOdModified: item.datumOdModified,
									venue: item.venue,
									volno: item.volno,
									ageFrom: root?.ageFrom?.label,
									isHiddenEvent: item.isHiddenEvent,
									skoly: item.skoly,
								}))}
								type="workshop"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={clsx(styles.column, styles.is_content)}>
				{title && <h1 className={styles.title}>{title}</h1>}
				{tagList.length > 0 && (
					<div className={styles.tags}>
						<Tags items={tagList} />
					</div>
				)}
				{content && <ContentRenderer isWorkshopDetail content={content} textGutters="full" />}
				<div className={styles.tableSpecification}>
					<DetailTableSpecification table={tableSpecification} />
				</div>
			</div>
		</div>
	)
}
