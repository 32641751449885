import type { TileResult } from '../app/data/content/components/TileFragment'
import { Button } from './Button'
import style from './GridTileFullContent.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type GridTileFullContentProps = {
	title: TileResult['title']
	text: TileResult['text']
	button: TileResult['button']
}

export const GridTileFullContent: React.FunctionComponent<GridTileFullContentProps> = ({
	title,
	text,
	button,
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.body}>
				{title && <h2 className={style.title}>{title}</h2>}
				{text && (
					<div className={style.text}>
						<Wysiwyg source={text} />
					</div>
				)}
				{button && button.title && (
					<div className={style.actions}>
						<Button type="link" link={button} variant="red" isSideSpaced>
							{button.title}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
