import Head from 'next/head'
import type { SeoResult } from '../app/data/content/components/OgSchemaFragment'

type SeoProps = SeoResult

export const Seo: React.FunctionComponent<SeoProps> = ({
	title,
	description,
	ogTitle,
	ogDescription,
	ogImage,
}) => {
	return (
		<Head>
			<title>{title ?? 'Minor'}</title>
			{description && <meta name="description" content={description} />}
			{ogTitle && <meta property="og:title" content={ogTitle} />}
			{ogDescription && <meta property="og:description" content={ogDescription} />}
			{ogImage && (
				<>
					<meta property="og:image" content={ogImage.url} />
					{ogImage.alt && <meta property="og:image:alt" content={ogImage.alt} />}
					{ogImage.width && <meta property="og:image:width" content={`${ogImage.width}`} />}
					{ogImage.height && <meta property="og:image:height" content={`${ogImage.height}`} />}
				</>
			)}
		</Head>
	)
}
