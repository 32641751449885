import type { FunctionComponent } from 'react'
import type { ProductLocaleResult } from '../app/data/content/components/ProductLocaleFragment'
import style from './EshopList.module.sass'
import { EshopProduct } from './EshopProduct'

export type EshopListProps = { products: ProductLocaleResult[] }

export const EshopList: FunctionComponent<EshopListProps> = ({ products }) => {
	return (
		<div className={style.wrapper}>
			{products.length > 0 && (
				<div className={style.list}>
					{products.map((product) => (
						<div key={product.id} className={style.item}>
							<EshopProduct {...product} />
						</div>
					))}
				</div>
			)}
		</div>
	)
}
