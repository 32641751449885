import type { FunctionComponent } from 'react'
import style from './Tag.module.sass'

export type TagProps = {
	text: string
	hasAddCharacter?: boolean
}

export const Tag: FunctionComponent<TagProps> = ({ text, hasAddCharacter }) => {
	return (
		<div className={style.wrapper}>
			{text}
			{hasAddCharacter && '+'}
		</div>
	)
}
