import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useLocalLoading } from 'shared-loading-indicator'
import { OrderFormInputs, paymentTypes } from '../app/forms/OrderForm/OrderFormInputs'
import { validateZod } from '../app/validators/utils/validateZod'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import { api } from './ContactFormBox'
import style from './EshopOrderForm.module.sass'
import { CheckboxField, InputField, RadioField, TextArea } from './FormFields'
import { LoadingDots } from './LoadingDots'
import { useOrderFormData } from './contexts/FormDataContextProvider'
import { useGetRecaptchaToken } from './contexts/RecaptchaContextProvider'

export type EshopOrderFormProps = {
	title?: string
	text?: string
}

export const EshopOrderForm: FunctionComponent<EshopOrderFormProps> = ({ title, text }) => {
	const paymentTypeArray = Object.values(paymentTypes)

	const [isLoading, setIsLoading] = useLocalLoading()

	const translation = useTranslate()
	const formDataTranslation = useOrderFormData()

	const flashSuccessMessage = formDataTranslation?.localesByLocale?.successMessage
	const flashErrorMessage = formDataTranslation?.localesByLocale?.errorMessage

	const mutation = useMutation(async (formData: FormData) => {
		const validatedData = validateZod(formData, OrderFormInputs())

		return api('orderForm', validatedData)
	})

	const getRecaptchaToken = useGetRecaptchaToken()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (event.currentTarget) {
			setIsLoading(true)
			const formData = new FormData(event.currentTarget)
			const recaptchaToken = await getRecaptchaToken()
			if (recaptchaToken === null) {
				setIsLoading(false)
				toast.error(flashErrorMessage, { toastId: 'eshop-order-form-error' })
				return
			}
			formData.append('recaptcha', recaptchaToken)
			await mutation
				.mutateAsync(formData)
				.then((result) => {
					if (result) {
						if (result.contemberStatus && !result.contemberStatus.ok) {
							console.error(result.contemberStatus.errorMessage)
						}
						if (result.status) {
							toast.success(flashSuccessMessage, {
								toastId: 'eshop-order-form-success',
							})
						} else {
							toast.error(flashErrorMessage, { toastId: 'eshop-order-form-error' })
							console.error(result.error)
						}
					}
				})
				.catch((e) => {
					toast.error(flashErrorMessage, { toastId: 'eshop-order-form-error' })
					console.error(e)
				})
			setIsLoading(false)
		}
	}

	return (
		<div className={style.wrapper}>
			{title && <h2 className={style.title}>{title}</h2>}
			{text && <div className={style.text}>{text}</div>}
			<form
				className={style.form}
				onSubmit={(event) => {
					event.preventDefault()
					handleSubmit(event)
				}}>
				{isLoading && <LoadingDots size="1.5em" duration="2.5s" />}
				<TextArea
					name="details"
					placeholder={formDataTranslation?.localesByLocale?.detailsPlaceholder}
					required
				/>
				<InputField
					type="text"
					name="name"
					placeholder={formDataTranslation?.localesByLocale?.namePlaceholder}
					required
				/>
				<InputField
					type="text"
					name="address"
					placeholder={formDataTranslation?.localesByLocale?.addressPlaceholder}
					required
				/>
				<InputField
					type="text"
					name="city"
					placeholder={formDataTranslation?.localesByLocale?.cityPlaceholder}
					required
				/>
				<InputField
					type="text"
					name="postalCode"
					placeholder={formDataTranslation?.localesByLocale?.postalCodePlaceholder}
					pattern="[0-9]{5}"
					required
					maxLength={5}
				/>
				<InputField
					type="email"
					name="email"
					placeholder={formDataTranslation?.localesByLocale?.emailPlaceholder}
					required
				/>
				<InputField
					type="tel"
					name="phone"
					placeholder={formDataTranslation?.localesByLocale?.telephonePlaceholder}
					required
				/>
				<div className={style.paymentOptions}>
					{paymentTypeArray.map((type, index) => (
						<RadioField
							key={type + '_' + index}
							label={
								type === 'bankTransfer'
									? formDataTranslation?.localesByLocale?.bankTransferLabel
									: formDataTranslation?.localesByLocale?.cashOnDeliveryLabel
							}
							name="payment_type"
							value={type}
							required
						/>
					))}
				</div>
				<CheckboxField
					label={
						formDataTranslation?.localesByLocale?.termsAndAgreements ? (
							<RichTextRenderer source={formDataTranslation?.localesByLocale.termsAndAgreements} />
						) : (
							''
						)
					}
					name="terms_and_conditions"
					required
				/>
				<div className={style.submit}>
					<Button type="submit" variant="red">
						{translation('send')}
					</Button>
				</div>
			</form>
		</div>
	)
}
