import type { FunctionComponent } from 'react'
import React from 'react'
import { ContemberInternalLink } from './ContemberLink'
import style from './ProgramAndTicketEventTitle.module.sass'
import type { EventTypeArray } from './ProgramAndTicketPageTileGroupedEvents'

export type ProgramAndTicketEventTitleProps = {
	type?: 'page' | 'block'
	originalTitle?: string
	overrideTitle?: string
	events?: EventTypeArray
}

export const ProgramAndTicketEventTitle: FunctionComponent<ProgramAndTicketEventTitleProps> = ({
	events,
	overrideTitle,
	originalTitle,
}) => {
	return (
		<>
			{/* Event might be connect to more than 1 repertoire/workshop */}
			{overrideTitle
				? overrideTitle
				: events && events.length > 0
				? events.map((event, index) => (
						<React.Fragment key={event.id}>
							{event.link ? (
								<>
									<ContemberInternalLink className={style.link} link={event.link}>
										{event.title}
									</ContemberInternalLink>
									{event && events.length > 1 && index !== event.title.length - 1 && ', '}
								</>
							) : (
								event.title
							)}
						</React.Fragment>
				  ))
				: originalTitle}
		</>
	)
}
