import type { FunctionComponent } from 'react'
import type { GroupedEventsResult } from '../utils/getGroupedEvents'
import style from './ProgramAndTicketPageEventsGrouped.module.sass'
import { ProgramAndTicketPageEventsGroupedByDay } from './ProgramAndTicketPageEventsGroupedByDay'

export type ProgramAndTicketPageEventsGroupedProps = {
	groupedEvents: GroupedEventsResult
}

export const ProgramAndTicketPageEventsGrouped: FunctionComponent<
	ProgramAndTicketPageEventsGroupedProps
> = ({ groupedEvents }) => {
	return (
		<div className={style.wrapper}>
			{groupedEvents.map((groupedEventsByDay, indexDay) => (
				<ProgramAndTicketPageEventsGroupedByDay
					key={groupedEventsByDay.day + indexDay}
					groupedEventsByDay={groupedEventsByDay}
				/>
			))}
		</div>
	)
}
