import { z } from 'zod'

export const ReviewFormInputs = () =>
	z.object({
		recaptcha: z.string().min(1),
		name: z.string().nullable(),
		email: z.string().nullable(),
		subject: z.string().nullable(),
		message: z.string().min(1),
		repertoireId: z.string().min(1),
	})

export type ReviewFormInputsResult = z.infer<ReturnType<typeof ReviewFormInputs>>
