import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import style from './LocaleSwitcher.module.sass'
import { useLocales } from './contexts/LocalesContextProvider'

export const LocaleSwitcher: FunctionComponent = () => {
	const pageLocales = useLocales()
	return (
		<div className={style.wrapper}>
			<div className={style.list}>
				{pageLocales &&
					pageLocales.length > 1 &&
					pageLocales.map(
						(pageLocale) =>
							pageLocale.link &&
							pageLocale.locale && (
								<div
									key={pageLocale.id}
									className={clsx(style.item, style[`view_${pageLocale.locale.code}`])}>
									<Link
										href={pageLocale.link.url}
										locale={false}
										key={pageLocale.id}
										className={style.link}>
										<div className={style.icon}>
											<Image
												src={`/lang/${pageLocale.locale.code}.png`}
												className={style.iconIn}
												fill
												alt={pageLocale.locale.label ?? pageLocale.locale.code}
												sizes="60px"
											/>
										</div>
									</Link>
								</div>
							)
					)}
			</div>
		</div>
	)
}
