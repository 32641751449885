import clsx from 'clsx'
import * as icons from '../app/svg/icons'
import style from './Icon.module.sass'

export type IconName = keyof typeof icons

export interface IconProps {
	name: IconName
}

export const Icon: React.FunctionComponent<IconProps> = ({ name }) => {
	const icon = icons[name]
	return (
		<svg viewBox={icon.viewBox} className={clsx(style.wrapper, style[`icon-${name}`])}>
			<use xlinkHref={`#${icon.id}`}></use>
		</svg>
	)
}
