import type { FunctionComponent } from 'react'
import React from 'react'
import type { ContentResult } from '../app/data/content/components/ContentFragment'
import type { PersonTileResult } from '../app/data/content/components/PersonTileFragment'
import { useHydrated } from '../libs/next/hydratation'
import { ContentRenderer } from './Content'
import { GalleryWithSmallThumbnails } from './GalleryWithSmallThumbnails'
import style from './PeoplePagePersonTileContent.module.sass'

export type PeoplePagePersonTileContentProps = {
	firstName: PersonTileResult['firstName']
	lastName: PersonTileResult['lastName']
	content?: ContentResult
	gallery?: PersonTileResult['gallery']
	setOpenPersonId: React.Dispatch<React.SetStateAction<string | null>>
}

export const PeoplePagePersonTileContent: FunctionComponent<PeoplePagePersonTileContentProps> = ({
	firstName,
	lastName,
	content,
	gallery,
	setOpenPersonId,
}) => {
	const hydrated = useHydrated()
	return (
		<div className={style.wrapper}>
			{gallery && (
				<div className={style.gallery}>
					<GalleryWithSmallThumbnails gallery={gallery} viewType="personTile" />
				</div>
			)}
			<div className={style.content}>
				<h2 className={style.name}>
					{firstName && `${firstName} `} {lastName}
				</h2>
				<button
					type="button"
					onClick={() => {
						setOpenPersonId(null)
					}}
					className={style.close}></button>
				{hydrated && content && <ContentRenderer content={content} textGutters="full" />}
			</div>
		</div>
	)
}
