import { z } from 'zod'

export const NewsletterFormInputs = () =>
	z.object({
		recaptcha: z.string().min(1),
		firstName: z.string().min(1),
		lastName: z.string().min(1),
		email: z.string().min(1),
		newsletterListId: z.string().min(1),
	})

export type NewsletterFormInputsResult = z.infer<ReturnType<typeof NewsletterFormInputs>>
