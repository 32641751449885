import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './FormFields.module.sass'

export type FormFieldProps = {
	label?: string | React.ReactNode
	isLabelSizeBig?: boolean
	name: string
	placeholder?: string
	required?: boolean
	autocomplete?: string
	pattern?: string
	maxLength?: number
	customClassName?: string
}

export type InputFieldProps = {
	type: string
} & FormFieldProps

export type TextAreaProps = FormFieldProps

export type CheckboxFieldProps = FormFieldProps

export type RadioFieldProps = FormFieldProps & { value: string }

export const InputField: FunctionComponent<InputFieldProps> = ({
	label,
	isLabelSizeBig,
	type,
	name,
	placeholder,
	required,
	autocomplete,
	pattern,
	maxLength,
}) => {
	return (
		<div className={style.wrapper}>
			<label className={style.label}>
				{label && (
					<span className={clsx(style.labelText, isLabelSizeBig && style.is_big)}>{`${label}${
						required ? '*' : ''
					}`}</span>
				)}
				<input
					className={style.input}
					type={type}
					name={name}
					placeholder={placeholder}
					required={required}
					autoComplete={autocomplete}
					pattern={pattern}
					maxLength={maxLength}
				/>
			</label>
		</div>
	)
}

export const TextArea: FunctionComponent<TextAreaProps> = ({
	label,
	isLabelSizeBig,
	name,
	placeholder,
	required,
	autocomplete,
}) => {
	return (
		<div className={clsx(style.wrapper, style.view_textArea)}>
			<label className={style.label}>
				{label && (
					<span className={clsx(style.labelText, isLabelSizeBig && style.is_big)}>{`${label}${
						required ? '*' : ''
					}`}</span>
				)}
				<textarea
					className={style.input}
					name={name}
					placeholder={placeholder}
					required={required}
					autoComplete={autocomplete}
				/>
			</label>
		</div>
	)
}

export const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({
	label,
	isLabelSizeBig,
	name,
	placeholder,
	required,
	customClassName,
}) => {
	return (
		<div className={clsx(style.wrapper, style.view_checkbox)}>
			<label className={style.label}>
				<input
					className={clsx(style.input, customClassName && style[customClassName])}
					type="checkbox"
					name={name}
					placeholder={placeholder}
					required={required}
				/>
				{label && (
					<span className={clsx(style.labelText, isLabelSizeBig && style.is_big)}>
						{typeof label === 'string' ? `${label}` : label}
						{required && '*'}
					</span>
				)}
			</label>
		</div>
	)
}

export const RadioField: FunctionComponent<RadioFieldProps> = ({
	label,
	isLabelSizeBig,
	name,
	placeholder,
	required,
	value,
}) => {
	return (
		<div className={clsx(style.wrapper, style.view_radio)}>
			<label className={style.label}>
				<input
					className={style.input}
					type="radio"
					name={name}
					placeholder={placeholder}
					required={required}
					value={value}
				/>
				{label && (
					<span className={clsx(style.labelText, isLabelSizeBig && style.is_big)}>{label}</span>
				)}
			</label>
		</div>
	)
}
