import clsx from 'clsx'
import style from './Container.module.sass'

export interface ContainerProps {
	children: React.ReactNode
	size?: 'normal' | 'site' | 'wide' | 'large'
	height?: 'full'
	disableGutters?: 'horizontal' | 'vertical' | 'full' | undefined
}

export const Container: React.FunctionComponent<ContainerProps> = ({
	children,
	size = 'normal',
	height,
	disableGutters = undefined,
}) => {
	return (
		<div className={style.wrapper}>
			<div
				className={clsx(
					style.main,
					style[`view_size_${size}`],
					height && style[`view_height_${height}`],
					disableGutters && style[`view_disableGutters_${disableGutters}`]
				)}>
				{children}
			</div>
		</div>
	)
}
