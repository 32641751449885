import Image from 'next/image'
import type { TileResult } from '../app/data/content/components/TileFragment'
import style from './GridTileFullImage.module.sass'
import { GridTileVideo } from './GridTileVideo'

export type GridTileFullImageProps = {
	image: TileResult['image']
	video: TileResult['video']
}

export const GridTileFullImage: React.FunctionComponent<GridTileFullImageProps> = ({
	image,
	video,
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.image}>
				<div className={style.ratio}>
					<div className={style.ratioIn}>
						{image?.url && (
							<Image
								src={image.url}
								alt={image.alt ?? ''}
								className={style.image}
								fill
								priority
								blurDataURL={image.url}
								placeholder="blur"
							/>
						)}
						{video && (
							<div className={style.videoButton}>
								<GridTileVideo {...video} buttonFontSize="largeFontSize" />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
