import { z } from 'zod'

export const ContactFormInputs = () =>
	z.object({
		recaptcha: z.string().min(1),
		name: z.string().min(1),
		email: z.string().min(1),
		message: z.string().min(1),
	})

export type ContactFormInputsResult = z.infer<ReturnType<typeof ContactFormInputs>>
