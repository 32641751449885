const localeCodes = {
	cs: 'cs-CZ',
	en: 'en-US',
} as const

export function ensureLocale(locale: string) {
	if (locale in localeCodes) {
		return locale as keyof typeof localeCodes
	}
	throw new Error('Unknown locale code')
}

export function parseDate(locale: keyof typeof localeCodes, date: string | Date) {
	date = date instanceof Date ? date : new Date(date)
	const localeCode = localeCodes[locale]
	const time = date.toLocaleTimeString(localeCode, {
		hour12: false,
		hour: '2-digit',
		minute: '2-digit',
	})
	const dayNumeric = date.toLocaleDateString(localeCode, { day: 'numeric' })
	const weekday = date.toLocaleDateString(localeCode, { weekday: 'long' })
	const month = date.toLocaleDateString(localeCode, { month: 'long' })
	const year = date.toLocaleDateString(localeCode, { year: 'numeric' })

	return { dayNumeric, weekday, month, time, year }
}

export function formatDate(locale: keyof typeof localeCodes, date: string | Date) {
	const d = parseDate(locale, date)

	return `${d.dayNumeric}. ${d.month} ${d.year}, ${d.time} - ${d.weekday}`
}
