import React, { useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import type { ListCategorySwitcherProps } from './ListCategorySwitcher'
import { ListCategorySwitcher } from './ListCategorySwitcher'
import { PageContent } from './PageContent'
import styles from './VideoPage.module.sass'
import { VideoTiles } from './VideoTiles'

export type VideoPageProps = Exclude<Pick<CommonPageProps['page'], 'videoPage'>['videoPage'], null>

export const VideoPage: React.FunctionComponent<VideoPageProps> = ({
	videos,
	listCategory,
	title,
	content,
}) => {
	const categories = useMemo(() => {
		const array: ListCategorySwitcherProps['categories'] = []

		listCategory.map((category) => {
			if (!category.localesByLocale?.link) {
				return
			}
			array.push({
				link: category.localesByLocale.link,
				name: category.localesByLocale.name,
				isHidden: category.localesByLocale.root?.isHidden,
			})
		})

		return array
	}, [listCategory])

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<PageContent title={title} content={content} />
			</div>
			<Container>
				<div className={styles.switcher}>
					<ListCategorySwitcher categories={categories} />
				</div>
				<div className={styles.videoList}>
					<VideoTiles videos={videos} />
				</div>
			</Container>
		</div>
	)
}
