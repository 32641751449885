import type { FunctionComponent } from 'react'
import type { PartnerListResult } from '../app/data/content/pages/PartnerListFragment'
import { BreakableContent } from './BreakableContent'
import styles from './DetailHeroPartners.module.sass'
import { LogoItem } from './LogoItem'

export type DetailHeroPartnersProps = PartnerListResult

export const DetailHeroPartners: FunctionComponent<DetailHeroPartnersProps> = ({
	items,
	title,
}) => {
	return (
		<div className={styles.wrapper}>
			{title && (
				<h4 className={styles.title}>
					<BreakableContent text={title} />
				</h4>
			)}
			<ul className={styles.list}>
				{items.map(
					(item) =>
						item.partner &&
						item.partner.localesByLocale?.image && (
							<LogoItem
								key={item.id}
								image={item.partner.localesByLocale.image}
								link={item.partner.localesByLocale.link}
								size={85}
							/>
						)
				)}
			</ul>
		</div>
	)
}
