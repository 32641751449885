import clsx from 'clsx'
import type { ImageProps } from 'next/image'
import Image from 'next/image'
import React from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import style from './ImageAspectRatio.module.sass'

type ImageAspectRatioProps = {
	image?: ImageResult
	hover?: 'verticalOffset'
	isRatioSquared?: boolean
	isRounded?: boolean
	isBackgroundTransparent?: boolean
	emptyImageLabel?: string
	sizes?: ImageProps['sizes']
} & (LayoutDefaultProps | LayoutMaxHeightProps | LayoutIntrinsicProps)

type LayoutDefaultProps = {
	layout?: 'default'
}

type LayoutIntrinsicProps = {
	layout?: 'intrinsic'
}

type LayoutMaxHeightProps = {
	layout?: 'maxHeight'
	maxHeight: number
}

export const ImageAspectRatio: React.FunctionComponent<ImageAspectRatioProps> = ({
	image,
	hover,
	isRatioSquared,
	isRounded,
	isBackgroundTransparent,
	emptyImageLabel = 'Minor',
	sizes,
	...props
}) => {
	const type = props.layout ?? 'default'
	const maxHeight =
		props.layout === 'maxHeight'
			? image
				? image.height && image.height > props.maxHeight
					? props.maxHeight
					: image.height
				: props.maxHeight
			: 'auto'

	return (
		<div
			className={clsx(
				style.wrapper,
				hover && style[`view_${hover}`],
				isRounded && style.view_rounded,
				isBackgroundTransparent && style.view_transparent
			)}>
			{image ? (
				<div
					className={clsx(style.image, isRatioSquared && style.view_squared, style[`view_${type}`])}
					style={{
						'--ImageAspectRatio-image-width': image.width,
						'--ImageAspectRatio-image-height': image.height,
						'--ImageAspectRatio-image-maxHeight': maxHeight,
					}}>
					{type === 'intrinsic' ? (
						<Image
							className={clsx(style.imageIn, style.is_intrinsic)}
							src={image.url}
							width={image.width}
							height={image.height}
							alt={image.alt ?? ''}
							blurDataURL={image.url}
							placeholder="blur"
							sizes={sizes}
						/>
					) : (
						// @TODO: fix width on mobile (overflowing images)
						<Image
							className={clsx(style.imageIn, style.is_fill)}
							src={image.url}
							fill
							alt={image.alt ?? ''}
							blurDataURL={image.url}
							placeholder="blur"
							sizes={sizes}
						/>
					)}
				</div>
			) : (
				<div
					style={{
						'--ImageAspectRatio-image-width': 150,
						'--ImageAspectRatio-image-height': 150,
						'--ImageAspectRatio-image-maxHeight': maxHeight,
					}}
					className={style.emptyImage}>
					<div className={style.emptyImageIn}>{emptyImageLabel}</div>
				</div>
			)}
		</div>
	)
}
