import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import style from './Header.module.sass'
import { HeaderMainNavigation } from './HeaderMainNavigation'
import { HeaderSecondaryNavigation } from './HeaderSecondaryNavigation'
import type { LayoutProps } from './Layout'

export type HeaderProps = Pick<CommonPageProps, 'header'> & {
	pageName: LayoutProps['pageName']
}

export const Header: React.FunctionComponent<HeaderProps> = ({ header, pageName }) => {
	return (
		<header className={clsx(style.wrapper, style[`is_${pageName}`])}>
			<Container size="large">
				<div className={style.main}>
					<div className={style.leftSide}>
						{header?.logo && (
							<Link href="/" className={style.logo}>
								<Image
									className={style.logoImage}
									src={header.logo.url}
									width={header.logo.width}
									height={header.logo.height}
									alt={header.logo.alt ?? ''}
									priority
								/>
							</Link>
						)}
						{header?.localesByLocale?.minorTvButton?.link &&
							header.localesByLocale.minorTvButton.image && (
								<ContemberLink
									className={style.minorTv}
									link={header.localesByLocale.minorTvButton.link}>
									<Image
										className={style.minorTvImage}
										src={header.localesByLocale.minorTvButton.image.url}
										fill
										alt={header.localesByLocale.minorTvButton.image.alt ?? ''}
										blurDataURL={header.localesByLocale.minorTvButton.image.url}
										placeholder="blur"
										sizes="100px"
									/>
								</ContemberLink>
							)}
					</div>
					<div className={style.navigations}>
						{header?.localesByLocale?.secondaryNavigation && (
							<div className={style.secondaryNavigation}>
								<HeaderSecondaryNavigation list={header.localesByLocale.secondaryNavigation.list} />
							</div>
						)}
						{header?.localesByLocale?.primaryNavigation && (
							<HeaderMainNavigation columns={header.localesByLocale.primaryNavigation.columns} />
						)}
					</div>
				</div>
			</Container>
		</header>
	)
}
