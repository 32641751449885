import React from 'react'
import type { ProductCategoryResult } from '../app/data/content/components/ProductCategoryFragment'
import style from '../components/EshopFilter.module.sass'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'

export type EshopFilterProps = {
	categories: ProductCategoryResult[]
	currentCategory: ProductCategoryResult | null
	setCurrentCategory: React.Dispatch<
		React.SetStateAction<{
			id: string
			localesByLocale?:
				| {
						id: string
						name: string
				  }
				| undefined
		} | null>
	>
}

export const EshopFilter: React.FunctionComponent<EshopFilterProps> = ({
	categories,
	currentCategory,
	setCurrentCategory,
}) => {
	const translation = useTranslate()

	return (
		<div className={style.wrapper}>
			{categories.length > 0 && (
				<div className={style.list}>
					<div className={style.item}>
						<Button
							type="button"
							viewType="tabSmall"
							onClick={() => {
								setCurrentCategory(null)
							}}
							isActive={!currentCategory}>
							{translation('EshopFilter.all')}
						</Button>
					</div>
					{categories.map((category) => (
						<div key={category.id} className={style.item}>
							<Button
								type="button"
								viewType="tabSmall"
								onClick={() => {
									setCurrentCategory(category)
								}}
								isActive={currentCategory ? category.id === currentCategory.id : false}>
								{category.localesByLocale?.name}
							</Button>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
