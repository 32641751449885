import { useRouter } from 'next/dist/client/router'
import type { FunctionComponent } from 'react'
import React from 'react'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import style from './ProgramAndTicketPageEventReservationButton.module.sass'

export type ProgramAndTicketPageEventReservationButtonProps = {
	webTicketId?: string
	capacity?: {
		remains?: number
		total?: number
	}
	overrideButton?: LinkResult
	isTypeSchool?: boolean
}

export const ProgramAndTicketPageEventReservationButton: FunctionComponent<
	ProgramAndTicketPageEventReservationButtonProps
> = ({ webTicketId, capacity, overrideButton, isTypeSchool = false }) => {
	const buttonContent = (content: React.ReactNode) => (
		<div className={style.buttonContent}>{content}</div>
	)
	const reservationUrl = isTypeSchool
		? 'https://www.webticket.cz/wt4/skoly/min/'
		: 'https://www.webticket.cz/akce/'

	const { query } = useRouter()
	const isExtraInfoSlug = 'extraDetails' in query

	const translation = useTranslate()
	const localeTicketReserve = translation('ticket.reserve')
	return (
		<div className={style.wrapper}>
			{overrideButton ? (
				<Button
					type="link"
					variant="red"
					viewType="programAndTicketPageEvent"
					link={overrideButton}>
					{buttonContent(
						overrideButton.title ?? (
							<span className={style.view_uppercase}>{localeTicketReserve}</span>
						)
					)}
				</Button>
			) : webTicketId ? (
				capacity?.remains && capacity.remains > 0 ? (
					<Button
						type="link"
						link={`${reservationUrl}${webTicketId}`}
						variant="red"
						viewType="programAndTicketPageEvent">
						{buttonContent(
							<>
								<span className={style.view_uppercase}>{localeTicketReserve}</span>
								{capacity.remains && capacity.total && isExtraInfoSlug ? (
									<>
										<br />
										<small>
											({capacity.remains} / {capacity.total})
										</small>
									</>
								) : null}
							</>
						)}
					</Button>
				) : (
					<Button type="button" variant="grey" isDisabled viewType="programAndTicketPageEvent">
						{buttonContent(translation('ticket.soldOut'))}
					</Button>
				)
			) : null}
		</div>
	)
}
