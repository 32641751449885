import React from 'react'
import type { NavigationSecondaryResult } from '../app/data/content/components/NavigationSecondaryFragment'
import { ContemberLink } from './ContemberLink'
import style from './HeaderSecondaryNavigation.module.sass'

type HeaderSecondaryNavigationProps = NavigationSecondaryResult

export const HeaderSecondaryNavigation: React.FunctionComponent<HeaderSecondaryNavigationProps> = ({
	list,
}) => {
	return (
		<nav className={style.wrapper} aria-label="secondary list navigation">
			{list && (
				<ul className={style.list}>
					{list.map((item) => (
						<React.Fragment key={item.id}>
							{item.link?.title && (
								<li className={style.item}>
									<ContemberLink className={style.link} link={item.link}>
										{item.link.title}
									</ContemberLink>
								</li>
							)}
						</React.Fragment>
					))}
				</ul>
			)}
		</nav>
	)
}
