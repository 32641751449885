import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { TileItemResult } from '../app/data/content/components/TileItemFragment'
import { Button } from './Button'
import style from './GridTile.module.sass'
import { GridTileFull } from './GridTileFull'
import { GridTileVideo } from './GridTileVideo'

export type GridTileProps = TileItemResult

export const GridTile: FunctionComponent<GridTileProps> = ({ tile, size }) => {
	return (
		<>
			{size === 'full' && tile ? (
				<GridTileFull {...tile} />
			) : (
				<div className={clsx(style.wrapper, style[`view_${size}`])}>
					<div className={style.background}>
						{tile?.image?.url && (
							<Image
								className={style.image}
								src={tile.image.url}
								fill
								alt={tile.image.alt ?? ''}
								blurDataURL={tile.image.url}
								placeholder="blur"
								sizes={
									size === 'large'
										? '1000px'
										: size === 'medium'
										? '700px'
										: size === 'small'
										? '350px'
										: undefined
								}
							/>
						)}
					</div>
					<div className={style.content}>
						{tile?.title && <h3 className={style.title}>{tile.title}</h3>}
						{tile?.text && (
							<div className={style.text}>
								<RichTextRenderer source={tile.text} />
							</div>
						)}
						{(tile?.video || tile?.button) && (
							<div className={style.actions}>
								{tile?.button && (
									<div className={style.button}>
										<Button type="link" link={tile.button} variant="red">
											{tile.button.title}
										</Button>
									</div>
								)}
								{tile?.video && (
									<div className={style.button}>
										<GridTileVideo {...tile.video} />
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}
