import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { ContemberLink } from './ContemberLink'
import styles from './LogoItem.module.sass'

export type LogoItemProps = {
	size?: number
	image: ImageResult
	link?: LinkResult
}

export const LogoItem: FunctionComponent<LogoItemProps> = ({
	size = 80,
	image: { id, url, width = 1, height = 1, alt = '', fileName = '' },
	link,
}) => {
	const volume = useMemo(
		() =>
			(width * height) / Math.pow(Math.max(width, ((height - width) / (height / width)) * 2), 2),
		[height, width]
	)
	const aspectRatio = useMemo(() => width / height, [height, width])

	const ImageWrapper = (image: ImageResult) => (
		<Image
			className={styles.image}
			src={image.url}
			width={image.width}
			height={image.height}
			alt={image.alt ?? image.fileName ?? ''}
		/>
	)

	return (
		<div
			className={styles.wrapper}
			style={{
				'--LogoItem-image-size': size,
				'--LogoItem-image-aspectRatio': aspectRatio,
				'--LogoItem-image-volume': volume,
			}}>
			{link ? (
				<ContemberLink className={styles.link} link={link}>
					{ImageWrapper({ id, url, width, height, alt, fileName })}
				</ContemberLink>
			) : (
				<>{ImageWrapper({ id, url, width, height, alt, fileName })}</>
			)}
		</div>
	)
}
