import { useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import type { ListCategorySwitcherProps } from './ListCategorySwitcher'
import { ListCategorySwitcher } from './ListCategorySwitcher'
import { PageContent } from './PageContent'
import styles from './VideoCategoryPage.module.sass'
import { VideoTiles } from './VideoTiles'

export type VideoCategoryPageProps = Exclude<
	Pick<CommonPageProps['page'], 'videoCategoryPage'>['videoCategoryPage'],
	null
>

// component is nearly identical as VideoPage.tsx but for better overview the code is separated
export const VideoCategoryPage: React.FunctionComponent<VideoCategoryPageProps> = ({
	name,
	content,
	listCategory,
	currentCategory,
}) => {
	const categories = useMemo(() => {
		const array: ListCategorySwitcherProps['categories'] = []

		listCategory.map((category) => {
			if (!category.localesByLocale?.link) {
				return
			}
			array.push({
				link: category.localesByLocale.link,
				name: category.localesByLocale.name,
				isHidden: category.localesByLocale.root?.isHidden,
			})
		})

		return array
	}, [listCategory])

	return (
		<Container size="large" disableGutters="full">
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<PageContent title={name} content={content} />
				</div>
				<Container>
					<div className={styles.switcher}>
						<ListCategorySwitcher
							categories={categories}
							currentCategoryName={currentCategory?.localesByLocale?.name}
						/>
					</div>
					{currentCategory && currentCategory.videos?.length > 0 && (
						<div className={styles.videoList}>
							<VideoTiles videos={currentCategory.videos} />
						</div>
					)}
				</Container>
			</div>
		</Container>
	)
}
