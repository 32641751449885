import clsx from 'clsx'
import React from 'react'
import type { NavigationResult } from '../app/data/content/components/NavigationFragment'
import style from './HeaderMainNavigationOverlay.module.sass'
import { HeaderMainNavigationSublinks } from './HeaderMainNavigationSublinks'

export type HeaderMainNavigationOverlayProps = NavigationResult & {
	menuIsActive: boolean
	setButtonIsActive: React.Dispatch<React.SetStateAction<boolean>>
	menuLinkIsActive: string | null
}

export const HeaderMainNavigationOverlay: React.FunctionComponent<
	HeaderMainNavigationOverlayProps
> = ({ columns, menuIsActive, setButtonIsActive, menuLinkIsActive }) => {
	const filteredColumnsSublinks = columns.filter((columnItem) => columnItem.items.length > 0)

	return (
		// eslint-disable-next-line
		<div
			onClick={() => setButtonIsActive(!menuIsActive)}
			className={clsx(style.wrapper, menuIsActive && style.is_active)}>
			{filteredColumnsSublinks?.length > 0 && (
				<div className={style.sublinkList}>
					{filteredColumnsSublinks.map((columnItem) => (
						<React.Fragment key={columnItem.id}>
							{columnItem.isItemsVisible && (
								<div className={style.sublinkItem}>
									<HeaderMainNavigationSublinks
										listSublinks={columnItem.items}
										isItemVisible={menuLinkIsActive === columnItem.id}
									/>
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	)
}
