import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { Error404PageLocaleResult } from '../app/data/content/components/Error404PageLocale'
import { Button } from './Button'
import { Container } from './Container'
import style from './Error404Page.module.sass'

export type Error404PageProps = Error404PageLocaleResult

export const Error404Page: FunctionComponent<Error404PageProps> = ({
	title,
	text,
	image,
	button,
}) => {
	return (
		<div className={style.wrapper}>
			<Container>
				<div className={style.main}>
					<div className={style.image}>
						{image?.url && (
							<Image
								src={image?.url}
								width={image.width}
								height={image.height}
								alt={image.alt ?? ''}
							/>
						)}
					</div>
					<div className={style.content}>
						<h1 className={style.title}>{title}</h1>
						<p className={style.text}>{text && <RichTextRenderer source={text} />}</p>
						<div className={style.button}>
							<Button
								link={button}
								variant="red"
								type="link"
								size="medium"
								icon={{ name: 'arrowRight', onHover: { direction: 'right', isLooped: true } }}>
								{button?.title}
							</Button>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
