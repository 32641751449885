import type { FunctionComponent } from 'react'
import React, { createContext, useCallback, useContext } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useLocalLoading } from 'shared-loading-indicator'
import style from './RecaptchaContextProvider.module.sass'

const RecaptchaContext = createContext<() => Promise<null | string>>(async () => null)

export const RecaptchaContextProvider: FunctionComponent<{ children: React.ReactNode }> = ({
	children,
}) => {
	const recaptchaRef = React.createRef<ReCAPTCHA>()
	const [_, setIsLoading] = useLocalLoading()

	const getRecaptchaToken = useCallback(async () => {
		setIsLoading(true)

		const token = await new Promise<string | null>(async (resolve) => {
			recaptchaRef.current?.reset()
			recaptchaRef.current?.executeAsync().then((token) => {
				resolve(token ?? null)
			})
		})
		setIsLoading(false)
		return token
	}, [recaptchaRef, setIsLoading])

	if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) {
		return <>{children}</>
	}
	return (
		<RecaptchaContext.Provider value={getRecaptchaToken}>
			<div className={style.wrapper}>
				<ReCAPTCHA
					ref={recaptchaRef}
					size="invisible"
					sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
				/>
			</div>
			{children}
		</RecaptchaContext.Provider>
	)
}

export const useGetRecaptchaToken = () => {
	const getRecaptchaToken = useContext(RecaptchaContext)
	return getRecaptchaToken
}
