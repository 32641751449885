import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import type { VideoYoutubeItemResult } from '../app/data/content/components/VideoYoutubeItem'
import type { VideoYoutubeListResult } from '../app/data/content/components/VideoYoutubeListFragment'
import { useYoutubeVideoGallery } from '../utils/useYoutubeVideoGallery'
import { ContemberLink } from './ContemberLink'
import type { VideoTileProps } from './VideoTile'
import { VideoTile } from './VideoTile'
import style from './VideoTiles.module.sass'

export type VideoTilesProps = {
	type?: VideoTileProps['type']
	title?: VideoYoutubeListResult['title']
	videos: VideoYoutubeItemResult['video'][]
	link?: LinkResult
}

export const VideoTiles: FunctionComponent<VideoTilesProps> = ({
	type = 'page',
	title,
	videos,
	link,
}) => {
	const lightbox = useYoutubeVideoGallery(videos)
	return (
		<div className={clsx(style.wrapper, style[`is_${type}`])}>
			<div className={style.main}>
				{type === 'block' && title && <h2 className={style.title}>{title}</h2>}
				{videos.length > 0 && (
					<div className={style.list}>
						{videos.map((video, index) => (
							<div key={video?.id} className={style.item}>
								{video?.youtubeVideo?.videoId && (
									<VideoTile type="block" video={video} lightbox={lightbox} index={index} />
								)}
							</div>
						))}
					</div>
				)}
			</div>
			{type === 'block' && (
				<div className={style.footer}>
					{link && (
						<ContemberLink className={style.link} link={link}>
							{link.title}
						</ContemberLink>
					)}
				</div>
			)}
		</div>
	)
}
