import clsx from 'clsx'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import { Container } from './Container'
import { NewspaperForm } from './NewspaperForm'
import styles from './NewspaperPage.module.sass'
import { NewspaperRepertoireListSelectFilter } from './NewspaperRepertoireListSelectFilter'

export type NewspaperPageProps = Exclude<
	Pick<CommonPageProps['page'], 'newspaperPage'>['newspaperPage'],
	null
>

export const NewspaperPage: React.FunctionComponent<NewspaperPageProps> = ({
	title,
	titleLabel1,
	titleLabel2,
	titleLabel3,
	titleLabel4,
	titleLabel5,
	titleLabel6,
	text,
	listRepertoire,
	publishedListRepertoire,
	listFilledReviewForm,
	repertoireFilterData,
	repertoireReviewValue,
}) => {
	const translation = useTranslate()
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.head}>
					<div className={styles.headTop}>
						<div className={styles.titleLabel}>{titleLabel1}</div>
						<div className={styles.titleLabel}>{titleLabel2}</div>
						<div className={styles.titleLabel}>{titleLabel3}</div>
					</div>
					<div className={clsx(styles.border, styles.is_double)} />
					<h1 className={styles.title}>{title}</h1>
					<div className={styles.headBottom}>
						<div className={styles.headBottomIn}>
							<div className={styles.titleLabel}>{titleLabel4}</div>
							<div className={styles.titleLabel}>{titleLabel5}</div>
							<div className={styles.titleLabel}>{titleLabel6}</div>
						</div>
					</div>
				</div>
				<p className={styles.text}>{text}</p>
				<div className={styles.main}>
					<div className={clsx(styles.box, styles.is_filter)}>
						<div className={clsx(styles.boxIn, styles.is_bordered)}>
							<h2 className={styles.label}>{translation('NewspaperPage.filter.title')}</h2>
							<div className={styles.select}>
								<NewspaperRepertoireListSelectFilter
									list={publishedListRepertoire}
									repertoireFilterData={repertoireFilterData}
								/>
							</div>
						</div>
					</div>
					<div className={styles.boxes}>
						<div className={clsx(styles.box, styles.is_form)}>
							<div className={clsx(styles.boxIn, styles.is_bordered)}>
								<h2 className={styles.label}>{translation('NewspaperPage.review.title')}</h2>
								<NewspaperForm
									repertoires={listRepertoire}
									repertoireReviewValue={repertoireReviewValue}
								/>
							</div>
						</div>
						{listFilledReviewForm &&
							listFilledReviewForm.length > 0 &&
							listFilledReviewForm.map((item) => (
								<div key={item.id} className={clsx(styles.box, styles.is_review)}>
									<div className={styles.boxIn}>
										<h2 className={styles.label}>{item.subject}</h2>
										<h3 className={styles.subLabel}>{item.repertoire?.localesByLocale?.title}</h3>
										<p className={styles.paragraph}>{item.message}</p>
										<span className={styles.author}>{item.name}</span>
									</div>
								</div>
							))}
					</div>
				</div>
			</Container>
		</div>
	)
}
