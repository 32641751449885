import Link from 'next/link'
import type { FunctionComponent } from 'react'
import React from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { Container } from './Container'
import { PersonDetailHeader } from './PersonDetailHeader'
import style from './PersonEmployeeDetail.module.sass'

export type PersonEmployeeDetailProps = EmployeeProps

type EmployeeProps = Exclude<Pick<CommonPageProps['page'], 'employee'>['employee'], null>

export const PersonEmployeeDetail: FunctionComponent<PersonEmployeeDetailProps> = ({ root }) => {
	return (
		<Container>
			<div className={style.wrapper}>
				<div className={style.header}>
					{root?.lastName && (
						<PersonDetailHeader
							lastName={root.lastName}
							firstName={root.firstName}
							image={root.image}
							viewImage="imageCenter"
						/>
					)}
				</div>
				<div className={style.contact}>
					{root?.department?.localesByLocale?.name && (
						<div className={style.department}>{root.department.localesByLocale.name}</div>
					)}
					{root?.positions && root.positions.length > 0 && (
						<div className={style.positions}>
							{root.positions.map((position, index) => (
								<React.Fragment key={position.id}>
									{position.localesByLocale?.name && (
										<span>
											{position.localesByLocale?.name}
											{root.positions.length !== index + 1 && ', '}
										</span>
									)}
								</React.Fragment>
							))}
						</div>
					)}
					{root?.telephone && (
						<div className={style.telephone}>
							<Link href={`tel:${root.telephone}`} className={style.link}>
								{root.telephone}
							</Link>
						</div>
					)}
					{root?.email && (
						<div className={style.email}>
							<Link href={`mailto:${root.email}`} className={style.link}>
								{root.email}
							</Link>
						</div>
					)}
				</div>
			</div>
		</Container>
	)
}
