import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { VideoYoutubeResult } from '../app/data/content/components/VideoYoutubeFragment'
import { Icon } from './Icon'
import style from './VideoTile.module.sass'

export type VideoTileProps = {
	type: 'page' | 'block'
	video: VideoYoutubeResult
	index: number
	lightbox: {
		open: (index?: number | undefined) => void
	} | null
}

export const VideoTile: FunctionComponent<VideoTileProps> = ({ video, lightbox, index }) => {
	return (
		<div className={style.wrapper}>
			{video?.youtubeVideo?.videoId && (
				<button
					aria-label={video.localesByLocale?.title ?? 'Video'}
					className={style.button}
					type="button"
					onClick={() => lightbox?.open(index)}>
					<Image
						src={`https://img.youtube.com/vi/${video.youtubeVideo.videoId}/hqdefault.jpg`}
						fill
						className={style.image}
						alt={video.localesByLocale?.title ?? ''}
						blurDataURL={`https://img.youtube.com/vi/${video.youtubeVideo.videoId}/hqdefault.jpg`}
						placeholder="blur"
						sizes="300px"
						loading="lazy"
					/>
					<div className={style.content}>
						<div className={style.icon}>
							<Icon name="play" />
						</div>
						{video.localesByLocale?.title && (
							<h3 className={style.title}>{video.localesByLocale.title}</h3>
						)}
					</div>
				</button>
			)}
		</div>
	)
}
