import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import type { TileItemResult } from '../app/data/content/components/TileItemFragment'
import type { TileListResult } from '../app/data/content/components/TileListFragment'
import { Container } from './Container'
import { GridTile } from './GridTile'
import style from './GridTiles.module.sass'

export type GridTilesProps = TileListResult

enum GroupTileSizes {
	fullWidth,
	otherWidth,
}

type GroupedTileByFullAndRestSizesProps = Array<{
	layout: GroupTileSizes
	items: TileItemResult[]
}>

export const GridTiles: FunctionComponent<GridTilesProps> = ({ items }) => {
	const groupTilesByFullAndRestSizes = useMemo(() => {
		const groupedTilesByFullAndRestSizes: GroupedTileByFullAndRestSizesProps = []
		let group: TileItemResult[] = []
		items.map((item) => {
			if (item.size === 'full') {
				if (group.length > 0) {
					groupedTilesByFullAndRestSizes.push({ layout: GroupTileSizes.otherWidth, items: group })
				}
				groupedTilesByFullAndRestSizes.push({ layout: GroupTileSizes.fullWidth, items: [item] })
				group = []
			} else {
				group.push(item)
			}
		})
		if (group.length > 0) {
			groupedTilesByFullAndRestSizes.push({ layout: GroupTileSizes.otherWidth, items: group })
		}

		return groupedTilesByFullAndRestSizes
	}, [items])

	const renderTiles = (items: TileItemResult[]) =>
		items.map((item) => {
			return (
				<div key={item.id} className={clsx(style.tile, style[`view_${item.size}`])}>
					<GridTile {...item} />
				</div>
			)
		})

	return (
		<div className={style.wrapper}>
			{items.length > 0 && (
				<div className={style.tiles}>
					{groupTilesByFullAndRestSizes.map((group, index) => (
						<div key={index} className={style.group}>
							{group.layout === GroupTileSizes.fullWidth ? (
								<Container size="large" disableGutters="full">
									{renderTiles(group.items)}
								</Container>
							) : (
								<Container disableGutters="vertical">
									<div className={style.view_grid}>{renderTiles(group.items)}</div>
								</Container>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	)
}
