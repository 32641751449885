import type { FunctionComponent } from 'react'
import type { ProgramAndTicketPageEventsGroupedByDayProps } from './ProgramAndTicketPageEventsGroupedByDay'
import style from './ProgramAndTicketPageEventsGroupedByTitle.module.sass'
import { ProgramAndTicketPageTileGroupedEvents } from './ProgramAndTicketPageTileGroupedEvents'

export type ProgramAndTicketPageEventsGroupedByTitleProps = {
	groupedEventsByTitle: ProgramAndTicketPageEventsGroupedByDayProps['groupedEventsByDay']['groupedEventsByTitle']
}

export const ProgramAndTicketPageEventsGroupedByTitle: FunctionComponent<
	ProgramAndTicketPageEventsGroupedByTitleProps
> = ({ groupedEventsByTitle }) => {
	return (
		<div className={style.wrapper}>
			{groupedEventsByTitle.map((titleEvents, indexTitle) => (
				<div key={titleEvents.id + indexTitle} className={style.main}>
					{titleEvents && (
						<div className={style.event}>
							<ProgramAndTicketPageTileGroupedEvents events={titleEvents.events} />
						</div>
					)}
				</div>
			))}
		</div>
	)
}
