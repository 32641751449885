import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import { ImageAspectRatio } from './ImageAspectRatio'
import style from './PersonDetailHeader.module.sass'

export type PersonDetailHeaderProps = {
	lastName: string
	firstName?: string
	image?: ImageResult
	viewImage?: 'imageCenter'
}

export const PersonDetailHeader: FunctionComponent<PersonDetailHeaderProps> = ({
	lastName,
	firstName,
	image,
	viewImage,
}) => {
	return (
		<div className={clsx(style.wrapper, viewImage && style[`view_${viewImage}`])}>
			{lastName && (
				<h1>
					{firstName && `${firstName} `}
					{lastName}
				</h1>
			)}
			<div className={style.image}>
				<ImageAspectRatio
					sizes="200px"
					image={image}
					isRounded
					emptyImageLabel={lastName + (firstName && ` ${firstName}`)}
				/>
			</div>
		</div>
	)
}
