import React from 'react'
import type { NavigationSecondaryResult } from '../app/data/content/components/NavigationSecondaryFragment'
import { ContemberLink } from './ContemberLink'
import style from './FooterNavigation.module.sass'

export type FooterNavigationProps = NavigationSecondaryResult

export const FooterNavigation: React.FunctionComponent<FooterNavigationProps> = ({ list }) => {
	return (
		<div className={style.wrapper}>
			{list && (
				<ul className={style.list}>
					{list.map((item) => (
						<React.Fragment key={item.id}>
							{item.link?.title && (
								<li className={style.item}>
									{item.link && (
										<ContemberLink className={style.link} link={item.link}>
											{item.link.title}
										</ContemberLink>
									)}
								</li>
							)}
						</React.Fragment>
					))}
				</ul>
			)}
		</div>
	)
}
