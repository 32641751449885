import React from 'react'
import type { PartnerGroupResult } from '../app/data/content/components/PartnerGroupFragment'
import { ContemberLink } from './ContemberLink'
import style from './FooterPartners.module.sass'
import { ImageAspectRatio } from './ImageAspectRatio'

export type FooterPartnersProps = PartnerGroupResult

export const FooterPartners: React.FunctionComponent<FooterPartnersProps> = ({ items }) => {
	return (
		<div className={style.wrapper}>
			{items && (
				<div className={style.list}>
					{items.map((item) => (
						<React.Fragment key={item.id}>
							{item.partner?.localesByLocale && (
								<div key={item.partner.localesByLocale.id} className={style.item}>
									{item.partner.localesByLocale.link?.externalLink ||
									item.partner.localesByLocale.link?.internalLink ? (
										<ContemberLink className={style.link} link={item.partner.localesByLocale.link}>
											<ImageAspectRatio
												image={item.partner.localesByLocale.image}
												hover="verticalOffset"
												layout="maxHeight"
												maxHeight={90}
												sizes="500px"
											/>
										</ContemberLink>
									) : (
										<ImageAspectRatio
											image={item.partner.localesByLocale.image}
											layout="maxHeight"
											maxHeight={90}
											sizes="500px"
										/>
									)}
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	)
}
