import { WorkshopTileLinkItem, type WorkshopTileLinkItemProps } from './WorkshopTileLinkItem'
import style from './WorkshopTileLinkList.module.sass'

type WorkshopTileLinkListProps = {
	items: WorkshopTileLinkItemProps[]
}

export const WorkshopTileLinkList: React.FunctionComponent<WorkshopTileLinkListProps> = ({
	items,
}) => {
	return items.length > 0 ? (
		<div className={style.wrapper}>
			{items.map((tile) => (
				<div key={tile.id} className={style.tile}>
					<WorkshopTileLinkItem {...tile} />
				</div>
			))}
		</div>
	) : null
}
