import React, { type ReactNode } from 'react'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import { WorkshopFilterButton } from './WorkshopFilterButton'
import style from './WorkshopListCategorySwitcher.module.sass'

export type WorkshopListCategorySwitcherProps = {
	categories: CategoryProps[]
	currentCategoryName?: string
	returnPage?: string
}

type CategoryProps = {
	name: string
	link: InternalLinkResult
	isHidden?: boolean
	descriptionChildren?: ReactNode
}

export const WorkshopListCategorySwitcher: React.FunctionComponent<
	WorkshopListCategorySwitcherProps
> = ({ categories, currentCategoryName: currentCategory, returnPage }) => {
	return (
		<div className={style.wrapper}>
			{categories.map((category, index) => (
				<React.Fragment key={index}>
					{category?.link && (
						<div className={style.button}>
							<WorkshopFilterButton
								title={category.name}
								type="link"
								link={category.link.url}
								returnLink={returnPage}
								isActive={category.name === currentCategory}>
								<div className={style.description}>
									{category.descriptionChildren && category.descriptionChildren}
								</div>
							</WorkshopFilterButton>
						</div>
					)}
				</React.Fragment>
			))}
		</div>
	)
}
