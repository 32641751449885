import Link from 'next/link'
import type { FunctionComponent, MouseEventHandler } from 'react'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { assertDefined } from '../utils/assertDefined'
import { contemberLinkToHref } from '../utils/contemberLinkToHref'

export type ContemberLinkProps = {
	link: LinkResult
	children: React.ReactNode
	className?: HTMLAnchorElement['className']
	onClick?: MouseEventHandler<HTMLAnchorElement> | undefined
}

export const ContemberLink: FunctionComponent<ContemberLinkProps> = ({
	link,
	className,
	children,
	onClick,
}) => {
	return (
		<Link
			href={contemberLinkToHref(link)}
			onClick={onClick}
			className={className}
			target={link.isTargetBlank ? '_blank' : undefined}
			rel={link.isTargetBlank ? 'noopener noreferrer' : undefined}>
			{children}
		</Link>
	)
}

export type ContemberInternalLinkProps = Pick<
	ContemberLinkProps,
	'children' | 'className' | 'onClick'
> & {
	link: InternalLinkResult | undefined
}

export const ContemberInternalLink: FunctionComponent<ContemberInternalLinkProps> = ({
	link,
	children,
	className,
	onClick,
}) => {
	assertDefined(link)
	return (
		<Link onClick={onClick} className={className} href={link.url}>
			{children}
		</Link>
	)
}
