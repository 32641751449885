import clsx from 'clsx'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import React, { useEffect } from 'react'
import type { Props } from 'react-modal'
import ReactModal from 'react-modal'
import style from './Modal.module.sass'

export type ModalProps = Props & {
	setModalOpen: Dispatch<SetStateAction<boolean>>
	isNoVerticalPadding?: boolean
	children: React.ReactNode
}

export const Modal: FunctionComponent<ModalProps> = ({
	isNoVerticalPadding: isNoPadding,
	children,
	setModalOpen,
	...props
}) => {
	const closeTimeoutMS = 200 // 200 = .2s for .overlay - if different value && must be changed here and on Modal.module.sass

	useEffect(() => {
		ReactModal.setAppElement('body')
	}, [])

	return (
		<ReactModal
			isOpen={props.isOpen}
			onRequestClose={() => setModalOpen(!props.isOpen)}
			closeTimeoutMS={closeTimeoutMS}
			className={style.modal}
			overlayClassName={{
				base: style.overlay,
				afterOpen: style.is_open,
				beforeClose: style.before_close,
			}}
			bodyOpenClassName={style.body}>
			<div className={style.close}></div>
			<div className={clsx(style.modalIn, isNoPadding && style.no_vertical_padding)}>
				<button className={style.modalClose} onClick={() => setModalOpen(false)}>
					×
				</button>
				{children}
			</div>
		</ReactModal>
	)
}
