import type { FunctionComponent } from 'react'
import type { ContentResult } from '../app/data/content/components/ContentFragment'
import { Container } from './Container'
import { ContentRenderer } from './Content'
import { PageTitle } from './PageTitle'

export type PageContentProps = {
	title?: string
	content?: ContentResult
}

export const PageContent: FunctionComponent<PageContentProps> = ({ title, content }) => {
	return (
		<>
			{!content && <Container>{title && <PageTitle title={title} />}</Container>}
			{content && <ContentRenderer content={content} />}
		</>
	)
}
