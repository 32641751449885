import clsx from 'clsx'
import type { APITypes } from 'plyr-react'
import Plyr from 'plyr-react'
import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { MusicTrackListResult } from '../app/data/content/components/MusicTrackListFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import styles from './Audio.module.sass'

export type AudioProps = MusicTrackListResult & {
	isRepertoire?: boolean
}

export const Audio: FunctionComponent<AudioProps> = ({ items, isRepertoire }) => {
	const [isAudioActive, setIsAudioActive] = useState(items[0].id)
	const activeAudioUrl = useMemo(() => {
		return (
			items.filter((item) => item.id === isAudioActive).filter(filterNonEmpty)[0].musicTrack?.file
				?.url ?? undefined
		)
	}, [items, isAudioActive])
	const [audioTrack, setAudioTrack] = useState(activeAudioUrl)

	const audioRef = useRef<APITypes>(null)
	const playAudio = () => (audioRef.current?.plyr as Plyr)?.play()

	useEffect(() => {
		if (activeAudioUrl) {
			setAudioTrack(activeAudioUrl)

			return () => {
				setAudioTrack(activeAudioUrl)
			}
		}
	}, [activeAudioUrl, audioTrack, items, isAudioActive])

	return (
		<div className={clsx(styles.wrapper, isRepertoire && styles.is_repertoire)}>
			<ul className={styles.navigation}>
				{items.map((item) => (
					<li key={item.id} className={styles.navigationItem}>
						<button
							className={clsx(
								styles.navigationButton,
								isAudioActive === item.id && styles.is_active
							)}
							onClick={() => {
								setIsAudioActive(item.id)
								setTimeout(() => {
									playAudio()
								}, 100)
							}}>
							{item.label ?? item.musicTrack?.name ?? item.musicTrack?.file?.name}
						</button>
					</li>
				))}
			</ul>

			<div className={styles.audio}>
				{audioTrack && (
					<Plyr
						ref={audioRef}
						options={{ controls: ['play', 'progress', 'current-time', 'volume'] }}
						source={{
							type: 'audio',
							sources: [
								{
									src: audioTrack,
									type: 'audio/mp3',
								},
							],
						}}
					/>
				)}
			</div>
		</div>
	)
}
