import clsx from 'clsx'
import 'plyr-react/plyr.css'
import type { FunctionComponent, ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from '../utils/useTranslate'
import { Icon } from './Icon'
import styles from './RepertoireReviewList.module.sass'

export type RepertoireReviewListProps = {
	title?: string
	list: RepertoireReviewListItemProps[]
}

type RepertoireReviewListItemProps = {
	id: string
	children: ReactNode
}

export const RepertoireReviewList: FunctionComponent<RepertoireReviewListProps> = ({
	list,
	title,
}) => {
	const slidesElement = useRef<HTMLDivElement>(null)

	// @TODO: create common component for the same code
	const scroll = (direction: 1 | -1) => {
		if (!slidesElement.current) {
			return
		}

		const scrollSlideOffset = direction * (slidesElement.current.scrollWidth / list.length)
		const isSliderAtAnd =
			slidesElement.current.scrollLeft ===
			(slidesElement.current.scrollWidth ?? 0) - (slidesElement.current.clientWidth ?? 0)

		if (isSliderAtAnd) {
			slidesElement.current.scrollTo({
				left: 0,
				behavior: 'smooth',
			})
		} else {
			slidesElement.current.scrollBy({
				left: scrollSlideOffset,
				behavior: 'smooth',
			})
		}
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	// @TODO: create common component for the same code
	const onSlideChange = () => {
		if (slidesElement.current === null) {
			return
		}

		const isSliderAtAnd =
			slidesElement.current.scrollLeft ===
			(slidesElement.current.scrollWidth ?? 0) - (slidesElement.current.clientWidth ?? 0)

		setShowPreviousButton(true)
		setShowNextButton(true)
		if (slidesElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (isSliderAtAnd) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (slidesElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])

	return (
		<div className={styles.wrapper}>
			{title && <h3 className={styles.title}>{title}</h3>}
			<div className={styles.main}>
				<button
					type="button"
					className={clsx(
						styles.button,
						styles.view_previous,
						showPreviousButton && styles.is_visible
					)}
					onClick={() => scroll(-1)}
					aria-label="previous">
					<Icon name="caret" />
				</button>
				<div className={styles.slides} ref={slidesElement}>
					{list.map((review) => (
						<Slide key={review.id}>{review.children}</Slide>
					))}
				</div>
				<button
					type="button"
					className={clsx(styles.button, styles.view_next, showNextButton && styles.is_visible)}
					onClick={() => scroll(1)}
					aria-label="next">
					<Icon name="caret" />
				</button>
			</div>
		</div>
	)
}

const Slide: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const slideElement = useRef<HTMLDivElement>(null)
	const [isExpanded, setIsExpanded] = useState(false)
	const slideOriginalHeight = slideElement.current?.getBoundingClientRect().height ?? 240
	const translations = useTranslate()
	return (
		<div className={clsx(styles.slide, isExpanded && styles.is_expanded)}>
			<div
				className={styles.slideIn}
				style={{
					'--RepertoireReviewList-slideIn-slideOriginalHeight': slideOriginalHeight,
				}}
				ref={slideElement}>
				{children}
			</div>
			{slideOriginalHeight > 240 && !isExpanded && (
				<button
					className={styles.expandWrapper}
					type="button"
					onClick={() => setIsExpanded(!isExpanded)}>
					<span className={styles.expandText}>
						{translations('RepertoireReviewList.review.readMore')}
					</span>
				</button>
			)}
		</div>
	)
}
