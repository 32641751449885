import type { PeoplePagePersonEmployeeItemProps } from './PeoplePagePersonEmployeeItem'
import { PeoplePagePersonEmployeeItem } from './PeoplePagePersonEmployeeItem'
import style from './PeoplePagePersonEmployeeList.module.sass'

type PeoplePagePersonEmployeeListProps = {
	employees: PeoplePagePersonEmployeeItemProps[]
}

export const PeoplePagePersonEmployeeList: React.FunctionComponent<
	PeoplePagePersonEmployeeListProps
> = ({ employees }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.list}>
				{employees.map((employee) => (
					<div className={style.person} key={employee.id}>
						<PeoplePagePersonEmployeeItem {...employee} />
					</div>
				))}
			</div>
		</div>
	)
}
