import { RichTextRenderer } from '@contember/react-client'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import styles from './AsideMediaContentBlock.module.sass'

export type AsideMediaContentBlockProps = {
	title: string
	image?: ImageResult
	text?: string
	imageDescription?: string
}

export const AsideMediaContentBlock: FunctionComponent<AsideMediaContentBlockProps> = ({
	title,
	text,
	image,
	imageDescription,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<h3 className={styles.title}>{title}</h3>
				{text && (
					<p className={styles.text}>
						<RichTextRenderer source={text} />
					</p>
				)}
			</div>
			{image && (
				<figure className={styles.figure}>
					<Image
						className={styles.image}
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.alt ?? image.fileName ?? imageDescription ?? ''}
					/>
					<figcaption className={styles.figureDescription}>{imageDescription}</figcaption>
				</figure>
			)}
		</div>
	)
}
