import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import { Container } from './Container'
import { PageContent } from './PageContent'
import { PageTileLinkList } from './PageTileLinkList'
import styles from './RepertoirePage.module.sass'
import { RepertoirePageCategoryFilter } from './RepertoirePageCategoryFilter'
import { Tags, type TagsProps } from './Tags'

export type RepertoirePageProps = NonNullable<
	Pick<CommonPageProps['page'], 'repertoirePage'>['repertoirePage']
>

export const RepertoirePage: FunctionComponent<RepertoirePageProps> = ({
	title,
	content,
	repertoires,
	categories,
}) => {
	const [activeCategoryId, setActiveCategoryId] = useState(categories[0].id)

	const translation = useTranslate()

	const tagList = useCallback((repertoire: RepertoirePageProps['repertoires'][number]) => {
		const tags: TagsProps['items'] = []
		// ageFrom tag
		if (repertoire?.ageFrom?.label) {
			tags.push({
				text: repertoire.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		// all other tags
		repertoire.tags?.items.map(
			(item) =>
				item.tag?.localesByLocale?.title &&
				tags.push({
					text: item.tag.localesByLocale.title,
				})
		)

		return tags
	}, [])

	const repertoireFiltered = useMemo(
		() =>
			repertoires.filter((repertoire) =>
				repertoire.categories.some((category) => category.id === activeCategoryId)
			),
		[activeCategoryId, repertoires]
	)

	const repertoireList = useMemo(
		() =>
			repertoireFiltered.map((item) => ({
				id: item.id,
				image: item.tileCover,
				link: {
					url: item.localesByLocale?.link?.url ?? '',
					label: translation('Repertoire.tile.button'),
				},
				title: item.localesByLocale?.title,
				lead: item.localesByLocale?.lead,
				tags: <Tags isAlignCenter items={tagList(item)} />,
			})),
		[repertoireFiltered, tagList, translation]
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<PageContent title={title} content={content} />
			</div>
			{categories.length > 0 && (
				<RepertoirePageCategoryFilter
					categories={categories}
					repertoireFiltered={repertoireList}
					setActiveCategoryId={setActiveCategoryId}
					activeCategoryId={activeCategoryId}
				/>
			)}
			<div className={styles.main}>
				<Container size="large" disableGutters="full">
					<PageTileLinkList items={repertoireList} />
				</Container>
			</div>
		</div>
	)
}
