import Image from 'next/image'
import Link from 'next/link'
import type { ReactNode } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import styles from './PageTileLinkItem.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type PageTileLinkItemProps = {
	id: string
	image?: ImageResult
	title?: string
	link?:
		| LinkResult
		| (InternalLinkResult & {
				label?: string
		  })
	lead?: string
	tags?: ReactNode
}

export const PageTileLinkItem: React.FunctionComponent<PageTileLinkItemProps> = ({
	image,
	title,
	link,
	lead,
	tags,
}) => {
	const imageBackground = image?.url && (
		<div className={styles.image}>
			<Image
				className={styles.imageIn}
				src={image.url}
				fill
				alt={image.alt ?? ''}
				blurDataURL={image.url}
				placeholder="blur"
				sizes="100vw, (min-width: 2048px) 2100px"
			/>
		</div>
	)

	const translation = useTranslate()

	const href = link
		? 'type' in link
			? link.type === 'internal'
				? link.internalLink?.url ?? '/'
				: link.externalLink ?? '/'
			: 'url' in link
			? link.url
			: '/'
		: undefined

	const label = link ? ('label' in link ? link.label : translation('goTo')) : translation('goTo')

	return (
		<div className={styles.wrapper}>
			{href ? <Link href={href}>{imageBackground}</Link> : imageBackground}
			<div className={styles.content}>
				<div className={styles.header}>
					{title && (
						<h3 className={styles.title}>
							{href ? (
								<Link href={href} className={styles.titleLink}>
									{title}
								</Link>
							) : (
								title
							)}
						</h3>
					)}
					{tags && <div className={styles.tags}>{tags}</div>}
				</div>
				{lead && (
					<div className={styles.lead}>
						<Wysiwyg source={lead} />
					</div>
				)}
				{href && (
					<div className={styles.button}>
						<Button
							type="link"
							link={href}
							variant="grey"
							size="xs"
							viewType="pageTileLinkItem"
							icon={{ name: 'arrowRight', onHover: { isLooped: true, direction: 'right' } }}>
							{label ?? translation('goTo')}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}
