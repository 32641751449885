import type { CommonPageProps } from '../pages/[[...page]]'
import { AnnualReportList } from './AnnualReportList'
import style from './AnnualReportPage.module.sass'
import { Container } from './Container'
import { PageContent } from './PageContent'

export type AnnualReportPageProps = Exclude<
	Pick<CommonPageProps['page'], 'annualReportPage'>['annualReportPage'],
	null
>

export const AnnualReportPage: React.FunctionComponent<AnnualReportPageProps> = ({
	listAnnualReport,
	title,
	content,
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.content}>
				<PageContent title={title} content={content} />
			</div>
			<Container>
				<div className={style.list}>
					<AnnualReportList listAnnualReport={listAnnualReport} />
				</div>
			</Container>
		</div>
	)
}
