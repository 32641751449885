import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React, { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import type { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import { useLocalLoading } from 'shared-loading-indicator'
import { ReviewFormInputs } from '../app/forms/ReviewForm/ReviewFormInputs'
import { validateZod } from '../app/validators/utils/validateZod'
import { Button } from './Button'
import { api } from './ContactFormBox'
import { InputField, TextArea } from './FormFields'
import { LoadingDots } from './LoadingDots'
import styles from './NewspaperForm.module.sass'
import type { NewspaperPageProps } from './NewspaperPage'
import type { SelectOptionProps } from './NewspaperRepertoireListSelect'
import { NewspaperRepertoireListSelect } from './NewspaperRepertoireListSelect'
import { useReviewFormData } from './contexts/FormDataContextProvider'
import { useGetRecaptchaToken } from './contexts/RecaptchaContextProvider'

export type NewspaperFormProps = {
	repertoires: NewspaperPageProps['listRepertoire']
	repertoireReviewValue: NewspaperPageProps['repertoireReviewValue']
}

export const NewspaperForm: FunctionComponent<NewspaperFormProps> = ({
	repertoires,
	repertoireReviewValue,
}) => {
	const [isLoading, setIsLoading] = useLocalLoading()

	const formDataTranslation = useReviewFormData()

	const flashSuccessMessage = formDataTranslation?.localesByLocale?.successMessage
	const flashErrorMessage = formDataTranslation?.localesByLocale?.errorMessage

	const mutation = useMutation(async (formData: FormData) => {
		const validatedData = validateZod(formData, ReviewFormInputs())

		return api('reviewForm', validatedData)
	})

	const getRecaptchaToken = useGetRecaptchaToken()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (event.currentTarget) {
			setIsLoading(true)
			const formData = new FormData(event.currentTarget)
			const recaptchaToken = await getRecaptchaToken()
			if (recaptchaToken === null) {
				setIsLoading(false)
				toast.error(flashErrorMessage, { toastId: 'review-order-form-error' })
				return
			}
			formData.append('recaptcha', recaptchaToken)
			await mutation
				.mutateAsync(formData)
				.then((result) => {
					if (result && result.contemberStatus) {
						if (result.contemberStatus.ok) {
							toast.success(flashSuccessMessage, { toastId: 'review-order-form-success' })
						} else {
							toast.error(flashErrorMessage, { toastId: 'review-order-form-error' })
							console.error(result.contemberStatus.errorMessage)
						}
					}
				})
				.catch((e) => {
					toast.error(flashErrorMessage, { toastId: 'review-order-form-error' })
					console.error(e)
				})
			setIsLoading(false)
		}
	}

	const defaultRepertoireId = useCallback(
		() =>
			repertoires.find((repertoire) => repertoire.localesByLocale?.slug === repertoireReviewValue)
				?.localesByLocale?.id,
		[repertoireReviewValue, repertoires]
	)

	const [repertoireId, setRepertoireId] = useState<string | undefined>(defaultRepertoireId)

	const onChange = useCallback(
		(option: SingleValue<SelectOptionProps>) => {
			option?.value
				? setRepertoireId(
						repertoires.find((repertoire) => repertoire.localesByLocale?.slug === option.value)
							?.localesByLocale?.id
				  )
				: setRepertoireId(undefined)
		},
		[repertoires]
	)

	return (
		<div className={styles.wrapper}>
			<form
				className={styles.form}
				onSubmit={(event) => {
					event.preventDefault()
					handleSubmit(event)
				}}>
				{isLoading && <LoadingDots size="1.5em" duration="2.5s" />}
				<div className={clsx(styles.item, styles.is_select)}>
					{/* @TODO: on select add slug/id of selected repertoire to url */}
					<NewspaperRepertoireListSelect
						list={repertoires}
						onChange={onChange}
						defaultValue={{
							label: repertoires.find(
								(repertoire) => repertoire?.localesByLocale?.slug === repertoireReviewValue
							)?.localesByLocale?.title,
							value: repertoireReviewValue ?? '',
						}}
					/>
					<input
						title="repertoireId"
						className={styles.repertoireSelect}
						name="repertoireId"
						defaultValue={repertoireId}
						required
					/>
				</div>
				<div className={styles.item}>
					<InputField
						isLabelSizeBig
						name="name"
						type="text"
						label={formDataTranslation?.localesByLocale?.nameLabel}
					/>
				</div>
				<div className={styles.item}>
					<InputField
						isLabelSizeBig
						name="email"
						type="email"
						label={formDataTranslation?.localesByLocale?.emailLabel}
					/>
				</div>
				<div className={styles.item}>
					<InputField
						isLabelSizeBig
						name="subject"
						type="text"
						label={formDataTranslation?.localesByLocale?.subjectLabel}
					/>
				</div>
				<div className={styles.item}>
					<TextArea
						isLabelSizeBig
						name="message"
						required
						label={formDataTranslation?.localesByLocale?.messageLabel}
					/>
				</div>
				<div className={styles.button}>
					<Button type="submit" variant="red" size="medium">
						Odeslat
					</Button>
				</div>
			</form>
		</div>
	)
}
