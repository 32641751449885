import clsx from 'clsx'
import React, { useState } from 'react'
import type { NavigationResult } from '../app/data/content/components/NavigationFragment'
import style from './HeaderMainNavigation.module.sass'
import { HeaderMainNavigationOverlay } from './HeaderMainNavigationOverlay'
import { HeaderMainNavigationSidebar } from './HeaderMainNavigationSidebar'

export type HeaderMainNavigationProps = NavigationResult

export const HeaderMainNavigation: React.FunctionComponent<HeaderMainNavigationProps> = ({
	columns,
}) => {
	const [menuIsActive, setButtonIsActive] = useState<boolean>(false)
	const [menuLinkIsActive, setMenuLinkIsActive] = useState<string | null>(null)

	return (
		<>
			<HeaderMainNavigationOverlay
				columns={columns}
				menuIsActive={menuIsActive}
				setButtonIsActive={setButtonIsActive}
				menuLinkIsActive={menuLinkIsActive}
			/>
			<button
				type="button"
				className={clsx(style.menuButton, menuIsActive && style.is_active)}
				onClick={() => setButtonIsActive(!menuIsActive)}>
				<span className={style.menuButtonLabel}>Menu</span>
				<span className={clsx(style.hamburger, menuIsActive && style.is_active)}>
					<span className={style.hamburgerLine}></span>
					<span className={style.hamburgerLine}></span>
					<span className={style.hamburgerLine}></span>
				</span>
			</button>
			<HeaderMainNavigationSidebar
				columns={columns}
				isActive={menuIsActive}
				setMenuLinkIsActive={setMenuLinkIsActive}
				setButtonIsActive={setButtonIsActive}
				menuLinkIsActive={menuLinkIsActive}
			/>
		</>
	)
}
