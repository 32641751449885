import type { LightboxSource } from '@mangoweb/react-fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import { useMemo } from 'react'
import type { VideoYoutubeItemResult } from '../app/data/content/components/VideoYoutubeItem'

export const useYoutubeVideoGallery = (videos?: VideoYoutubeItemResult['video'][]) => {
	const videoGallery = useMemo(() => {
		let sources: LightboxSource[] = []
		if (videos) {
			sources =
				videos.map((video) => ({
					source: video?.youtubeVideo?.videoId
						? `https://www.youtube.com/watch?v=${video.youtubeVideo.videoId}`
						: '',
					type: 'youtube',
					caption: video?.localesByLocale?.title,
				})) ?? []
		}
		return sources
	}, [videos])

	const lightbox = useLightbox(videoGallery)

	return lightbox
}
