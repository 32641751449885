import type { TileResult } from '../app/data/content/components/TileFragment'
import style from './GridTileFull.module.sass'
import { GridTileFullContent } from './GridTileFullContent'
import { GridTileFullImage } from './GridTileFullImage'

export type GridTileFullProps = TileResult

export const GridTileFull: React.FunctionComponent<GridTileFullProps> = ({
	image,
	title,
	text,
	video,
	button,
}) => {
	return (
		<div className={style.wrapper}>
			<div className={style.pictures}>
				{image && <GridTileFullImage image={image} video={video} />}
			</div>
			<div className={style.overlay}>
				<div className={style.overlayIn}>
					<div className={style.cards}>
						<GridTileFullContent title={title} text={text} button={button} />
					</div>
				</div>
			</div>
		</div>
	)
}
