import type { FunctionComponent } from 'react'
import { useMemo } from 'react'
import type { ContentReferenceProgramItemResult } from '../app/data/content/components/ContentReferenceProgramItem'
import type { EventWithTitleAndRepertoiresOrWorkshopsLocaleResult } from '../app/data/content/components/EventWithTitleAndRepertoiresOrWorkshopsLocaleFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { getGroupedEvents } from '../utils/getGroupedEvents'
import { Button } from './Button'
import { Icon } from './Icon'
import { PageTitle } from './PageTitle'
import styles from './UpcomingProgram.module.sass'
import { UpcomingProgramItems } from './UpcomingProgramItems'

export type UpcomingProgramProps = {
	title?: string
	program: ContentReferenceProgramItemResult[]
	link?: LinkResult
}

export const UpcomingProgram: FunctionComponent<UpcomingProgramProps> = ({
	title,
	program,
	link,
}) => {
	const programArray: EventWithTitleAndRepertoiresOrWorkshopsLocaleResult[] = useMemo(
		() => program.map((item) => item.item).filter(filterNonEmpty),
		[program]
	)

	const groupedEvents = getGroupedEvents(programArray)

	return (
		<div className={styles.wrapper}>
			{title && (
				<div className={styles.title}>
					<PageTitle title={title} isLine align="center" />
				</div>
			)}
			{groupedEvents.length > 0 && (
				<div className={styles.list}>
					{groupedEvents.map((event) => (
						<div key={event.day} className={styles.item}>
							<UpcomingProgramItems {...event} />
						</div>
					))}
				</div>
			)}
			{link && (
				<div className={styles.button}>
					<Button type="link" link={link} variant="red">
						<div className={styles.buttonIn}>
							<span className={styles.linkIcon}>
								<Icon name="calendar" />
							</span>
							<span className={styles.linkText}>{link?.title}</span>
						</div>
					</Button>
				</div>
			)}
		</div>
	)
}
