import 'collapsible-react-component/dist/index.css'
import React, { useEffect, useMemo, useState } from 'react'
import slugify from 'slugify'
import type { PersonTileResult } from '../app/data/content/components/PersonTileFragment'
import style from './PeoplePagePersonList.module.sass'
import { PeoplePagePersonTile } from './PeoplePagePersonTile'

type PeoplePagePersonListProps = {
	persons: PersonTileResult[]
}

export const PeoplePagePersonList: React.FunctionComponent<PeoplePagePersonListProps> = ({
	persons,
}) => {
	const [openPersonId, setOpenPersonId] = useState<string | null>(null)

	persons = useMemo(
		() =>
			persons.map((person) => ({
				...person,
				slug: slugify(person.slug) || slugify(person.lastName),
			})),
		[persons]
	)

	useEffect(() => {
		const { hash } = location
		if (!hash) {
			return
		}
		const slug = hash.substring(1)
		const personId = persons.find((person) => person.slug === slug)?.id ?? null
		if (personId) {
			setOpenPersonId(personId)
		}
	}, [persons])

	return (
		<div className={style.wrapper}>
			<div className={style.list}>
				{persons.map((person, index) => (
					<React.Fragment key={index}>
						{/* To keep persons in max-width: 800px (see PeoplePagePersonList.module.sass) */}
						{index === 0 ? (
							<div className={style.emptyDivToFillInGridOnDesktop}></div>
						) : index % 4 === 0 ? (
							<>
								<div className={style.emptyDivToFillInGridOnDesktop}></div>
								<div className={style.emptyDivToFillInGridOnDesktop}></div>
							</>
						) : null}
						<PeoplePagePersonTile
							key={person.id}
							{...person}
							setOpenPersonId={setOpenPersonId}
							openPersonId={openPersonId}
						/>
					</React.Fragment>
				))}
			</div>
		</div>
	)
}
