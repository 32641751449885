import clsx from 'clsx'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import type { TicketPaymentInfoVenueResult } from '../app/data/content/components/TicketPaymentInfoVenueFragment'
import { Button } from './Button'
import style from './ProgramAndTicketPageFilterShowTypes.module.sass'

export type ProgramAndTicketPageFilterShowTypesProps = {
	venues: TicketPaymentInfoVenueResult[]
	venueId: string | null
	showTypeId: string | null
	setShowTypeId: Dispatch<SetStateAction<string | null>>
}

export const ProgramAndTicketPageFilterShowTypes: FunctionComponent<
	ProgramAndTicketPageFilterShowTypesProps
> = ({ venues, venueId, showTypeId, setShowTypeId }) => {
	return (
		<div className={style.wrapper}>
			{venues.map(
				(venue) =>
					venue.showTypes.length > 0 &&
					venue.showTypes.map((type) => (
						<div
							key={type.id}
							className={clsx(style.button, venue.id === venueId && style.is_inView)}>
							<Button
								type="button"
								viewType="tabSmall"
								onClick={() => setShowTypeId(type.id)}
								isActive={type.id === showTypeId}>
								{type.label}
							</Button>
						</div>
					))
			)}
		</div>
	)
}
