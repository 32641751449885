import type { ImageResult } from '../app/data/content/components/ImageFragment'
import { PeoplePagePersonItemImage } from './PeoplePagePersonItemImage'
import style from './PeoplePagePersonProfile.module.sass'

export type PeoplePagePersonProfileProps = {
	image?: ImageResult
	firstName?: string
	lastName: string
}

export const PeoplePagePersonProfile: React.FunctionComponent<PeoplePagePersonProfileProps> = ({
	image,
	firstName,
	lastName,
}) => {
	return (
		<>
			<div className={style.wrapper}>
				<div className={style.image}>
					<PeoplePagePersonItemImage image={image} hoverOnImage />
				</div>
				<h3 className={style.name}>
					{firstName && `${firstName} `}
					{lastName}
				</h3>
			</div>
		</>
	)
}
