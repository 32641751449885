import clsx from 'clsx'
import React, { useEffect } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { ContemberLink } from './ContemberLink'
import style from './HeaderMainNavigationSidebar.module.sass'
import { LocaleSwitcher } from './LocaleSwitcher'

type HeaderMainNavigationSidebarProps = NonNullable<
	NonNullable<Pick<CommonPageProps, 'header'>['header']>['localesByLocale']
>['primaryNavigation'] & {
	isActive: boolean
	menuLinkIsActive: string | null
	setMenuLinkIsActive: React.Dispatch<React.SetStateAction<string | null>>
	setButtonIsActive: React.Dispatch<React.SetStateAction<boolean>>
}

export const HeaderMainNavigationSidebar: React.FunctionComponent<
	HeaderMainNavigationSidebarProps
> = ({ columns, isActive, setButtonIsActive, menuLinkIsActive, setMenuLinkIsActive }) => {
	const sideBarDesktopWidth = '250px'

	useEffect(() => {
		isActive
			? document.documentElement.style.setProperty(
					'--page-desktop-menu-sidebar-width',
					`${sideBarDesktopWidth}`
			  )
			: document.documentElement.style.setProperty('--page-desktop-menu-sidebar-width', '0')
	}, [isActive])

	return (
		<aside
			className={clsx(style.wrapper, isActive && style.is_open)}
			style={{
				'--HeaderMainNavigationSidebar-desktop-width': sideBarDesktopWidth,
			}}>
			<nav className={style.navigation} aria-label="primary list navigation">
				<ul className={style.list}>
					{columns.map((item) => (
						<React.Fragment key={item.id}>
							{item.link?.title && (
								<li
									className={style.item}
									id={item.id}
									onFocus={() => setMenuLinkIsActive(item.id)}
									onMouseOver={() => setMenuLinkIsActive(item.id)}>
									<ContemberLink
										onClick={() => {
											setButtonIsActive(false)
										}}
										className={clsx(
											style.link,
											item.isItemsVisible &&
												item.items.length > 0 &&
												menuLinkIsActive === item.id &&
												style.isActiveOnHover
										)}
										link={item.link}>
										{item.link.title}
									</ContemberLink>
								</li>
							)}
						</React.Fragment>
					))}
				</ul>
			</nav>
			<div className={style.languages}>
				<LocaleSwitcher />
			</div>
		</aside>
	)
}
