import Image from 'next/image'
import Link from 'next/link'
import type { ReactNode } from 'react'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import type { InternalLinkResult } from '../app/data/content/components/InternalLinkFragment'
import type { LinkResult } from '../app/data/content/components/LinkFragment'
import style from './WorkshopTileLinkItem.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type WorkshopTileLinkItemProps = {
	id: string
	image?: ImageResult
	title?: string
	link?:
		| LinkResult
		| (InternalLinkResult & {
				label?: string
		  })
	lead?: string
	tags?: ReactNode
}

export const WorkshopTileLinkItem: React.FunctionComponent<WorkshopTileLinkItemProps> = ({
	image,
	title,
	link,
	lead,
	tags,
}) => {
	const imageBackground = image?.url && (
		<div className={style.image}>
			<Image
				className={style.imageIn}
				src={image.url}
				fill
				alt={image.alt ?? ''}
				blurDataURL={image.url}
				placeholder="blur"
				sizes="100vw, (min-width: 2048px) 2100px"
			/>
		</div>
	)

	const href = link
		? 'type' in link
			? link.type === 'internal'
				? link.internalLink?.url ?? '/'
				: link.externalLink ?? '/'
			: 'url' in link
			? link.url
			: '/'
		: undefined

	return (
		<div className={style.wrapper}>
			{href ? <Link href={href}>{imageBackground}</Link> : imageBackground}
			<div className={style.content}>
				<div className={style.header}>
					{title && (
						<h3 className={style.title}>
							{href ? (
								<Link href={href} className={style.titleLink}>
									{title}
								</Link>
							) : (
								title
							)}
						</h3>
					)}
				</div>
				<div className={style.description}>
					{lead && (
						<div className={style.lead}>
							<Wysiwyg source={lead} />
						</div>
					)}
					{tags && lead && <div className={style.divider} />}
					{tags && <div className={style.tags}>{tags}</div>}
				</div>
			</div>
		</div>
	)
}
