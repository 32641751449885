import clsx from 'clsx'
import React, { useState } from 'react'
import type { FooterSocialResult } from '../app/data/content/components/FooterSocialLinkFragment'
import type { ImageResult } from '../app/data/content/components/ImageFragment'
import { useTranslate } from '../utils/useTranslate'
import { ContactFormBox } from './ContactFormBox'
import { ContemberLink } from './ContemberLink'
import style from './FooterSocials.module.sass'
import { ImageAspectRatio } from './ImageAspectRatio'

export type FooterSocialsProps = {
	items: FooterSocialResult[]
}

export const FooterSocials: React.FunctionComponent<FooterSocialsProps> = ({ items }) => {
	const [isNewsletterVisible, setNewsletterVisible] = useState(false)
	const icon = (image?: ImageResult) => (
		<div className={style.icon}>
			<ImageAspectRatio
				sizes="100px"
				image={image}
				isBackgroundTransparent
				hover="verticalOffset"
			/>
		</div>
	)

	const translation = useTranslate()

	return (
		<div className={style.wrapper}>
			{items && (
				<ul className={style.list}>
					{items.map((item) => (
						<li key={item.id} className={style.item}>
							{item.type === 'newsletter' ? (
								<>
									<button
										className={style.button}
										type="button"
										onClick={() => setNewsletterVisible(!isNewsletterVisible)}>
										{icon(item.icon?.image)}
									</button>

									<div className={clsx(style.newsletter, isNewsletterVisible && style.is_visible)}>
										<button
											type="button"
											aria-label={translation('close')}
											className={style.newsletterClose}
											onClick={() => setNewsletterVisible(!isNewsletterVisible)}
										/>
										<ContactFormBox
											type={{
												name: 'newsletter',
												newsletterListId: item.newsletter?.id,
												labels: {
													firstName: '',
													lastName: '',
													email: '',
												},
											}}
										/>
									</div>
								</>
							) : 'link' && item.link ? (
								<ContemberLink className={style.link} link={item.link}>
									<div className={style.icon}>{icon(item.icon?.image)}</div>
								</ContemberLink>
							) : null}
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
