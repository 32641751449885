import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import style from './Footer.module.sass'
import { FooterNavigation } from './FooterNavigation'
import { FooterPartners } from './FooterPartners'
import { FooterSocials } from './FooterSocials'
import { Icon } from './Icon'

export type FooterProps = Pick<CommonPageProps, 'footer'>

export const Footer: React.FunctionComponent<FooterProps> = ({ footer }) => {
	const translation = useTranslate()
	return (
		<footer className={style.wrapper}>
			<Container disableGutters="vertical">
				<div className={style.main}>
					<div className={style.column}>
						{footer?.localesByLocale?.partners?.items &&
							footer?.localesByLocale?.partners?.items.length > 0 && (
								<div className={style.partners}>
									{footer.localesByLocale.partnerTitleLink && (
										<ContemberLink
											className={style.partnersLink}
											link={footer.localesByLocale.partnerTitleLink}>
											<h2 className={style.columnTitle}>
												{footer.localesByLocale.partnerTitleLink.title}
											</h2>
										</ContemberLink>
									)}
									<FooterPartners {...footer.localesByLocale.partners} />
								</div>
							)}
					</div>

					<div className={clsx(style.column, style.view_align_right)}>
						{footer?.localesByLocale?.socials && footer?.localesByLocale?.socials.length > 0 && (
							<div className={style.socials}>
								{footer?.localesByLocale?.socialsTitle && (
									<h2 className={style.columnTitle}>{footer.localesByLocale.socialsTitle}</h2>
								)}
								<FooterSocials items={footer?.localesByLocale?.socials} />
							</div>
						)}
						{footer?.localesByLocale?.navigation?.list &&
							footer?.localesByLocale?.navigation?.list.length > 0 && (
								<div className={style.navigation}>
									{footer?.localesByLocale?.navigationTitle && (
										<h2 className={style.columnTitle}>{footer.localesByLocale.navigationTitle}</h2>
									)}
									<FooterNavigation list={footer?.localesByLocale?.navigation?.list} />
								</div>
							)}
					</div>

					<div className={style.copyright}>
						{translation('Footer.copyright')}{' '}
						<Link
							href="https://www.mangoweb.cz/"
							className={style.copyrightLink}
							target="_blank"
							rel="noopener noreferrer">
							manGoweb
							<span className={style.copyrightHover}>
								{' '}
								<Icon name="mangoHeart" />
							</span>
						</Link>
					</div>
				</div>
			</Container>
		</footer>
	)
}
