import type { FunctionComponent } from 'react'
import type { TableWithLinksOrTextsRowResult } from '../app/data/content/components/TableWithLinksOrTextsRowFragment'
import style from './TableWithLinks.module.sass'
import { TableWithLinksContent } from './TableWithLinksContent'

export type TableWithLinksProps = {
	rows: TableWithLinksOrTextsRowResult[]
}

export const TableWithLinks: FunctionComponent<TableWithLinksProps> = ({ rows }) => {
	return (
		<table className={style.wrapper}>
			<tbody>
				{rows.length > 0 &&
					rows.map((row) => (
						<tr key={row.id} className={style.row}>
							{row.headerLabel && <td className={style.cellHeader}>{row.headerLabel}</td>}
							{row.contents.length > 0 && (
								<td className={style.cellContents}>
									{row.contents.map((content, index) => (
										<span key={content.id} className={style.cellContent}>
											<TableWithLinksContent {...content} />
											{row.contents.length !== index + 1 &&
												(content.text?.includes('\\n') ? null : ', ')}
										</span>
									))}
								</td>
							)}
						</tr>
					))}
			</tbody>
		</table>
	)
}
