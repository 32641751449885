import { useCallback, useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import type { ListCategorySwitcherProps } from './ListCategorySwitcher'
import { PageContent } from './PageContent'
import type { TagsProps } from './Tags'
import { Tags } from './Tags'
import { CategorySwitcherDescription } from './WorkshopCategory'
import { WorkshopListCategorySwitcher } from './WorkshopListCategorySwitcher'
import style from './WorkshopPage.module.sass'
import { WorkshopTileLinkList } from './WorkshopTileLinkList'

export type WorkshopPageProps = Exclude<
	Pick<CommonPageProps['page'], 'workshopPage'>['workshopPage'],
	null
>

export const WorkshopPage: React.FunctionComponent<WorkshopPageProps> = ({
	listWorkshop,
	listWorkshopCategory,
	title,
	content,
}) => {
	const translation = useTranslate()

	const tagList = useCallback((workshops: WorkshopPageProps['listWorkshop'][number]) => {
		const tags: TagsProps['items'] = []
		// ageFrom tag
		if (workshops?.ageFrom?.label) {
			tags.push({
				text: workshops.ageFrom.label.toString(),
				hasAddCharacter: true,
			})
		}
		// all other tags
		workshops.tags?.items.map(
			(item) =>
				item.tag?.localesByLocale?.title &&
				tags.push({
					text: item.tag.localesByLocale.title,
				})
		)

		return tags
	}, [])

	const categories = useMemo(() => {
		const array: ListCategorySwitcherProps['categories'] = []

		listWorkshopCategory.map((category) => {
			if (!category.localesByLocale?.link) {
				return
			}
			array.push({
				link: category.localesByLocale.link,
				name: category.localesByLocale.name,
				isHidden: category.localesByLocale.isHidden,
				descriptionChildren: <CategorySwitcherDescription category={category} />,
			})
		})

		return array
	}, [listWorkshopCategory])

	return (
		<div className={style.wrapper}>
			<div className={style.content}>
				<PageContent title={title} content={content} />
			</div>
			<div className={style.main}>
				<div className={style.switcher}>
					<WorkshopListCategorySwitcher categories={categories} />
				</div>
				{listWorkshop?.length > 0 && (
					<WorkshopTileLinkList
						items={listWorkshop.map((item) => ({
							id: item.id,
							image: item.tileImage,
							link: {
								url: item.localesByLocale?.link?.url ?? '',
								label: translation('Workshop.tile.button'),
							},
							title: item.localesByLocale?.title,
							lead: item.localesByLocale?.lead,
							tags: item.ageFrom?.label && <Tags items={tagList(item)} isAlignCenter />,
						}))}
					/>
				)}
			</div>
		</div>
	)
}
