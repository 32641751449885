import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocalLoading } from 'shared-loading-indicator'
import { v4 as uuidv4 } from 'uuid'
import { ContactFormInputs } from '../app/forms/ContactForm/ContactFormInputs'
import { NewsletterFormInputs } from '../app/forms/NewsletterForm/NewsletterFormInputs'
import { validateZod } from '../app/validators/utils/validateZod'
import { createFetcher } from '../libs/next/api/jsonApiHandler'
import type { API } from '../pages/api/v1/[handler]'
import { useTranslate } from '../utils/useTranslate'
import { Button } from './Button'
import style from './ContactFormBox.module.sass'
import { CheckboxField, InputField, TextArea } from './FormFields'
import { useContactFormData, useNewsletterFormData } from './contexts/FormDataContextProvider'
import { useGetRecaptchaToken } from './contexts/RecaptchaContextProvider'

export type ContactFormBoxProps = {
	type: ContactFormBoxContact | ContactFormBoxNewsletter
	title?: string
	text?: string
}

type ContactFormBoxContact = {
	name: 'contact'
	labels?: ContactFormBoxLabels & ContactFormBoxContactLabels
	placeholders?: ContactFormBoxContactPlaceholders
}

type ContactFormBoxNewsletter = {
	name: 'newsletter'
	newsletterListId?: string
	labels?: ContactFormBoxLabels & ContactFormBoxNewsletterLabels
	placeholders?: ContactFormBoxNewsletterPlaceholders
}

type ContactFormBoxLabels = {
	email?: string
	text?: string
}

type ContactFormBoxPlaceholders = ContactFormBoxLabels

type ContactFormBoxContactPlaceholders = ContactFormBoxPlaceholders & ContactFormBoxContactLabels

type ContactFormBoxNewsletterPlaceholders = ContactFormBoxPlaceholders &
	ContactFormBoxNewsletterLabels

type ContactFormBoxContactLabels = {
	name?: string
}

type ContactFormBoxNewsletterLabels = {
	firstName?: string
	lastName?: string
}

export const api = createFetcher<API>('/api/v1/[handler]')

export const ContactFormBox: FunctionComponent<ContactFormBoxProps> = ({ type, title, text }) => {
	let flashSuccessMessage: string | undefined
	let flashErrorMessage: string | undefined
	const toastId = uuidv4()
	const translation = useTranslate()
	const contactFormDataTranslation = useContactFormData()
	const newsletterFormDataTranslation = useNewsletterFormData()

	if (type.name === 'contact') {
		flashSuccessMessage = contactFormDataTranslation?.localesByLocale?.successMessage
		flashErrorMessage = contactFormDataTranslation?.localesByLocale?.errorMessage
	} else if (type.name === 'newsletter') {
		flashSuccessMessage = newsletterFormDataTranslation?.localesByLocale?.successMessage
		flashErrorMessage = newsletterFormDataTranslation?.localesByLocale?.errorMessage
	}

	const mutation = useMutation(async (formData: FormData) => {
		let validatedData

		if (type.name === 'contact') {
			validatedData = validateZod(formData, ContactFormInputs())

			return api('contactForm', validatedData)
		} else if (type.name === 'newsletter') {
			validatedData = validateZod(formData, NewsletterFormInputs())

			return api('newsletterForm', validatedData)
		}
	})

	const getRecaptchaToken = useGetRecaptchaToken()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (event.currentTarget) {
			setIsLoading(true)
			const formData = new FormData(event.currentTarget)
			const recaptchaToken = await getRecaptchaToken()
			if (recaptchaToken === null) {
				setIsLoading(false)
				toast.error(flashErrorMessage, { toastId: toastId + '-' + type.name })
				return
			}
			formData.append('recaptcha', recaptchaToken)
			await mutation
				.mutateAsync(formData)
				.then((result) => {
					if (result) {
						if (result.contemberStatus && !result.contemberStatus.ok) {
							console.error(result.contemberStatus.errorMessage)
						}
						if (result.status) {
							toast.success(flashSuccessMessage, {
								toastId: toastId + '-' + type.name,
							})
						} else {
							toast.error(result.error, { toastId: toastId + '-' + type.name })
							console.error(result.error, 'error')
						}
					}
				})
				.catch((e) => {
					toast.error(flashErrorMessage, { toastId: toastId + '-' + type.name })
					console.error(e)
				})
			setIsLoading(false)
		}
	}

	const [_, setIsLoading] = useLocalLoading()

	return (
		<>
			<div className={clsx(style.wrapper, type.name === 'newsletter' && style.view_newsletter)}>
				{title ? (
					<h3 className={style.title}>{title}</h3>
				) : (
					type.name === 'newsletter' && (
						<h3 className={style.title}>{newsletterFormDataTranslation?.localesByLocale?.title}</h3>
					)
				)}
				{text ? (
					<div className={style.text}>{text}</div>
				) : (
					type.name === 'newsletter' && (
						<div className={style.text}>{newsletterFormDataTranslation?.localesByLocale?.text}</div>
					)
				)}
				{((type.name === 'newsletter' && type.newsletterListId) || type.name === 'contact') && (
					<form
						className={style.form}
						onSubmit={(event) => {
							event.preventDefault()
							handleSubmit(event)
						}}>
						<div className={style.name}>
							{type.name === 'contact' ? (
								<InputField
									label={
										type.labels?.name ?? contactFormDataTranslation?.localesByLocale?.nameLabel
									}
									type="text"
									name="name"
									placeholder={
										type.placeholders?.name ??
										contactFormDataTranslation?.localesByLocale?.namePlaceholder
									}
									required
								/>
							) : type.name === 'newsletter' ? (
								<>
									<input type="hidden" name="newsletterListId" value={type.newsletterListId} />
									<InputField
										label={
											type.labels?.firstName ??
											newsletterFormDataTranslation?.localesByLocale?.firstnameLabel
										}
										type="text"
										name="firstName"
										placeholder={
											type.placeholders?.firstName ??
											newsletterFormDataTranslation?.localesByLocale?.firstnamePlaceholder
										}
										required
									/>
									<InputField
										label={
											type.labels?.lastName ??
											newsletterFormDataTranslation?.localesByLocale?.lastnameLabel
										}
										type="text"
										name="lastName"
										placeholder={
											type.placeholders?.lastName ??
											newsletterFormDataTranslation?.localesByLocale?.lastnamePlaceholder
										}
										required
									/>
								</>
							) : null}
						</div>
						<div className={style.email}>
							<InputField
								label={
									type.labels?.email || type.labels?.email === ''
										? type.labels.email
										: type.name === 'contact'
										? contactFormDataTranslation?.localesByLocale?.emailLabel
										: type.name === 'newsletter'
										? newsletterFormDataTranslation?.localesByLocale?.emailLabel
										: undefined
								}
								type="email"
								name="email"
								placeholder={
									type.placeholders?.email
										? type.placeholders?.email
										: type.name === 'contact'
										? contactFormDataTranslation?.localesByLocale?.emailPlaceholder
										: type.name === 'newsletter'
										? newsletterFormDataTranslation?.localesByLocale?.emailPlaceholder
										: undefined
								}
								required
							/>
						</div>
						{type.name === 'contact' && (
							<div className={style.textarea}>
								<TextArea
									label={
										type.labels?.text ?? contactFormDataTranslation?.localesByLocale?.messageLabel
									}
									name="message"
									placeholder={
										type.placeholders?.text ??
										contactFormDataTranslation?.localesByLocale?.messagePlaceholder
									}
									required
								/>
							</div>
						)}
						{type.name === 'newsletter' && (
							<div className={style.termsAndConditions}>
								<CheckboxField
									label={
										newsletterFormDataTranslation?.localesByLocale?.termsAndAgreements ? (
											<RichTextRenderer
												source={newsletterFormDataTranslation?.localesByLocale.termsAndAgreements}
											/>
										) : (
											''
										)
									}
									name="terms_and_conditions"
									required
								/>
							</div>
						)}
						<div className={style.submit}>
							<Button
								type="submit"
								variant="red"
								viewType={type.name === 'newsletter' ? 'newsletterBox' : undefined}>
								{translation('send')}
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	)
}
