import type { LightboxSource } from '@mangoweb/react-fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import { useMemo } from 'react'
import type { MediumGalleryImagesResult } from '../app/data/content/components/MediumGalleryImagesFragment'

export const usePicturesGallery = (pictures?: MediumGalleryImagesResult) => {
	const gallery = useMemo(() => {
		let sources: LightboxSource[] = []
		if (pictures) {
			sources =
				pictures.items.map((item) => ({
					source: item.medium?.image?.url ?? '',
					type: 'image',
					caption: item.medium?.image?.alt,
				})) ?? []
		}
		return sources
	}, [pictures])

	const lightbox = useLightbox(gallery)

	return lightbox
}
