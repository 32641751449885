import type { LinkResult } from '../app/data/content/components/LinkFragment'

export const contemberLinkToHref = (link: LinkResult): string => {
	if (link.type === 'external' && link.externalLink !== undefined && link.externalLink !== null) {
		return link.externalLink
	}
	if (link.type === 'internal' && link.internalLink !== undefined) {
		return link.internalLink.url
	}
	console.warn('Invalid link', link)
	return ''
}
