import type { CommonPageProps } from '../pages/[[...page]]'
import style from './HomePage.module.sass'
import { PageContent } from './PageContent'

export type HomePageProps = Exclude<Pick<CommonPageProps['page'], 'homePage'>['homePage'], null>

export const HomePage: React.FunctionComponent<HomePageProps> = ({ title, content }) => {
	return (
		<div className={style.wrapper}>
			<div className={style.content}>
				<PageContent title={title} content={content} />
			</div>
		</div>
	)
}
