import { useMemo } from 'react'
import type { CommonPageProps } from '../pages/[[...page]]'
import { useTranslate } from '../utils/useTranslate'
import style from './AboutTheatrePage.module.sass'
import { Container } from './Container'
import { PageContent } from './PageContent'
import type { PageTileLinkItemProps } from './PageTileLinkItem'
import { PageTileLinkList } from './PageTileLinkList'

export type AboutTheatrePageProps = Exclude<
	Pick<CommonPageProps['page'], 'aboutTheatrePage'>['aboutTheatrePage'],
	null
>

export const AboutTheatrePage: React.FunctionComponent<AboutTheatrePageProps> = ({
	listAboutTheatre,
	additionalTileList,
	title,
	content,
}) => {
	const translation = useTranslate()

	const rawTiles: PageTileLinkItemProps[] = useMemo(
		() =>
			listAboutTheatre.map((item) => ({
				id: item.id,
				image: item.tileImage,
				link: {
					url: item.localesByLocale?.link?.url ?? '',
					label: translation('AboutTheatre.tile.button'),
				},
				title: item.localesByLocale?.title,
				lead: item.localesByLocale?.lead,
			})),
		[listAboutTheatre, translation]
	)

	const additionalTiles: PageTileLinkItemProps[] | undefined = useMemo(
		() =>
			additionalTileList?.items.map((item) => ({
				id: item.id,
				image: item.tile?.image,
				link: item.tile?.button?.type
					? {
							type: item.tile?.button?.type,
							externalLink: item.tile?.button?.externalLink,
							internalLink: {
								url: item.tile?.button?.internalLink?.url ?? '/',
							},
							isTargetBlank: item.tile?.button.isTargetBlank,
							label: translation('AboutTheatre.tile.button'),
					  }
					: undefined,
				title: item.tile?.title,
				lead: item.tile?.text,
			})),
		[additionalTileList?.items, translation]
	)

	const tiles = useMemo(
		() => (additionalTiles ? rawTiles.concat(additionalTiles) : rawTiles),
		[additionalTiles, rawTiles]
	)

	return (
		<div className={style.wrapper}>
			<div className={style.content}>
				<PageContent title={title} content={content} />
			</div>
			<div className={style.main}>
				<Container size="large" disableGutters="full">
					{tiles && <PageTileLinkList items={tiles} />}
				</Container>
			</div>
		</div>
	)
}
