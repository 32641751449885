import { groupBy } from 'lodash'
import type { EventWithTitleAndRepertoiresOrWorkshopsLocaleResult } from '../app/data/content/components/EventWithTitleAndRepertoiresOrWorkshopsLocaleFragment'

export const getGroupedEvents = (events: EventWithTitleAndRepertoiresOrWorkshopsLocaleResult[]) => {
	return Object.entries(groupBy(events, (event) => event.datumOdModified?.substring(0, 10))).map(
		([day, groupedEventsByDay]) => ({
			day,
			groupedEventsByTitle: Object.entries(
				groupBy(groupedEventsByDay, (event) => event.customTitle || event.title?.id)
			).map(([id, events]) => ({
				id,
				// events are sorted by "datumOd" and "volno"
				events: events.sort((a, b) => {
					const aIsReserverable = (a.volno ?? 0) > 0
					const bIsReserverable = (b.volno ?? 0) > 0

					if (aIsReserverable === bIsReserverable) {
						return a.datumOd.localeCompare(b.datumOd, 'en')
					}

					if (aIsReserverable) {
						return -1
					}

					return 1
				}),
			})),
		})
	)
}

export type GroupedEventsResult = ReturnType<typeof getGroupedEvents>
