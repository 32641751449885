import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import type { MediumGalleryImagesResult } from '../app/data/content/components/MediumGalleryImagesFragment'
import { usePicturesGallery } from '../utils/usePicturesGallery'
import style from './GalleryWithSmallThumbnails.module.sass'

export type GalleryWithSmallThumbnailsProps = {
	gallery: MediumGalleryImagesResult
	viewType?: 'personTile'
}

export const GalleryWithSmallThumbnails: FunctionComponent<GalleryWithSmallThumbnailsProps> = ({
	gallery,
	viewType,
}) => {
	const lightbox = usePicturesGallery(gallery)
	return (
		<div className={clsx(style.wrapper, style[`view_${viewType}`])}>
			{gallery?.items && gallery.items.length > 0 && (
				<div className={style.images}>
					{gallery.items.map(
						(item, index) =>
							item.medium &&
							item.medium.image?.url && (
								<button
									aria-label={item.medium.image.alt ?? 'image'}
									key={item.medium.id}
									type="button"
									className={style.button}
									onClick={() => lightbox?.open(index)}>
									<Image
										src={item.medium.image.url}
										className={style.image}
										fill
										sizes="110px"
										alt={item.medium.image.alt ?? ''}
										blurDataURL={item.medium.image.url}
										placeholder="blur"
									/>
								</button>
							)
					)}
				</div>
			)}
		</div>
	)
}
