import type { FunctionComponent } from 'react'
import type { YoutubeVideoResult } from '../app/data/content/components/YoutubeVideoFragment'
import { PlyrPlayer } from './PlyrPlayer'
import style from './YoutubeVideo.module.sass'

export type YoutubeVideoProps = YoutubeVideoResult

export const YoutubeVideo: FunctionComponent<YoutubeVideoProps> = ({ videoId }) => {
	return (
		<div className={style.wrapper}>
			{videoId && (
				<PlyrPlayer
					source={{
						type: 'video',
						sources: [
							{
								src: videoId,
								provider: 'youtube',
							},
						],
					}}
				/>
			)}
		</div>
	)
}
